import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

const DropdownButton = ({ selected, open, defaultValue, label, first }) => {
  return (
    <div className="-mx-3 w-[300px] px-3 pt-3 pb-[10px] text-left">
      <div className="text-xs tracking-wider text-bsr-gray-500">{label}</div>
      <div className="flex items-center justify-between ">
        <div className="flex-1 truncate py-1 text-sm font-bold tracking-wide text-bsr-gray-900">
          {selected ? selected : defaultValue ? defaultValue : ""}
        </div>
        {open ? (
          <ChevronUpIcon
            className="flex-0 ml-2 h-4 w-4 text-bsr-gray-500"
            aria-hidden="true"
          />
        ) : (
          <ChevronDownIcon
            className="flex-0 ml-2 h-4 w-4 text-bsr-gray-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
};

export default DropdownButton;
