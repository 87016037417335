import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import Error from "./Error";
import { selectSession, selectSessionStatus } from "../store/sessionSlice";
import { selectApplicationStatus } from "../store/applicationSlice";

export function RequireAuth({ children }) {
  const session = useSelector(selectSession);
  const sessionStatus = useSelector(selectSessionStatus);
  const applicationStatus = useSelector(selectApplicationStatus);
  const location = useLocation();

  if (sessionStatus === "loading" || applicationStatus === "loading")
    return <Loading />;

  if (sessionStatus === "failed" || applicationStatus === "failed")
    return <Error />;

  // firebase tried to load the session from cookies, but the user is logged out or has never logged in from this machine. Redirect them to login.
  if (!session) {
    let referrer;
    if (location && location.pathname) {
      referrer = "?referrer=" + location.pathname;
      if (location.search) {
        referrer += location.search;
      }
    }
    return <Navigate to={"/login" + referrer} />;
  }

  // session valid - redirect user to the requested page.
  return children;
}
