import { classNames } from "../utilities";
import { ReactComponent as Logo } from "../assets/logo-alt.svg";
import { ReactComponent as AirAwareLogo } from "../assets/airaware.svg";

let ConditionalLogo = Logo;
let appName = "AirLogic";

if (process.env.REACT_APP_AIR_AWARE === "true") {
  ConditionalLogo = AirAwareLogo;
  appName = "AirAware";
}

export default function AppName({
  vertical,
  withTm = true,
  withLogo = true,
  withName = true,
  size = "md",
  iconColor = "white",
  textColor = "text-white",
}) {
  switch (size) {
    case "sm":
      return (
        <div
          className={classNames(
            vertical ? "flex-col" : "",
            textColor ? textColor : "",
            "flex items-center"
          )}
        >
          {withLogo && (
            <ConditionalLogo
              fill={iconColor}
              className={classNames(vertical ? "" : "mr-1", "h-6 w-6")}
            />
          )}
          {withName && (
            <h1 className="text-md">
              {appName}
              {withTm && "™"}
            </h1>
          )}
        </div>
      );
    case "md":
      return (
        <div
          className={classNames(
            vertical ? "flex-col" : "",
            textColor ? textColor : "",
            "flex items-center"
          )}
        >
          {withLogo && (
            <ConditionalLogo
              fill={iconColor}
              className={classNames(vertical ? "" : "mr-1", "h-10 w-10")}
            />
          )}
          {withName && (
            <h1 className="ml-2 text-xl">
              {appName}
              {withTm && "™"}
            </h1>
          )}
        </div>
      );
    case "lg":
      return (
        <div
          className={classNames(
            vertical ? "flex-col items-center" : "",
            textColor ? textColor : "",
            "flex items-center"
          )}
        >
          {withLogo && (
            <ConditionalLogo
              fill={iconColor}
              className={classNames(
                process.env.REACT_APP_AIR_AWARE === "true" ? "" : "mr-1",
                "h-14 w-14"
              )}
            />
          )}
          {withName && (
            <h1 className="ml-2 text-4xl">
              {appName}
              {withTm && "™"}
            </h1>
          )}
        </div>
      );
    default:
      return (
        <div
          className={classNames(
            vertical ? "flex-col" : "",
            textColor ? textColor : "",
            "flex"
          )}
        >
          {withLogo && (
            <ConditionalLogo
              className={classNames(vertical ? "" : "mr-4", "h-14 w-14")}
            />
          )}
          {withName && (
            <h1 className="ml-2 text-4xl tracking-wide">
              {appName}
              {withTm && "™"}
            </h1>
          )}
        </div>
      );
  }
}
