import dayjs from "./dayjs";

export const startDate = "2021-03-26";
export const endDate = "2021-06-23";

export const MAX_WIND_ZOOM_LEVEL = 17;

// sets radius pixels for bubbles in portfolio map. also used in legend.
export const RADIUS_MIN_PIXELS = 6;
export const RADIUS_MAX_PIXELS = 25;

export const metrics = [
  { color: "bsr-blue-500", id: "tropospheric_NO2_column_number_density" },
  { color: "bsr-pink-500", id: "CO_column_number_density" },
  { color: "bsr-gray-500", id: "tropospheric_HCHO_column_number_density" },
  { color: "bsr-red-500", id: "absorbing_aerosol_index" },
  { color: "bsr-yellow-500", id: "CH4_column_volume_mixing_ratio_dry_air" },
];

export const DEFAULT_TIME_FRAME = 0;

export const timeFrames = [
  {
    name: `This year`,
    cadence: "monthly",
    endDate: dayjs().startOf("month").add(1, "month").toISOString(),
    startDate: dayjs().startOf("year").toISOString(),
  },
  {
    name: `Last year`,
    cadence: "monthly",
    endDate: dayjs().startOf("year").toISOString(),
    startDate: dayjs().startOf("year").subtract(1, "year").toISOString(),
  },
  {
    name: dayjs().startOf("year").subtract(2, "year").format("YYYY"),
    cadence: "monthly",
    endDate: dayjs().startOf("year").subtract(1, "year").toISOString(),
    startDate: dayjs().startOf("year").subtract(2, "year").toISOString(),
  },
  {
    name: `Last 2 years`,
    cadence: "monthly",
    endDate: dayjs().startOf("day").toISOString(),
    startDate: dayjs().startOf("day").subtract(2, "years").toISOString(),
  },
  {
    name: `Last 365 days`,
    cadence: "monthly",
    endDate: dayjs().startOf("day").toISOString(),
    startDate: dayjs().startOf("day").subtract(1, "year").toISOString(),
  },
  {
    name: `Last 30 days`,
    cadence: "daily",
    endDate: dayjs().startOf("day").toISOString(),
    startDate: dayjs().startOf("day").subtract(30, "days").toISOString(),
  },
  {
    name: `Last week`,
    cadence: "daily",
    endDate: dayjs().startOf("day").toISOString(),
    startDate: dayjs().startOf("day").subtract(1, "week").toISOString(),
  },
  {
    name: `All time`,
    cadence: "yearly",
    endDate: dayjs().startOf("year").add(1, "year").toISOString(),
    startDate: dayjs()
      .startOf("year")
      .add(1, "year")
      .subtract(5, "years")
      .toISOString(),
  },
];

export const ALMOST_ZERO = 0.000000000001;

export const COLORS = [
  {
    tailwind: {
      to: "to-bsr-pink-500",
      via: "via-bsr-pink-500",
      bg: "bg-bsr-pink-500",
      text: "text-bsr-pink-500",
      border: "border-bsr-pink-500",
    },
    hex: "#DA127D",
    rgb: [218, 18, 125],
  },
  {
    tailwind: {
      to: "to-bsr-blue-500",
      via: "via-bsr-blue-500",
      bg: "bg-bsr-blue-500",
      text: "text-bsr-blue-500",
      border: "border-bsr-blue-500",
    },
    hex: "#2BB0ED",
    rgb: [43, 176, 237],
  },
  {
    tailwind: {
      to: "to-bsr-teal-500",
      via: "via-bsr-teal-500",
      bg: "bg-bsr-teal-500",
      text: "text-bsr-teal-500",
      border: "border-bsr-teal-500",
    },
    hex: "#27AB83",
    rgb: [39, 171, 131],
  },
  {
    tailwind: {
      to: "to-bsr-gray-500",
      via: "via-bsr-gray-500",
      bg: "bg-bsr-gray-500",
      text: "text-bsr-gray-500",
      border: "border-bsr-gray-500",
    },
    hex: "#616E7C",
    rgb: [97, 110, 124],
  },
  {
    tailwind: {
      to: "to-bsr-yellow-500",
      via: "via-bsr-yellow-500",
      bg: "bg-bsr-yellow-500",
      text: "text-bsr-yellow-500",
      border: "border-bsr-yellow-500",
    },
    hex: "#F0B429",
    rgb: [240, 180, 41],
  },
  {
    tailwind: {
      to: "to-bsr-yellow-900",
      via: "via-bsr-yellow-900",
      bg: "bg-bsr-yellow-900",
      text: "text-bsr-yellow-900",
      border: "border-bsr-yellow-900",
    },
    hex: "#8D2B0B",
    rgb: [141, 43, 11],
  },
  {
    tailwind: {
      to: "to-bsr-red-500",
      via: "via-bsr-red-500",
      bg: "bg-bsr-red-500",
      text: "text-bsr-red-500",
      border: "border-bsr-red-500",
    },
    hex: "#E12D39",
    rgb: [225, 45, 57],
  },
  {
    tailwind: {
      to: "to-bsr-pink-900",
      via: "via-bsr-pink-900",
      bg: "bg-bsr-pink-900",
      text: "text-bsr-pink-900",
      border: "border-bsr-pink-900",
    },
    hex: "#620042",
    rgb: [98, 0, 66],
  },
];

export const ANSWERS_DATASET_MAP = {
  wm: {
    datasets: {
      // "wm-sem2flux-daily": {
      //   color: "#EF4E4E",
      //   name: "SEM Flux",
      // },
      "wm-ghgsat-plumes-with-non-detects-v2-daily": {
        color: "#3EBD93",
        name: "GHGSAT",
      },
      "carbon-mapper-v3-ch4-daily": {
        color: "#52606D",
        name: "Carbon Mapper",
      },
      "wm-scientific-aviation-daily": {
        color: "#B39DDB",
        name: "Scientific Aviation",
      },
      "wm-tracer-corr-daily": {
        color: "#D2042D",
        name: "Tracer Corr",
      },
    },
    reported: {
      color: "#F0B429",
      name: "EPA HH8 Modeled Estimate",
      dataset_id: "wm-epa-hh8-emission-rate-yearly",
      cadence: "yearly",
      getStartDate: (time) => time.subtract(1, "year").toDate(),
      getEndDate: (time) => time.subtract(1, "year").toDate(),
    },
    customTimeFrames: [
      {
        name: `2021`,
        cadence: "monthly",
        endDate: dayjs("2021").endOf("year").toISOString(),
        startDate: dayjs("2021").startOf("year").toISOString(),
      },
      {
        name: `2022`,
        cadence: "monthly",
        endDate: dayjs("2022").endOf("year").toISOString(),
        startDate: dayjs("2022").startOf("year").toISOString(),
      },
    ],
    defaultTimeFrame: {
      name: `This year`,
      cadence: "monthly",
      endDate: dayjs().startOf("month").add(1, "month").toISOString(),
      startDate: dayjs().startOf("year").toISOString(),
    },
    comingSoonDatasets: [
      "FlightAware",
      "MethaneSAT",
      "UN Methane Alert and Response System (MARS)",
    ],
  },
  chevron: {
    datasets: {
      "bridger-daily": {
        color: "#3EBD93",
        name: "Bridger",
      },
      "carbon-mapper-v3-ch4-daily": {
        color: "#52606D",
        name: "Carbon Mapper",
      },
    },
    defaultTimeFrame: {
      name: `This year`,
      cadence: "monthly",
      endDate: dayjs().startOf("month").add(1, "month").toISOString(),
      startDate: dayjs().startOf("year").toISOString(),
    },
    comingSoonDatasets: [],
  },
  bsr: {
    datasets: {
      "bsr-ghgsat-plumes-with-non-detects-v2-daily": {
        color: "#3EBD93",
        name: "GHGSAT",
      },
      "carbon-mapper-v3-ch4-daily": {
        color: "#52606D",
        name: "Carbon Mapper",
      },
    },
    reported: {
      color: "#F0B429",
      name: "EPA HH8 Modeled Estimate",
      dataset_id: "bsr-epa-hh8-emission-rate-yearly",
      cadence: "yearly",
      getStartDate: (time) => time.subtract(1, "year").toDate(),
      getEndDate: (time) => time.subtract(1, "year").toDate(),
    },
    customTimeFrames: [
      {
        name: `2021`,
        cadence: "monthly",
        endDate: dayjs("2021").endOf("year").toISOString(),
        startDate: dayjs("2021").startOf("year").toISOString(),
      },
      {
        name: `2022`,
        cadence: "monthly",
        endDate: dayjs("2022").endOf("year").toISOString(),
        startDate: dayjs("2022").startOf("year").toISOString(),
      },
      {
        name: `2023`,
        cadence: "monthly",
        endDate: dayjs("2023").endOf("year").toISOString(),
        startDate: dayjs("2023").startOf("year").toISOString(),
      },
    ],
    comingSoonDatasets: [],
  },
  bhp: {
    datasets: {
      sem2flux: {
        color: "#EF4E4E",
        name: "Drone 2 Flux",
      },
      "bhp-ghgsat-plumes-daily-emission_rate_avg": {
        color: "#3EBD93",
        name: "GHGSAT",
      },
    },
    reported: {
      color: "#F0B429",
      name: "Modeled",
      dataset_id: "bhp_emission_estimates_monthly",
      cadence: "monthly",
      getStartDate: (time) => dayjs("2022-07").startOf("month").toDate(),
      getEndDate: (time) => dayjs("2022-07").endOf("month").toDate(),
    },
    customTimeFrames: [
      {
        name: `July 2022`,
        cadence: "daily",
        endDate: dayjs("2022-07").endOf("month").toISOString(),
        startDate: dayjs("2022-07").startOf("month").toISOString(),
      },
    ],
  },
};

export const CONTEXT_LAYER_CATEGORIES = [
  "Bridger Facilities",
  "Cell Limits",
  "Waste Limits",
  "Exempt Areas",
  "Property Bounds",
  "Waste Footprint",
  "LFG Extraction Wells",
  "Sem Grids",
  "GCCS Control Devices",
];

export const DEFAULT_USER_SETTINGS = {
  mock: true,
  favorite_site_ids: [],
  selected_site_ids: [],
  notifications_enabled: false,
  modified_ts: null,
  sites_subscription: "all", // all | selected
  dataset_ids: ["carbon-mapper", "ghgsat"],
};
