export default function LandingPageCopy() {
  if (process.env.REACT_APP_AIR_AWARE === "true") {
    return (
      <>
        <p className="text-xl font-bold">
          Know who's watching you. A public lens over your assets.
        </p>
        <ul className="list-inside list-disc">
          <li>Track methane and other emissions with public sensor data</li>
          <li>Access public news and reporting on company emissions</li>
          <li>Make timely assessments of detects, and plan response</li>
          <li>Single point of information for stakeholder communications</li>
        </ul>
      </>
    );
  }
  return (
    <>
      <p className="text-xl font-bold">Measurements with Impact</p>
      <ul className="list-inside list-disc">
        <li>Track emissions of greenhouse gases and air pollutants</li>
        <li>Identify and fix problems</li>
        <li>Determine the right sensor configuration</li>
        <li>
          Communicate effectively with the public, investors and regulators
        </li>
      </ul>
    </>
  );
}
