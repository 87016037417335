import React from "react";
import PropTypes from "prop-types";

const DefaultButtonTypes = {
  primary:
    "border border-transparent shadow-sm text-white bg-bsr-blue-600 hover:bg-bsr-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bsr-blue-500",
  secondary:
    "border border border-bsr-blue-300 text-bsr-blue-900 bg-bsr-blue-100 hover:bg-bsr-blue-050 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bsr-blue-500",
  white:
    "border border-bsr-gray-200 shadow-sm text-bsr-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bsr-blue-500",
  tertiary: "text-bsr-blue-700 hover:text-bsr-blue-600",
};

const ButtonSizes = {
  xs: "px-2.5 py-1.5 text-xs font-medium rounded",
  sm: "px-3 py-2 text-sm leading-4 font-medium rounded-md",
  md: "px-4 py-2 text-sm font-medium rounded-md",
  lg: "px-4 py-2 text-base font-medium rounded-md",
  xl: "px-6 py-3 text-base font-medium rounded-md",
};

const DangerButtonTypes = {
  primary: "",
  secondary: "",
  tertiary: "",
};

function Button({
  className,
  fullWidth = false,
  disabled = false,
  danger = false,
  pill = false,
  type = "primary",
  size = "md",
  children,
  onClick,
}) {
  return (
    <button
      onClick={(e) => {
        if (onClick !== undefined) {
          onClick(e);
        }
      }}
      type={type}
      disabled={disabled}
      className={`inline-flex items-center disabled:opacity-50 ${className} ${
        ButtonSizes[size]
      } ${danger ? DangerButtonTypes[type] : DefaultButtonTypes[type]} ${
        pill ? "rounded-full" : ""
      }  ${
        fullWidth ? "flex w-full justify-center" : "inline-flex items-center"
      } ${disabled ? "cursor-not-allowed" : ""}`}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  type: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "white",
    undefined,
  ]).isRequired,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Button;
