import * as JSURL from "jsurl";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectSiteById } from "./store/applicationSlice";
import { selectAccount } from "./store/sessionSlice";
import { Col, Row, Statistic, Segmented } from "antd";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { ANSWERS_DATASET_MAP } from "./constants";
import dayjs from "./dayjs";
import { buildObservationId, convertToUnits } from "./utilities";
import { getMeasurements, getReported, getEstimates } from "./services/Api";
import useBaseParameters from "./hooks/useBaseParameters";
import EstimatesChart from "./EstimatesChart";
import MeasurementsTable from "./shared/MeasurementsTable";

function generateLinkComponent({ measurement }) {
  return (
    <td className="px-6 py-4 text-bsr-blue-400">
      <Link
        to={computeLinkUrl(
          measurement.site_id,
          measurement.date,
          measurement.dataset
        )}
        title="view measurement in rosetta"
      >
        <div className="flex">
          Open in Rosetta
          <ArrowTopRightOnSquareIcon className="ml-1 h-4 w-4" />
        </div>
      </Link>
    </td>
  );
}

const computeLinkUrl = (siteId, observationDate, observationDataset) => {
  if (observationDataset === "sem2flux") {
    observationDataset = "wm-sem2flux-daily";
  }
  const baseUrl = `/map/${siteId}`;
  const observationParam = `${JSURL.stringify([
    buildObservationId(observationDataset, observationDate),
  ])}`;
  const timeframeParam = JSURL.stringify({
    name: `CUSTOM`,
    cadence: "daily",
    startDate: dayjs(observationDate).startOf("month").toISOString(),
    endDate: dayjs(observationDate).endOf("month").toISOString(),
  });
  const datasetsParam = JSURL.stringify([observationDataset]);
  return `${baseUrl}?observations=${observationParam}&timeframe=${timeframeParam}&datasets=${datasetsParam}`;
};

export default function Estimates() {
  const { siteId, activeTimePeriod } = useBaseParameters();

  const site = useSelector((state) => selectSiteById(state, siteId));
  const account = useSelector(selectAccount);
  const [filteredDatasets, setFilteredDatasets] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [reported, setReported] = useState(null);
  const [statistics, setStatistics] = useState({});
  const unitOptions = ["SCFM", "kg / hr"];
  const [unitDisplay, setUnitDisplay] = useState(unitOptions[1]);
  const [fetchingMeasurements, setFetchingMeasurements] = useState(false);
  const [fetchingEstimates, setFetchingEstimates] = useState(false);
  const [fetchingReported, setFetchingReported] = useState(false);
  const estimatesDatasets = ANSWERS_DATASET_MAP[account].datasets;

  console.log(estimatesDatasets);

  // fetch latest reported data for a given site
  useEffect(() => {
    if (ANSWERS_DATASET_MAP[account]?.reported) {
      setFetchingReported(true);
      setReported(null);
      getReported({ account, siteId })
        .then(setReported)
        .then(() => {
          setFetchingReported(false);
        });
    }
  }, [account, siteId]);

  // fetch data from measurements endpoint
  useEffect(() => {
    setFetchingMeasurements(true);
    setMeasurements([]);
    setStatistics({});
    const datasetIds = Object.keys(estimatesDatasets);
    const params = {
      siteIds: [siteId],
      datasetIds: datasetIds,
      start: activeTimePeriod.startDate,
      end: activeTimePeriod.endDate,
    };
    if (siteId && datasetIds.length) {
      getMeasurements(params)
        .then(setMeasurements)
        .then(() => setFetchingMeasurements(false))
        .catch((e) => {
          console.log(e);
          setMeasurements([]);
          setStatistics({});
          setFetchingMeasurements(false);
        });
    }
    // eslint-disable-next-line
  }, [siteId, activeTimePeriod.startDate, activeTimePeriod.endDate]);

  useEffect(() => {
    setFetchingEstimates(true);
    setStatistics({});
    const datasetIds = Object.keys(estimatesDatasets);
    const visibleDatasetIds = datasetIds.filter(
      (d) => !filteredDatasets.includes(d)
    );
    console.log({ visibleDatasetIds, datasetIds });
    const params = {
      siteIds: [siteId],
      datasetIds: visibleDatasetIds,
      start: activeTimePeriod.startDate,
      end: activeTimePeriod.endDate,
    };
    getEstimates(params)
      .then(setStatistics)
      .then(() => {
        setFetchingEstimates(false);
      })
      .catch((e) => {
        setStatistics({});
        setFetchingEstimates(false);
      });
  }, [
    siteId,
    activeTimePeriod.startDate,
    activeTimePeriod.endDate,
    estimatesDatasets,
    filteredDatasets,
  ]);

  // const measurementsByDataset = measurements.reduce((acc, cur) => {
  //   if (!acc[cur.dataset]) {
  //     acc[cur.dataset] = {};
  //     acc[cur.dataset]["measurements"] = [cur];
  //     acc[cur.dataset]["count"] = 1;
  //   } else {
  //     acc[cur.dataset]["measurements"].push(cur);
  //     acc[cur.dataset]["count"] += 1;
  //   }
  //   return acc;
  // }, {});

  if (!site) {
    return (
      <div className="m-5 rounded-md bg-white p-2 shadow-lg">
        <div className="rounded-md border-2 border-dashed border-bsr-gray-500 py-10 text-center text-bsr-gray-700">
          <h3 className="text-xl font-extrabold">
            Select a site from the dropdown to get started
          </h3>
        </div>
      </div>
    );
  }

  if (measurements.length === 0 && !fetchingMeasurements) {
    return (
      <div className="m-5 rounded-md bg-white p-2 shadow-lg">
        <div className="rounded-md border-2 border-dashed border-bsr-gray-500 py-10 text-center text-bsr-gray-700">
          <h3 className="text-xl font-extrabold">
            No measurements for selected site and time period
          </h3>
        </div>
      </div>
    );
  }

  return (
    <main className="mt-8 min-h-screen max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
      <h2 className="mb-2 text-2xl font-bold leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
        Estimates
      </h2>
      <div className="mb-5 flex flex-col">
        <div className="mb-4 w-full rounded-lg bg-white px-4 py-5 shadow sm:p-6">
          <Row gutter={50}>
            <Col span={6}>
              <Statistic title="Active Site" value={site.name} />
            </Col>
            <Col span={8}>
              <Row>
                <Statistic
                  title={
                    <div className="whitespace-nowrap">
                      {`Estimated Emission Rate ${
                        statistics?.uncertainty
                          ? `(±${convertToUnits(
                              statistics.uncertainty,
                              unitDisplay
                            ).toFixed(0)} ${unitDisplay})`
                          : ""
                      }`}
                    </div>
                  }
                  value={
                    fetchingEstimates || !statistics.emission_rate
                      ? "---"
                      : convertToUnits(
                          statistics.emission_rate,
                          unitDisplay
                        ).toFixed(0)
                  }
                  // prefix={`± ${
                  //   loading ? "" : statistics?.uncertainty.toFixed(2)
                  // }`}
                  suffix={unitDisplay}
                  valueStyle={{ color: "#2BB0ED" }}
                />
              </Row>
            </Col>
            <Col span={6}>
              <Statistic
                title={
                  <div className="whitespace-nowrap">
                    Latest Reported ({reported?.datetime_str})
                  </div>
                }
                value={
                  fetchingReported || !reported?.value
                    ? "---"
                    : convertToUnits(reported?.value, unitDisplay).toFixed(0)
                }
                suffix={unitDisplay}
              />
            </Col>
            <Col span={4}>
              <div className="flex justify-end">
                <Segmented
                  size="small"
                  options={unitOptions}
                  value={unitDisplay}
                  onChange={(value) => {
                    setUnitDisplay(value);
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="flex rounded-lg bg-white p-5 shadow">
          <div className="mb-5 h-[500px] grow overflow-auto p-5 px-5 pb-10">
            <div className="relative h-full">
              <EstimatesChart
                activeTimePeriod={activeTimePeriod}
                account={account}
                measurements={measurements}
                movingAverage={statistics?.rolling_model || []}
                reported={reported}
                siteId={siteId}
                datasets={estimatesDatasets}
                filteredDatasets={filteredDatasets}
                setFilteredDatasets={setFilteredDatasets}
              />
            </div>
          </div>
          {/* <div className="mb-5 w-72">
            <h3 className="my-2 text-center text-lg font-bold leading-6 text-bsr-gray-500">
              Measurements by type
            </h3>
            <div className="mx-auto ">
              <Skeleton loading={loading} active={loading} className="mt-5" />
              <Pie
                data={{
                  labels: Object.keys(measurementsByDataset).map(
                    (d) => estimatesDatasets[d].name
                  ),
                  datasets: [
                    {
                      label: "Measurement Types",
                      data: Object.keys(measurementsByDataset).map(
                        (m) => measurementsByDataset[m]["count"]
                      ),
                      backgroundColor: Object.keys(measurementsByDataset).map(
                        (d) => estimatesDatasets[d].color
                      ),
                      hoverOffset: 4,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      position: "top",
                    },
                  },
                }}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="rounded-lg bg-white shadow">
        <MeasurementsTable
          initialSortBy={"datetime_str"}
          withUnits={"kg/hr"}
          withLink
          data={measurements.map((record) => ({
            dataset_name: estimatesDatasets[record.dataset].name,
            datetime_str: record.date,
            link: generateLinkComponent,
            ...record,
          }))}
        />
      </div>
    </main>
  );
}
