import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { selectActiveDatasets } from "../store/applicationSlice";
import { selectAggregatedObservationsByMetric as selectAggregatedObservationsByMetricPortfolio } from "../store/portfolioSlice";
import { selectAggregatedObservationsByMetric as selectAggregatedObservationsByMetricSite } from "../store/siteSlice";

import MetricCard from "./MetricCard";

export default function MetricGroup({ multipleSelect }) {
  const { siteId } = useParams();
  const activeDatasets = useSelector(selectActiveDatasets);
  const aggregatedObservationsByMetric = useSelector((state) =>
    siteId
      ? selectAggregatedObservationsByMetricSite(state, siteId)
      : selectAggregatedObservationsByMetricPortfolio(state)
  );

  return (
    <div className="flex divide-x divide-gray-200">
      {activeDatasets.map((dataset) => {
        return (
          <MetricCard
            key={dataset.id}
            multipleSelect={multipleSelect}
            metric={dataset}
            aggregation={aggregatedObservationsByMetric?.[dataset.name]}
          />
        );
      })}
    </div>
  );
}
