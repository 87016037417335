import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSettings,
  updateActiveGrouping,
  selectActiveGrouping,
} from "../store/applicationSlice";
import { db, auth } from "../shared/firebase";
import timestampConverter from "../converters/timestampConverter";

const useUserSettingsListener = () => {
  const activeGrouping = useSelector(selectActiveGrouping);
  const dispatch = useDispatch();
  const user = auth.currentUser;
  useEffect(() => {
    const unsubscribe = db
      .doc(`accounts/${user.account}/user_settings/${user.uid}`)
      .withConverter(timestampConverter)
      .onSnapshot((snapshot) => {
        const userSettings = snapshot.data();
        if (snapshot.exists) {
          dispatch(
            setSettings({
              id: snapshot.id,
              ...userSettings,
            })
          );

          if (activeGrouping?.id === "favorite_sites") {
            if (userSettings.favorite_site_ids.length) {
              dispatch(
                updateActiveGrouping({
                  ...activeGrouping,
                  sites: userSettings.favorite_site_ids,
                })
              );
            } else {
              dispatch(updateActiveGrouping(null));
            }
          }
        } else {
          console.log("No user settings document!");
        }
      });

    // Cleanup listener on unmount
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [dispatch, user.uid, activeGrouping?.id]);
};

export default useUserSettingsListener;
