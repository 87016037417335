import { useDispatch, useSelector } from "react-redux";
import { usePopperTooltip } from "react-popper-tooltip";
import { useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";
import ObservationHover from "./ObservationHover";
import ObservationHoverV2 from "./hovers/observation/ObservationHover";

import {
  selectActiveObservations,
  updateActiveObservations,
} from "./store/applicationSlice";
import { selectObservationsStatus } from "./store/siteSlice";
import {
  computeStoneColor,
  classNames,
  buildObservationId,
  formatData,
} from "./utilities";

export default function Observation({
  observation,
  dataset,
  singleRow,
  index,
  showRosettaValues,
}) {
  const dispatch = useDispatch();
  const status = useSelector(selectObservationsStatus);
  const activeObservations = useSelector(selectActiveObservations) || [];
  const activeObservationIds = activeObservations.map((o) => o.id);
  const [, setSearchParameters] = useSearchParams();

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({ placement: singleRow ? "right" : "auto" });

  // enrich observation with dataset
  if (observation) {
    observation = {
      id: buildObservationId(dataset.id, observation.datetime_str),
      dataset,
      ...observation,
    };
  }

  const active = activeObservations.find((el) => el.id === observation?.id);

  // what the.... metric.metric.metric??? need to revisit our terminolgy :)
  // console.log(metric?.metric?.metric);
  const value = dataset?.metric?.metric
    ? observation?.metadata?.[dataset?.metric?.metric]
    : observation?.value;

  // console.log(value);

  const stoneColor = computeStoneColor(dataset, value);
  const d = formatData(value, observation?.metadata?.uncertainty);

  // scaffold rosetta observation blocks when fetching them to avoid blank white timeline
  if (status === "loading") {
    return (
      <div
        style={{ animationDelay: `-${index * 0.2}s` }}
        className="mr-1 w-full animate-pulse bg-bsr-gray-050 last:mr-0"
      ></div>
    );
  }

  if (!observation) {
    return (
      <div
        className={classNames(
          activeObservations?.length ? "opacity-40" : "",
          "mr-1 w-full border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 last:mr-0"
        )}
      ></div>
    );
  }

  return (
    <>
      <div
        onClick={() => {
          if (activeObservationIds.find((id) => id === observation.id)) {
            const filtered = activeObservationIds.filter(
              (id) => id !== observation.id
            );
            if (filtered.length === 0) {
              setSearchParameters((sp) => {
                sp.delete("observations");
                return sp;
              });
              dispatch(updateActiveObservations(filtered));
            } else {
              setSearchParameters((sp) => {
                sp.set("observations", JSURL.stringify(filtered));
                return sp;
              });
              dispatch(updateActiveObservations(filtered));
            }
          } else {
            const updated = [...activeObservationIds, observation.id];
            setSearchParameters((sp) => {
              sp.set("observations", JSURL.stringify(updated));
              return sp;
            });
            dispatch(updateActiveObservations(updated));
          }
        }}
        ref={setTriggerRef}
        className={classNames(
          activeObservations?.length ? (active ? "" : "opacity-40") : "",
          "mr-1 flex w-full cursor-pointer items-center justify-center overflow-hidden border-2 border-transparent last:mr-0"
        )}
        style={{
          backgroundColor: stoneColor,
        }}
      >
        {showRosettaValues && (
          <div className="break-all text-xs font-bold text-white">
            {value !== null &&
              !isNaN(value) &&
              new Intl.NumberFormat("en", { notation: "compact" }).format(
                value
              )}
          </div>
        )}
      </div>
      {dataset.layer_type ? (
        <ObservationHoverV2
          type={dataset.layer_type}
          dataset={dataset}
          observation={observation}
          formattedData={d}
          stoneColor={stoneColor}
          visible={visible}
          setTooltipRef={setTooltipRef}
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
        />
      ) : (
        <ObservationHover
          dataset={dataset}
          observation={observation}
          formattedData={d}
          stoneColor={stoneColor}
          visible={visible}
          setTooltipRef={setTooltipRef}
          getArrowProps={getArrowProps}
          getTooltipProps={getTooltipProps}
        />
      )}
    </>
  );
}
