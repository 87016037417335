import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  buildObservationId,
  formatData,
  sortFunction,
  classNames,
} from "../utilities";
import * as JSURL from "jsurl";
import { BarsArrowDownIcon, BarsArrowUpIcon } from "@heroicons/react/24/solid";
import ObservationValue from "../hovers/values";
import { selectActiveDatasets } from "../store/applicationSlice";
import useTable from "../hooks/useTable";

export default function MeasurementsTable({
  data,
  initialSortBy,
  withUnits,
  withLink,
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const datasets = useSelector(selectActiveDatasets);

  const [sortBy, setSortBy] = useState(initialSortBy || "id");
  const [sortAscending, setSortAscending] = useState(false);

  function reSort(field) {
    // only flip the sort order if the user clicked on the active field
    if (field === sortBy) setSortAscending(!sortAscending);
    else if (field !== sortBy) setSortBy(field);
  }
  const [page, setPage] = useState(1);

  const withDatasetDefinition = (observation) => {
    const dataset = datasets.find((d) => d.id === observation.dataset_id);
    return { dataset, ...observation };
  };

  const sorted = data
    .slice()
    .sort((a, b) => sortFunction(a[sortBy], b[sortBy], sortAscending))
    .map(withDatasetDefinition);

  const { slice, range } = useTable(sorted, page, 25);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-bsr-gray-500">
                  <tr>
                    <th
                      scope="col"
                      className="cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      onClick={() => {
                        reSort("datetime_str");
                      }}
                    >
                      <div className="inline-flex">
                        <p>Date</p>
                        {sortBy === "datetime_str" &&
                          (sortAscending ? (
                            <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ) : (
                            <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ))}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      onClick={() => {
                        reSort("site_name");
                      }}
                    >
                      <div className="inline-flex">
                        <p>Site</p>
                        {sortBy === "site_name" &&
                          (sortAscending ? (
                            <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ) : (
                            <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ))}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      onClick={() => {
                        reSort("dataset_name");
                      }}
                    >
                      <div className="inline-flex">
                        <p>Dataset</p>
                        {sortBy === "dataset_name" &&
                          (sortAscending ? (
                            <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ) : (
                            <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ))}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      onClick={() => {
                        reSort("value");
                      }}
                    >
                      <div className="inline-flex">
                        <p>
                          Measurement{" "}
                          <span className="text-sm text-bsr-gray-200">
                            {withUnits ? withUnits : ""}
                          </span>
                        </p>
                        {sortBy === "value" &&
                          (sortAscending ? (
                            <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ) : (
                            <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-white" />
                          ))}
                      </div>
                    </th>
                    {withLink && (
                      <th
                        scope="col"
                        className="cursor-pointer py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-6"
                      >
                        <div className="inline-flex">
                          <p></p>
                        </div>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {slice.map((row, i) => {
                    const { link: Link } = row;
                    const uncertainty =
                      row.uncertainty || row?.metadata?.uncertainty;
                    const formattedData = formatData(row.value, uncertainty);
                    return (
                      <tr
                        key={i}
                        className="cursor-pointer hover:bg-bsr-gray-050"
                        onClick={() => {
                          const id = buildObservationId(
                            row.dataset_id,
                            row.datetime_str
                          );
                          let newSearchParams = new URLSearchParams(
                            searchParams
                          );
                          newSearchParams.set(
                            "observations",
                            JSURL.stringify([id])
                          );
                          navigate({
                            pathname: `/map/${row.site_id}`,
                            search: newSearchParams.toString(),
                          });
                        }}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {row.datetime_str}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {row.site_name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6">
                          {row.dataset_name}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6">
                          <ObservationValue
                            type={row?.dataset?.layer_type}
                            observation={row}
                            formattedData={formattedData}
                          />
                        </td>
                        {withLink && <Link measurement={row} />}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing page <span className="font-medium">{page}</span> of{" "}
                    <span className="font-medium">{range.length}</span>
                  </p>
                </div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    disabled={page === 1}
                    onClick={() => setPage((p) => (p -= 1))}
                    className={classNames(
                      page === 1 && "cursor-not-allowed",
                      "relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    )}
                  >
                    Previous
                  </button>
                  <button
                    disabled={page === range.length}
                    onClick={() => setPage((p) => (p += 1))}
                    className={classNames(
                      page === range.length && "cursor-not-allowed",
                      "relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                    )}
                  >
                    Next
                  </button>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
