import constructContextHover from "./ContextLayerHover";
import constructPathSensorHover from "./PathSensorHover";
import constructPlumeHover from "./PlumeHover";

const hovers = {
  ContextLayer: constructContextHover,
  PathSensorLegacyLayer: constructPathSensorHover,
  PathSensorLayer: constructPathSensorHover,
  PlumeLayer: constructPlumeHover,
};

export default hovers;
