import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FunnelIcon } from "@heroicons/react/24/outline";

import DataCoverageTable from "./DataCoverageTable";
import SensorTable from "./SensorTable";
import {
  selectDatasets,
  selectSites,
  selectActiveTimePeriod,
  selectSensors,
} from "./store/applicationSlice";
import {
  selectObservationsStatus,
  fetchObservations,
} from "./store/dataCoverageSlice";
import { useEffect } from "react";
import { findDatasetForAggregation } from "./utilities";

export default function DataCoverage() {
  const dispatch = useDispatch();
  const observationsStatus = useSelector(selectObservationsStatus);
  const activeTimeFrame = useSelector(selectActiveTimePeriod);
  const sites = useSelector(selectSites);
  const datasets = useSelector(selectDatasets);
  const sensors = useSelector(selectSensors);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (
      sensors.length > 0 &&
      sites.length > 0 &&
      datasets.length > 0 &&
      observationsStatus === "idle" &&
      activeTimeFrame
    ) {
      const matchingDatasets = sensors.map((sensor) => {
        return findDatasetForAggregation(
          { dataset_matcher: sensor.dataset_matcher },
          activeTimeFrame.cadence,
          datasets
        );
      });

      console.log({matchingDatasets})

      // also fetch aquisition count datasets - for GEE these are stored in separate datasets
      const coverageDatasets = [];
      matchingDatasets.forEach((dataset) => {
        console.log({ dataset });
        if (dataset?.data_coverage) {
          const coverageDataset = datasets.find(
            (d) => d.id === dataset.data_coverage.dataset_id
          );
          if (coverageDataset) {
            coverageDatasets.push(coverageDataset);
          }
        }
      });

      // prevent fetching the same data more than once. Sometimes we add same
      //   dataset to a summary more than once or some datasets share the same
      //   data_coverage dataset. We don't wannt to fetch the same data more than
      //   once because it leads to duplicate observations and throws off our aggregations
      const uniqueDatasets = [];
      const uniqueDatasetIds = [];
      matchingDatasets.concat(coverageDatasets).forEach((d) => {
        try {
          if (!uniqueDatasetIds.includes(d.id)) {
            uniqueDatasetIds.push(d.id);
            uniqueDatasets.push(d);
          }
        } catch (err) {
          console.error(err);
          console.log(d);
        }
      });

      const siteIds = sites.map((site) => site.id);
      dispatch(
        fetchObservations({
          activeTimeFrame,
          sites: siteIds,
          datasetIds: uniqueDatasetIds,
        })
      );
    }
  }, [activeTimeFrame, sensors, sites, datasets, dispatch, observationsStatus]);

  return (
    <main className="mt-5 max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
      <div className="mt-7 flex items-center">
        <div className="flex min-w-0 flex-1 items-center">
          <h2 className="text-xl font-medium leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
            Summary statistics
          </h2>
        </div>
      </div>
      <SensorTable sensors={sensors} />
      <div className="mt-7 flex items-center">
        <div className="flex min-w-0 flex-1 items-center">
          <h2 className="text-xl font-medium leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
            Coverage
          </h2>
          <div className="mt-4 flex md:ml-8 md:mt-0">
            <div className="ml-3 flex flex-col justify-center text-sm text-bsr-gray-300">
              {observationsStatus === "loading" ? "crunching data..." : ""}
            </div>
          </div>
        </div>
        <div className="flex-none">
          <div className="absolute ml-2 mt-2">
            <FunnelIcon
              className="h-5 w-5 text-bsr-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="search"
            id="search"
            className="block w-full rounded-md border-bsr-gray-200 pl-10 text-bsr-gray-700 focus:border-bsr-blue-500 focus:ring-bsr-blue-500 sm:text-sm"
            placeholder="Filter sites by name..."
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
          />
        </div>
      </div>
      <DataCoverageTable searchTerm={searchTerm} />
    </main>
  );
}
