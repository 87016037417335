import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "./shared/firebase";
import { selectAccount } from "./store/sessionSlice";
import { selectActiveTimePeriod } from "./store/applicationSlice";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

function Article({ media }) {
    return (
        <div className="flex flex-col justify-between overflow-hidden rounded bg-white shadow-lg">
            <div className="px-6 py-4">
                <div className="mb-2 text-2xl font-bold text-bsr-gray-800">
                    {media.name}
                </div>
                <div className="mb-2 text-bsr-gray-600">{media.publisher}</div>
                <p className="text-base text-gray-700">{media.description}</p>
            </div>
            <div className="px-6 pb-2 pt-4">
                {media.tags.map((tag) => {
                    return (
                        <span
                            key={tag}
                            className="mb-2 mr-2 inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700"
                        >
                            #{tag}
                        </span>
                    );
                })}
            </div>
            <Link to={media.href} target="_blank" rel="noopener noreferrer">
                <div className="flex cursor-pointer justify-end">
                    <div className="h-10 w-10 p-2">
                        <ArrowTopRightOnSquareIcon />
                    </div>
                </div>
            </Link>
        </div>
    );
}

export default function Media() {
    const account = useSelector(selectAccount);
    const [media, setMedia] = useState([]);
    const [status, setStatus] = useState("loading");
    const { startDate, endDate } = useSelector(selectActiveTimePeriod);

    console.log({ media });

    useEffect(() => {
        function fetchMedia() {
            console.log('YNWA')
            db.collection(`/accounts/${account}/media`)
                .where("published", ">=", new Date(startDate))
                .where("published", "<", new Date(endDate))
                .get()
                .then((querySnapshot) => {
                    const data = querySnapshot.docs.map((doc) => doc.data());
                    setMedia(data);
                    setStatus("success");
                })
                .catch((err) => {
                    console.log(err);
                    setMedia([]);
                    setStatus("failed");
                });
        }
        fetchMedia();
    }, [account, startDate, endDate]);

    return (
        <main className="min-h-screen max-w-screen-2xl bg-bsr-gray-100 px-4 py-8 sm:px-6 lg:px-8">
            <h2 className="mb-4 text-2xl font-bold leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
                Media
            </h2>
            <div className="grid grid-cols-2 gap-2 xl:grid-cols-3 xl:gap-4">
                {(() => {
                    if (media.length > 0 && status === "success") {
                        return media.map((media, idx) => {
                            return (
                                <Article media={media} key={`media-${idx}-${media.href}`} />
                            );
                        });
                    } else if (media.length === 0 && status === "success") {
                        return (
                            <div className="flex justify-start text-bsr-gray-300">
                                <div className="mb-2 text-2xl font-bold">No media found</div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="flex justify-start text-bsr-gray-300">
                                <div className="mb-2 text-2xl font-bold">Loading...</div>
                            </div>
                        );
                    }
                })()}
            </div>
        </main>
    );
}
