import { useState } from "react";
import Tooltip from "./shared/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import dayjs from "./dayjs";
import { Line } from "react-chartjs-2";
import { isEmpty } from "lodash";
import {
  findChangePercentage,
  formatData,
  classNames,
  isNullish,
  hexToRgb,
} from "./utilities";
import { Switch } from "@headlessui/react";

function TotalsChart({
  aggregatedObservationsByMetricAndDatetimeString,
  selectedDatasets,
  colorHash,
  activeTimePeriod,
}) {
  const [relativeMode, setRelativeMode] = useState(false);
  const [showUncertainty, setShowUncertainty] = useState(true);

  let data = {
    datasets: [],
    labels: [],
  };

  let options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        // labels: {
        //   filter: (item, chart) => {
        //     return (
        //       !item.text.includes("Uncertainty Max") &&
        //       !item.text.includes("Uncertainty Min")
        //     );
        //   },
        // },
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return dayjs(tooltipItems[0].parsed.x).format(
              activeTimePeriod.cadence === "monthly"
                ? "MMM YYYY"
                : activeTimePeriod.cadence === "daily"
                ? "MMM D, YYYY"
                : "YYYY"
            );
          },
          label: function (context) {
            if (context.raw.y !== null) {
              const labels = [];
              const d = formatData(
                context.raw.yValue,
                context.raw.yUncertainty
              );
              labels.push(
                `${d.value}${
                  d.uncertainty_display ? ` ±${d.uncertainty_display}%` : ""
                }${
                  isNullish(context.dataset.units)
                    ? ""
                    : ` ${context.dataset.units}`
                } ${
                  isNullish(context.raw.yPercentage)
                    ? ""
                    : ` (${formatData(context.raw.yPercentage).value}%)`
                }`
              );
              if (!isNullish(context.raw.sites_reporting)) {
                labels.push(`Sites reporting: ${context.raw.sites_reporting}`);
              }
              if (!isNullish(context.raw.total_readings)) {
                labels.push(`Num. measurements: ${context.raw.total_readings}`);
              }
              return labels;
            } else {
              return "";
            }
          },
        },
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit:
            activeTimePeriod.cadence === "yearly"
              ? "year"
              : activeTimePeriod.cadence === "monthly"
              ? "month"
              : "day",
        },
      },
    },
    parsing: { xAxisKey: "x", yAxisKey: "y" },
  };

  if (
    aggregatedObservationsByMetricAndDatetimeString &&
    !isEmpty(aggregatedObservationsByMetricAndDatetimeString) &&
    selectedDatasets.length > 0
  ) {
    selectedDatasets.forEach((metric, idx) => {
      // only add metric to the chart if the metric has data
      if (!aggregatedObservationsByMetricAndDatetimeString?.[metric.name])
        return;

      const plot = [];
      let compareToValue = null;

      Object.keys(
        aggregatedObservationsByMetricAndDatetimeString[metric.name]
      ).forEach((dateKey, i, arr) => {
        const aggregatedValue =
          aggregatedObservationsByMetricAndDatetimeString[metric.name][dateKey];
        if (aggregatedValue.total === null) return;

        if (compareToValue === null) {
          compareToValue = aggregatedValue.total;
        }
        const yPercentage = findChangePercentage(
          compareToValue,
          aggregatedValue.total
        );
        plot.push({
          x: dayjs(dateKey).valueOf(),
          y: relativeMode ? yPercentage : aggregatedValue.total,
          yValue: aggregatedValue.total,
          yUncertainty: aggregatedValue.total_uncertainty,
          yPercentage,
          sites_reporting: aggregatedValue.sites_reporting,
          total_readings: aggregatedValue.total_readings,
        });
      });

      // use a consolidated y axis... or use separate y axis for each metric
      const yAxisID = "y" + idx;
      const dataset = {
        label: metric.name,
        lineTension: 0.5,
        data: plot,
        yAxisID: yAxisID,
        units: metric.units,
        fill: false,
        pointRadius: 7,
        backgroundColor: colorHash[metric.name].hex,
        borderColor: colorHash[metric.name].hex,
      };

      data.datasets.push(dataset);

      const hasUncertainty = plot.find((point) => point.yUncertainty !== null);
      if (hasUncertainty && showUncertainty) {
        const plotUncertaintyMax = [];

        Object.keys(
          aggregatedObservationsByMetricAndDatetimeString[metric.name]
        ).forEach((dateKey, i, arr) => {
          const aggregatedValue =
            aggregatedObservationsByMetricAndDatetimeString[metric.name][
              dateKey
            ];
          if (aggregatedValue.total === null) return;

          const uncertaintyPercentage =
            Math.abs(
              aggregatedValue.total_uncertainty / aggregatedValue.total
            ) * 100;

          const yPercentage = findChangePercentage(
            compareToValue,
            aggregatedValue.total
          );
          plotUncertaintyMax.push({
            x: dayjs(dateKey).valueOf(),
            y: relativeMode
              ? yPercentage + uncertaintyPercentage
              : aggregatedValue.total + aggregatedValue.total_uncertainty,
            yValue: aggregatedValue.total,
            yUncertainty: aggregatedValue.total_uncertainty,
            yPercentage: yPercentage,
          });
        });

        const plotUncertaintyMin = [];

        Object.keys(
          aggregatedObservationsByMetricAndDatetimeString[metric.name]
        ).forEach((dateKey, i, arr) => {
          const aggregatedValue =
            aggregatedObservationsByMetricAndDatetimeString[metric.name][
              dateKey
            ];

          if (aggregatedValue.total === null) return;

          const uncertaintyPercentage =
            Math.abs(
              aggregatedValue.total_uncertainty / aggregatedValue.total
            ) * 100;

          const yPercentage = findChangePercentage(
            compareToValue,
            aggregatedValue.total
          );

          plotUncertaintyMin.push({
            x: dayjs(dateKey).valueOf(),
            y: relativeMode
              ? yPercentage - uncertaintyPercentage
              : aggregatedValue.total - aggregatedValue.total_uncertainty,
            yValue: aggregatedValue.total,
            yUncertainty: aggregatedValue.total_uncertainty,
            yPercentage: yPercentage,
          });
        });

        const datasetUncertaintyMin = {
          label: `${metric.name} Uncertainty Min`,
          lineTension: 0.5,
          data: plotUncertaintyMin,
          yAxisID: yAxisID,
          // units: metric.units,
          fill: false,
          pointRadius: 0,
          borderWidth: 0,
        };
        data.datasets.push(datasetUncertaintyMin);

        const datasetUncertaintyMax = {
          label: `${metric.name} Uncertainty Max`,
          lineTension: 0.5,
          data: plotUncertaintyMax,
          yAxisID: yAxisID,
          // units: metric.units,
          fill: "-1",
          pointRadius: 0,
          backgroundColor: `rgba(${hexToRgb(colorHash[metric.name].hex).join(
            ","
          )}, 0.2)`,
          borderWidth: 0,
        };
        data.datasets.push(datasetUncertaintyMax);
      }

      if (relativeMode) {
        options.scales[yAxisID] = {
          title: {
            display: true,
            text: "Percent change",
          },
        };
      } else {
        options.scales[yAxisID] = {
          title: {
            display: true,
            text: `${metric.name} ${
              metric.units ? "(" + metric.units + ")" : ""
            }`,
          },
          ticks: {
            // chartjs was adding many unnecessary zeros after decimal for small numbers
            // we apply this transformation to avoid this.
            callback: (value) =>
              Math.abs(value) < 0.001 && Math.abs(value) > 0
                ? value.toExponential(2)
                : value,
          },
        };

        if (!isNullish(metric.yAxisMin) && !isNullish(metric.yAxisMax)) {
          options.scales[yAxisID].min = metric.yAxisMin;
          options.scales[yAxisID].max = metric.yAxisMax;
        }

        if (selectedDatasets.length > 1) {
          options.scales[yAxisID].title.color = colorHash[metric.name].hex;
          options.scales[yAxisID].grid = {
            display: false,
          };
          options.scales[yAxisID].ticks = { color: colorHash[metric.name].hex };
        }
      }
    });
  }

  const someDataAvailable = data.datasets.reduce((acc, cur) => {
    return acc || cur.data?.length > 0;
  }, false);

  return (
    <>
      <div className="flex items-center justify-end">
        <Tooltip
          width={300}
          position="top"
          content={
            "Use the toggle to enable/disable uncertainty. Metric uncertainty is displayed as a shaded region on the plot."
          }
        >
          <InformationCircleIcon className="mx-1 h-4 w-4 text-bsr-gray-400"></InformationCircleIcon>
        </Tooltip>
        <label className="mr-2 text-xs text-bsr-gray-500">Uncertainty</label>
        <Switch
          checked={showUncertainty}
          onChange={setShowUncertainty}
          className={classNames(
            showUncertainty ? "bg-bsr-blue-500" : "bg-bsr-gray-200",
            "relative mr-4 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-bsr-blue-500 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              showUncertainty ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
        <Tooltip
          width={300}
          position="top"
          content={
            "Select Absolute or Relative chart scale. Absolute (default) displays the metric values as is. Relative displays the metric values as a percentage of the first value for the selected time period."
          }
        >
          <InformationCircleIcon className="mx-1 h-4 w-4 text-bsr-gray-400"></InformationCircleIcon>
        </Tooltip>
        <label className="mr-2 text-xs text-bsr-gray-500">Chart scale</label>
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className={classNames(
              relativeMode === false
                ? "z-10 border-bsr-blue-500 text-bsr-blue-500 ring-1 ring-bsr-blue-500"
                : "border-bsr-gray-100 text-bsr-gray-400",
              "relative inline-flex items-center rounded-l-md border bg-white px-4 py-1 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none"
            )}
            onClick={() => setRelativeMode(false)}
          >
            Absolute
          </button>
          <button
            type="button"
            className={classNames(
              relativeMode === true
                ? "z-10 border-bsr-blue-500 text-bsr-blue-500 ring-1 ring-bsr-blue-500"
                : "border-bsr-gray-100 text-bsr-gray-400",
              "relative -ml-px inline-flex items-center rounded-r-md border bg-white px-4 py-1 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:outline-none"
            )}
            onClick={() => setRelativeMode(true)}
          >
            Relative
          </button>
        </span>
      </div>
      {someDataAvailable ? (
        <div className="h-96 w-full overflow-hidden px-4 py-5">
          <Line data={data} options={options} />
        </div>
      ) : (
        <div className="mt-4 flex w-full items-center justify-center rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 py-5 text-center hover:border-gray-300">
          <p className="block text-sm font-medium text-bsr-gray-700">
            No data available. Select another dataset and/or time period.
          </p>
        </div>
      )}
    </>
  );
}

export default TotalsChart;
