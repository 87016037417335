import { CalendarIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";
import dayjs from "./dayjs";
import {
  selectActiveDatasets,
  selectActiveTimePeriod,
  updateActiveTimeframe,
} from "./store/applicationSlice";
import { timeFrames } from "./constants";
import { translateDatasetToActiveTimePeriod } from "./utilities";

export default function RosettaBreadcrumbs() {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const activeDatasets = useSelector(selectActiveDatasets);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  let breadcrumbs = [];

  if (activeTimePeriod.cadence === "yearly") {
    breadcrumbs.push({
      name: activeTimePeriod.name,
    });
  }

  if (activeTimePeriod.cadence === "monthly") {
    breadcrumbs.push({
      name: dayjs(activeTimePeriod.startDate).format("YYYY"),
    });
  }

  if (activeTimePeriod.cadence === "daily") {
    breadcrumbs.push({
      name: dayjs(activeTimePeriod.startDate).format("YYYY"),
      timeFrame: {
        name: dayjs(activeTimePeriod.startDate).format("YYYY"),
        cadence: "monthly",
        endDate: dayjs(activeTimePeriod.startDate)
          .startOf("year")
          .add(1, "year")
          .toISOString(),
        startDate: dayjs(activeTimePeriod.startDate)
          .startOf("year")
          .toISOString(),
      },
    });
    breadcrumbs.push({
      name: dayjs(activeTimePeriod.startDate).format("MMMM"),
    });
  }

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div
            className="cursor-pointer text-bsr-gray-500 hover:text-bsr-gray-400"
            onClick={() => {
              const allTime = timeFrames.find((tf) =>
                tf.name.includes("All time")
              );
              let newSearchParams = new URLSearchParams(searchParams);
              newSearchParams.set("timeframe", JSURL.stringify(allTime));
              const newDatasetIds = activeDatasets.map((d) => {
                return translateDatasetToActiveTimePeriod(
                  d.id,
                  allTime.cadence
                );
              });
              newSearchParams.set("datasets", JSURL.stringify(newDatasetIds));
              setSearchParams(newSearchParams);
              dispatch(updateActiveTimeframe(allTime));
            }}
          >
            <CalendarIcon
              className="h-5 w-5 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="sr-only">Home</span>
          </div>
        </li>
        {breadcrumbs.map((breadcrumb) => (
          <li key={breadcrumb.name}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {breadcrumb.timeFrame ? (
                <div
                  className="ml-4 cursor-pointer text-sm font-medium text-bsr-gray-500 hover:text-bsr-gray-400 hover:underline"
                  onClick={() => {
                    let newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.set(
                      "timeframe",
                      JSURL.stringify(breadcrumb.timeFrame)
                    );
                    const newDatasetIds = activeDatasets.map((d) => {
                      return translateDatasetToActiveTimePeriod(
                        d.id,
                        breadcrumb.timeFrame.cadence
                      );
                    });
                    newSearchParams.set(
                      "datasets",
                      JSURL.stringify(newDatasetIds)
                    );
                    setSearchParams(newSearchParams);
                    dispatch(updateActiveTimeframe(breadcrumb.timeFrame));
                  }}
                >
                  {breadcrumb.name}
                </div>
              ) : (
                <div className="ml-4 text-sm font-medium text-bsr-gray-500">
                  {breadcrumb.name}
                </div>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
