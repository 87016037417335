import { Popover, Transition } from "@headlessui/react";
import SitesPanel from "./SitesPanel";
import { Fragment } from "react";
import DropdownButton from "./shared/DropdownButton";
import {
  selectActiveGrouping,
  selectGroupings,
  selectSiteById,
} from "./store/applicationSlice";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export default function SitesDropdown() {
  const { siteId } = useParams();
  const site = useSelector((state) => selectSiteById(state, siteId));
  const groupings = useSelector(selectGroupings);
  const activeGrouping = useSelector(selectActiveGrouping);
  console.log(groupings);
  console.log(activeGrouping);
  console.log(groupings?.[activeGrouping]);
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button>
            <DropdownButton
              selected={
                site?.name && site?.alternateSiteId ? (
                  <>
                    {site.name}{" "}
                    <span className="font-light">{site?.alternateSiteId}</span>
                  </>
                ) : (
                  site?.name
                )
              }
              open={open}
              label="Site"
              defaultValue={
                activeGrouping?.name ? activeGrouping.name : "All Sites"
              }
              first={true}
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-60 -mx-3 w-96 transform shadow-lg">
              {({ close }) => <SitesPanel closePanel={close} />}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
