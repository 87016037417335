import { createPortal } from "react-dom";
import { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "./dayjs";
import { DatePicker } from "antd";
import Button from "./shared/Button";
import {
  selectOpticalLayers,
  selectActiveOpticalLayer,
  updateActiveOpticalLayer,
  fetchOpticalLayers,
  selectActiveTimePeriod,
} from "./store/applicationSlice";
import { roundToNearestGranularity, classNames } from "./utilities";
import { XMarkIcon } from "@heroicons/react/24/outline";
import streetPng from "./assets/street.png";
import satellitePng from "./assets/satellite.png";
import terrainPng from "./assets/terrain.png";
import OpticalImagePreview from "./OpticalImagePreview";
import { usePopperTooltip } from "react-popper-tooltip";

function CalendarCell({ collect, activeOpticalLayer, dispatch, current }) {
  const style = {};

  if (collect) {
    style.border = "1px solid #1890ff";
    style.borderRadius = "50%";
  }
  if (collect && collect.resolution <= 1.5) {
    // higher quality images get golden ring :D
    style.border = "1px solid #FFD700";
  }
  // if (collect && collect?.id === activeOpticalLayer?.id) {
  //   console.log("TIGER");
  //   console.log(collect?.id, activeOpticalLayer?.id);
  //   style.backgroundColor = "#1890ff";
  // }

  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ placement: "auto-start" });

  if (!collect) {
    return <div>{current.date()}</div>;
  }

  return (
    <>
      <div
        ref={setTriggerRef}
        onClick={() => {
          if (
            collect &&
            activeOpticalLayer &&
            collect.id === activeOpticalLayer.id
          ) {
            dispatch(updateActiveOpticalLayer(null));
          } else {
            dispatch(updateActiveOpticalLayer(collect));
          }
        }}
        className="ant-picker-cell-inner"
        style={style}
      >
        {current.date()}
      </div>
      {createPortal(
        <OpticalImagePreview
          visible={visible}
          activeImage={collect}
          getTooltipProps={getTooltipProps}
          setTooltipRef={setTooltipRef}
        />,
        document.body
      )}
    </>
  );
}

export default function OpticalLayersPopup({ site, closeModal, mapRef }) {
  const dispatch = useDispatch();
  const opticalLayers = useSelector(selectOpticalLayers);
  const activeOpticalLayer = useSelector(selectActiveOpticalLayer);
  const activeTimePeriod = useSelector(selectActiveTimePeriod);

  console.log({ activeTimePeriod });

  const [start, setStart] = useState(
    dayjs(activeTimePeriod.startDate).startOf("month")
  );
  const [end, setEnd] = useState(
    dayjs(activeTimePeriod.startDate).endOf("month")
  );

  const [mapType, setMapType] = useState(
    mapRef ? mapRef.getMapTypeId() : "roadmap"
  );

  useEffect(() => {
    dispatch(
      fetchOpticalLayers({
        siteId: site.id,
        start: start.toISOString(),
        end: end.toISOString(),
      })
    );
  }, [dispatch, start, end, site.id]);

  const sorted = useMemo(() => {
    try {
      return opticalLayers.reduce((acc, cur) => {
        if (!acc[cur.date]) {
          acc[cur.date] = cur;
        }
        // only need to pick optimal image when there are 2+ collects competing on same day
        if (cur.id !== acc[cur.date].id) {
          const fuzzyResolutionCandidate = roundToNearestGranularity(
            cur.resolution,
            0.5
          );
          const fuzzyResolutionIncumbent = roundToNearestGranularity(
            acc[cur.date].resolution,
            0.5
          );
          if (fuzzyResolutionCandidate < fuzzyResolutionIncumbent) {
            acc[cur.date] = cur;
          } else if (fuzzyResolutionCandidate === fuzzyResolutionIncumbent) {
            const fuzzyCloudCoverageCandidate = roundToNearestGranularity(
              cur.cloud_cover,
              0.2
            );
            const fuzzyCloudCoverageIncumbent = roundToNearestGranularity(
              acc[cur.date].cloud_cover,
              0.2
            );
            if (fuzzyCloudCoverageCandidate < fuzzyCloudCoverageIncumbent) {
              acc[cur.date] = cur;
            } else if (
              fuzzyCloudCoverageCandidate === fuzzyCloudCoverageIncumbent
            ) {
              const tasked = cur.img_type === "task";
              if (tasked) {
                acc[cur.date] = cur;
              }
            }
          }
        }

        return acc;
      }, {});
    } catch (err) {
      console.log("failed to bin optical collects into dates");
      console.log(err);
      return {};
    }
  }, [opticalLayers]);

  return (
    <div className="items-start shadow-xl">
      <div
        style={{ zIndex: -1 }}
        className="absolute -right-[10px] top-[4px] z-10 h-5 w-5 rotate-45 bg-bsr-gray-050"
      ></div>

      <div className="flex rounded-b-sm bg-bsr-gray-050 shadow-xl">
        <div>
          {activeOpticalLayer && (
            <div className="m-2 flex items-center justify-between bg-bsr-gray-200 p-2 shadow-sm">
              <div>
                <span className="text-sm tracking-wide text-bsr-gray-900">
                  Selected basemap
                </span>
                <div className="truncate text-xl font-extrabold tracking-wider text-bsr-gray-900">
                  {`${dayjs(activeOpticalLayer.date).format("MMM D, YYYY")}  ${
                    activeOpticalLayer.provider
                  }`}
                </div>
              </div>
              <div className="flex">
                <div
                  onClick={() => dispatch(updateActiveOpticalLayer(null))}
                  title="deselect optical image"
                  className="cursor-pointer"
                >
                  <XMarkIcon className="mr-2 h-5 w-5 text-bsr-gray-500" />
                </div>
              </div>
            </div>
          )}
          <div className="relative flex items-center px-2 py-2">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="mx-4 flex-shrink text-xs text-gray-400">
              Choose basemap
            </span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <div className="min-h-[320px]">
            <DatePicker
              popupStyle={{
                position: "static",
                zIndex: 50,
                overflow: "visible",
                padding: 5,
              }}
              size="small"
              value={start}
              style={{
                display: "none",
              }}
              renderExtraFooter={() => {
                return (
                  <div className="flex items-center space-x-2 p-1 text-gray-400">
                    <div className="flex items-center text-xs">
                      <div className="mr-1 h-3 w-3 rounded-full border border-bsr-blue-500"></div>
                      <div>lower resolution</div>
                    </div>
                    <div className="flex items-center text-xs">
                      <div className="mr-1 h-3 w-3 rounded-full border border-bsr-yellow-500"></div>
                      <div>higher resolution</div>
                    </div>
                  </div>
                );
              }}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              cellRender={(current, info) => {
                if (info.type !== "date") {
                  return info.originNode;
                }
                const collect = sorted[current.format("YYYY-MM-DD")];

                return (
                  <CalendarCell
                    dispatch={dispatch}
                    activeOpticalLayer={activeOpticalLayer}
                    collect={collect}
                    current={current}
                  />
                );
              }}
              open
              showToday={false}
              onPanelChange={(value, mode) => {
                const start = value.startOf("month");
                const end = value.endOf("month");
                setStart(start);
                setEnd(end);
              }}
            />
          </div>
          <div className="relative flex items-center px-2 py-2">
            <div className="flex-grow border-t border-gray-400"></div>
            <span className="mx-4 flex-shrink text-xs text-gray-400">
              Choose background
            </span>
            <div className="flex-grow border-t border-gray-400"></div>
          </div>
          <div className="flex justify-around text-xs">
            <div className="flex flex-col items-center text-bsr-gray-400">
              <div
                onClick={() => {
                  setMapType("satellite");
                  mapRef.setMapTypeId("satellite");
                }}
                className={classNames(
                  mapType === "satellite" || mapType === "hybrid"
                    ? "border-4 border-bsr-blue-300"
                    : "",
                  "box-border h-[64px] w-[64px] rounded-xl hover:border-4 hover:border-bsr-blue-300"
                )}
              >
                <img
                  alt="sprite for selecting google basemap satellite"
                  src={satellitePng}
                />
              </div>
              <div className="my-1">Satellite</div>
              {(mapType === "satellite" || mapType === "hybrid") && (
                <div className="flex justify-between">
                  <label>+ labels</label>
                  <input
                    checked={mapType === "hybrid"}
                    className="ml-1"
                    onChange={(e) => {
                      if (e.target.checked) {
                        mapRef.setMapTypeId("hybrid");
                        setMapType("hybrid");
                      } else {
                        mapRef.setMapTypeId("satellite");
                        setMapType("satellite");
                      }
                    }}
                    type="checkbox"
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col items-center text-bsr-gray-400">
              <div
                onClick={() => {
                  setMapType("roadmap");
                  mapRef.setMapTypeId("roadmap");
                }}
                className={classNames(
                  mapType === "roadmap" ? "border-4 border-bsr-blue-300" : "",
                  "box-border h-[64px] w-[64px] rounded-xl hover:border-4 hover:border-bsr-blue-300"
                )}
              >
                <img
                  alt="sprite for selecting google basemap default"
                  src={streetPng}
                />
              </div>
              <div className="my-1">Roadmap</div>
            </div>

            <div className="flex flex-col items-center text-bsr-gray-400">
              <div
                onClick={() => {
                  setMapType("terrain");
                  mapRef.setMapTypeId("terrain");
                }}
                className={classNames(
                  mapType === "terrain" ? "border-4 border-bsr-blue-300" : "",
                  "box-border h-[64px] w-[64px] rounded-xl hover:border-4 hover:border-bsr-blue-300"
                )}
              >
                <img
                  alt="sprite for selecting google basemap terrain"
                  src={terrainPng}
                />
              </div>
              <div className="my-1">Terrain</div>
            </div>
          </div>
          <div className="mt-auto flex w-full items-center justify-end p-2">
            <Button type="primary" onClick={closeModal}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
