export default function constructContextHover(properties) {
  console.log({ properties });
  let str;
  let timestamp = properties?.timestamp;
  let layerId = properties?.layer_id;

  str = `
    ${layerId ? `<div>id: ${layerId}</div>` : ""}
    ${timestamp ? `<div>last updated: ${timestamp}</div>` : ""}
    `;

  if (properties.properties_dict) {
    Object.keys(properties.properties_dict).forEach((key) => {
      str += `<div>${key}: ${properties.properties_dict[key]}</div>`;
    });
  }

  return str;
}
