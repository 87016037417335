import { useState } from "react";
import * as JSURL from "jsurl";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";

import { useEffect } from "react";
import TimeframePanel from "./TimeframePanel";

import {
  selectActiveTimePeriod,
  updateActiveTimeframe,
} from "./store/applicationSlice";
import { useSelector, useDispatch } from "react-redux";
import { timeFrames, DEFAULT_TIME_FRAME } from "./constants";
import { useSearchParams } from "react-router-dom";

export default function TimeDropdown({ options }) {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);

  // initialize activeTimeframe based on url search param
  useEffect(() => {
    const urlTimeframe = searchParams.get("timeframe")
      ? JSURL.parse(searchParams.get("timeframe"))
      : timeFrames[DEFAULT_TIME_FRAME];

    // initialize activeTimePeriod based on url search param (user loads a link with activeTimePeriod in url)
    if (activeTimePeriod === null) {
      console.log(urlTimeframe);
      dispatch(updateActiveTimeframe(urlTimeframe));
    }

    // when activeTimePeriod changes in url search params, update activeTopic (user pressed back button)
    if (activeTimePeriod && activeTimePeriod.name !== urlTimeframe.name) {
      dispatch(updateActiveTimeframe(urlTimeframe));
    }
  }, [dispatch, activeTimePeriod, searchParams, setSearchParams]);

  return (
    <div className="-mx-3 w-[300px] px-3 pt-3 pb-[10px] text-left">
      <div className="text-xs tracking-wider text-bsr-gray-500">Timeframe</div>
      <div className="flex items-center justify-between ">
        <TimeframePanel onOpenChange={setOpen} />
        {open ? (
          <ChevronUpIcon
            className="ml-2 h-4 w-4 text-bsr-gray-500"
            aria-hidden="true"
          />
        ) : (
          <ChevronDownIcon
            className="ml-2 h-4 w-4 text-bsr-gray-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
}
