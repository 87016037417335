import firebase from "firebase/compat/app";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../services/Api";
import {
  auth,
  serializeFirebaseUser,
  googleAuthProvider,
} from "../shared/firebase";

const initialState = {
  data: null,
  // default to loading instead of idle because firebase tries to load the session from a coookie when the page loads and there is no dispatch event for that.
  status: "loading",
  error: null,
};

export const signInWithEmailAndPassword = createAsyncThunk(
  "session/signInWithEmailAndPassword",
  async ({ email, password }) => {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    return serializeFirebaseUser(user);
  }
);

export async function signInWithSso(email) {
  try {
    const domain = email.match(/@(.+)/)[1];
    const ssoAddress = await api.fetchSsoConfiguration(domain);
    if (ssoAddress) {
      const provider = new firebase.auth.OAuthProvider(ssoAddress);
      provider.setCustomParameters({
        prompt: "login",
        login_hint: email,
      });
      auth.signInWithPopup(provider);
    } else {
      console.warn("failed to get provider");
    }
  } catch (err) {
    console.warn(
      "something went wrong while attempting to resolve sso provider"
    );
    throw err;
  }
}

export const signInWithGoogle = () => {
  auth.signInWithPopup(googleAuthProvider);
};

export const createUserWithEmailAndPassword = createAsyncThunk(
  "session/createUserWithEmailAndPassword",
  async ({ email, password }) => {
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    return serializeFirebaseUser(user);
  }
);

export const signOut = createAsyncThunk("session/signOut", async () => {
  return await auth.signOut();
});

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionAdded: (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
      state.error = null;
    },
    sessionRemoved: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: {
    [signInWithEmailAndPassword.pending]: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = null;
    },
    // [signInWithEmailAndPassword.fulfilled]: (state, action) => {
    //   state.data = action.payload;
    //   state.status = "succeeded";
    //   state.error = null;
    // },
    [signInWithEmailAndPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.data = null;
      state.error = action.error;
    },

    [createUserWithEmailAndPassword.pending]: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = null;
    },
    [createUserWithEmailAndPassword.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.status = "succeeded";
      state.error = null;
    },
    [createUserWithEmailAndPassword.rejected]: (state, action) => {
      state.status = "failed";
      state.data = null;
      state.error = action.error;
    },

    [signOut.pending]: (state) => {
      state.status = "loading";
      state.data = null;
      state.error = null;
    },
    [signOut.fulfilled]: (state, action) => {
      state.data = null;
      state.status = "idle";
      state.error = null;
    },
    [signOut.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error;
      state.data = null;
    },
  },
});

export const { sessionAdded, sessionRemoved } = sessionSlice.actions;

export const selectSession = (state) => state.session.data;
export const selectSessionStatus = (state) => state.session.status;
export const selectSessionError = (state) => state.session.error;
export const selectAccount = (state) => state.session.data?.account;

export default sessionSlice.reducer;
