import dayjs from "./dayjs";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as JSURL from "jsurl";
import { useSearchParams, useLocation } from "react-router-dom";
import {
  selectActiveDatasets,
  selectActiveTimePeriod,
  updateActiveTimeframe,
} from "./store/applicationSlice";
import { timeFrames } from "./constants";
import { translateDatasetToActiveTimePeriod } from "./utilities";

const DAILY_RESOLUTION_LIMIT = 45;
const MONTHLY_RESOLUTION_LIMIT = 500;

const computeTimeframe = ([start, end]) => {
  const delta = Math.abs(start.diff(end, "day"));
  const cadence =
    delta > MONTHLY_RESOLUTION_LIMIT
      ? "yearly"
      : delta > DAILY_RESOLUTION_LIMIT
      ? "monthly"
      : "daily";
  return {
    name: `Custom ${dayjs(start).format("MM-DD-YYYY")} - ${dayjs(end).format(
      "MM-DD-YYYY"
    )}`,
    cadence: cadence,
    // go to start of day to maximally inclusive
    startDate: dayjs(start).startOf("day").toISOString(),
    // add one day so that we include all 24 hours of the end date that the user selects
    endDate: dayjs(end).add(1, "day").toISOString(),
  };
};

function TimeframePanel({ onOpenChange }) {
  const dispatch = useDispatch();
  let location = useLocation();
  const activeTimeframe = useSelector(selectActiveTimePeriod);
  const activeDatasets = useSelector(selectActiveDatasets);
  const [searchParams, setSearchParams] = useSearchParams();
  const rangePresets = timeFrames.map((frame) => {
    return {
      label: frame.name,
      value: [dayjs(frame.startDate), dayjs(frame.endDate)],
    };
  });

  return (
    <DatePicker.RangePicker
      className="font-bold"
      value={[
        dayjs(activeTimeframe?.startDate),
        dayjs(activeTimeframe?.endDate),
      ]}
      disabled={
        location.pathname.includes("/reports") ||
        location.pathname.includes("/media")
      }
      onOpenChange={onOpenChange}
      presets={rangePresets}
      style={{ borderWidth: 0 }}
      popupStyle={{ borderRadius: 0 }}
      onChange={(dates) => {
        console.log({ dates });
        const timeframe = computeTimeframe(dates);
        let newSearchParams = new URLSearchParams(searchParams);
        const newDatasetIds = activeDatasets.map((d) => {
          return translateDatasetToActiveTimePeriod(d.id, timeframe.cadence);
        });
        newSearchParams.set("datasets", JSURL.stringify(newDatasetIds));
        newSearchParams.set("timeframe", JSURL.stringify(timeframe));
        setSearchParams(newSearchParams);
        dispatch(updateActiveTimeframe(timeframe));
      }}
    ></DatePicker.RangePicker>
  );
}

export default TimeframePanel;
