import { formatData, formatDate } from "../../utilities";

// function WindDirectionArrow({ degrees }) {
//   return `
//     <div style="background-color:red; display: 'inline-block'; transform: rotate(${degrees}deg);">
//       <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" style="height: 20px; width: 20px;">
//         <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
//       </svg>
//     </div>
//   `;
// }

export default function constructPlumeHover(properties) {
  console.log(properties);
  let geometry = properties?.geometry;
  let uncertainty_normalized =
    properties?.uncertainty || properties?.uncertainty_outside;
  let emission_normalized =
    properties?.emission_rate || properties?.emission_rate_outside;
  let platform = properties?.platform;
  const { value, uncertainty_display } = formatData(
    emission_normalized,
    uncertainty_normalized
  );
  let str = `
    <div>${formatDate(properties.time, {
      cadence: "daily",
      precise: true,
    })} UTC</div>
    <div>
      ${
        value
          ? `Emission rate: ${value} ${
              properties.units ? properties.units : "kg/hr"
            }`
          : "No quantity published"
      } 
      ${uncertainty_display ? `±${uncertainty_display}%` : ""}
    </div>
    ${platform ? `<div>Platform: ${platform}</div>` : ""}
    <div>
      Lat: ${geometry?.coordinates[1].toFixed(4)}, 
      Lng: ${geometry?.coordinates[0].toFixed(4)}
    </div>
    `;

  if (
    properties?.wind_speed ||
    properties?.wind_direction ||
    properties?.wind_source
  ) {
    str += `<div>`;
    if (properties?.wind_speed) {
      str += `Wind: ${formatData(properties?.wind_speed).value} ${
        properties.wind_speed_units ? properties.wind_speed_units : "m/s"
      } `;
    }
    // if (properties?.wind_direction) {
    //   str += `${WindDirectionArrow({ degrees: properties?.wind_direction })} `;
    // }
    if (properties?.wind_source) {
      str += ` (${properties?.wind_source})`;
    }
    str += `</div>`;
  }
  return str;
}
