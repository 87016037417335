import { useState } from "react";
import { classNames, buildObservationId } from "./utilities";

import Legend from "./shared/Legend";

export default function LegendManager({
  activeObservations,
  numberOfRosettaMetrics,
  rosettaOpen,
}) {
  const initialActiveObservationId = buildObservationId(
    activeObservations[0].dataset.id,
    activeObservations[0].datetime_str
  );

  const [activeObservationId, setActiveObservationId] = useState(
    initialActiveObservationId
  );

  const activeObservation =
    activeObservations.find((o) => o.id === activeObservationId) ||
    activeObservations[activeObservations.length - 1];

  // position the legend based on the height of rosetta
  let legendBottom = "bottom-[30px]";
  if (rosettaOpen && numberOfRosettaMetrics === 1) {
    legendBottom = "bottom-[215px]";
  } else if (rosettaOpen && numberOfRosettaMetrics === 2) {
    legendBottom = "bottom-[255px]";
  } else if (rosettaOpen && numberOfRosettaMetrics >= 3) {
    legendBottom = "bottom-[295px]";
  }

  return (
    <div className={`${legendBottom} absolute left-24 z-30 max-w-[270px]`}>
      <div className="hidden sm:block">
        <nav
          className="relative z-0 flex divide-x divide-gray-200 rounded-sm shadow"
          aria-label="Tabs"
        >
          {activeObservations.map((o, tabIdx) => {
            return (
              <button
                key={o.id}
                title={`${activeObservation.dataset.name} ${activeObservation.datetime_str}`}
                onClick={() => setActiveObservationId(o.id)}
                className={classNames(
                  activeObservation.id === o.id
                    ? "text-gray-900"
                    : "text-gray-500 hover:text-gray-700",
                  tabIdx === 0 ? "rounded-tl-sm" : "",
                  tabIdx === activeObservations.length - 1
                    ? "rounded-tr-sm"
                    : "",
                  "group relative flex-1 truncate bg-white p-2 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
                )}
              >
                <span>{o.dataset.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    activeObservation.id === o.id
                      ? "bg-bsr-blue-500"
                      : "bg-transparent",
                    "absolute inset-x-0 bottom-0 h-0.5"
                  )}
                />
              </button>
            );
          })}
        </nav>
      </div>
      {activeObservation && (
        <Legend
          activeObservation={activeObservation}
          id={activeObservation.id}
          key={`legend-${activeObservation.id}`}
        />
      )}
    </div>
  );
}
