import { useSelector } from "react-redux";
import { selectActiveTimePeriod } from "../../store/applicationSlice";
import { formatDate, formatData, isNullish } from "../../utilities";
import "react-popper-tooltip/dist/styles.css";

import ObservationValue from "../values";

export default function PlumeHover({
  dataset,
  observation,
  formattedData,
  stoneColor,
  setTooltipRef,
  getTooltipProps,
  getArrowProps,
  visible,
}) {
  // TODO reduce observation.metadata.acquisitions and bin into detects, non-detects, non-quantifiables
  // and then render them into the tooltip
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  if (visible) {
    return (
      <div
        ref={setTooltipRef}
        {...getTooltipProps({
          className: "tooltip-container",
        })}
      >
        <div {...getArrowProps({ className: "tooltip-arrow" })}></div>
        <div className="my-1 text-sm font-semibold text-bsr-gray-700">
          {formatDate(observation.datetime_str, {
            cadence: activeTimePeriod.cadence,
          })}
        </div>
        <div style={{ color: stoneColor }} className="text-lg">
          <ObservationValue
            type={observation.dataset.layer_type}
            observation={observation}
            formattedData={formattedData}
          />
        </div>
        {!isNullish(observation.metadata?.emission_rate_outside) && (
          <div className="text-xs text-bsr-gray-500">
            Outside emission rate:{" "}
            {formatData(observation.metadata?.emission_rate_outside).value}{" "}
            kg/hr
          </div>
        )}
        {!isNullish(observation.metadata?.mean_background) && (
          <div className="text-xs text-bsr-gray-500">
            Mean background:{" "}
            {formatData(observation.metadata.mean_background).value} ppm
          </div>
        )}
        {observation?.metadata?.total_readings &&
          observation?.metadata?.total_readings > 0 && (
            <div className="text-xs text-bsr-gray-500">
              Num readings: {observation?.metadata?.total_readings}
            </div>
          )}
      </div>
    );
  } else {
    return null;
  }
}
