import dayjs from "./dayjs";
import { Line } from "react-chartjs-2";
import PropTypes from "prop-types";

function SensorChart({ activeSensor }) {
  let activeSensorReadings;

  if (activeSensor?.activeObservation?.metadata?.readings?.length) {
    activeSensorReadings =
      activeSensor.activeObservation.metadata.readings.find((reading) => {
        return activeSensor.sensor_index === reading.sensor_index;
      });
  }

  const chartMetadata = activeSensor?.activeObservation?.dataset?.chart;

  console.log(chartMetadata);

  if (!activeSensorReadings) {
    return null;
  }
  const keys = activeSensorReadings?.chart?.keys;
  const values = activeSensorReadings?.chart?.values;

  const label_date = dayjs(
    activeSensor?.activeObservation?.datetime_str
  ).format("MMM D, YYYY");
  const label = activeSensor.sensor_id
    ? `${activeSensor?.sensor_id} / ${label_date}`
    : `Sensor ${activeSensor?.sensor_index} / ${activeSensor?.name} / ${label_date}`;

  const data = {
    labels: keys,
    datasets: [
      {
        label,
        data: values,
        fill: false,
        backgroundColor: "#F5F7FA",
        borderColor: "#616E7C",
      },
    ],
  };

  // const thresholds = {
  //   greenZone: {
  //     type: "box",
  //     scaleID: "y",
  //     drawTime: "beforeDatasetsDraw",
  //     yMin: 0,
  //     yMax: activeSensor.chartMetadata.vis?.domain[0],
  //     backgroundColor: "rgba(63, 191, 127, 0.5)",
  //     borderWidth: 0,
  //   },
  //   yellowZone: {
  //     type: "box",
  //     scaleID: "y",
  //     drawTime: "beforeDatasetsDraw",
  //     yMin: activeSensor.chartMetadata.vis?.domain[0],
  //     yMax: activeSensor.chartMetadata.vis?.domain[1],
  //     backgroundColor: "rgba(191, 191, 63, 0.5)",
  //     borderWidth: 0,
  //   },
  //   redZone: {
  //     type: "box",
  //     scaleID: "y",
  //     drawTime: "beforeDatasetsDraw",
  //     yMin: activeSensor.chartMetadata.vis?.domain[1],
  //     yMax: Math.max(
  //       activeSensor.chartMetadata.vis?.domain[1] + 10, // show a minimum of 10 units above the yellow zone
  //       ...values
  //     ),
  //     backgroundColor: "rgba(191, 63, 63, 0.5)",
  //     borderWidth: 0,
  //   },
  //   yellowLine: {
  //     type: "line",
  //     mode: "horizontal",
  //     scaleID: "y",
  //     drawTime: "beforeDatasetsDraw",
  //     value: activeSensor.chartMetadata.vis?.domain[0],
  //     borderColor: "#a7a7a7",
  //     borderWidth: 2,
  //     borderDash: [2, 2],
  //   },
  //   redLine: {
  //     type: "line",
  //     mode: "horizontal",
  //     scaleID: "y",
  //     drawTime: "beforeDatasetsDraw",
  //     value: activeSensor.chartMetadata.vis?.domain[1],
  //     borderColor: "#a7a7a7",
  //     borderWidth: 2,
  //     borderDash: [2, 2],
  //   },
  // };

  const options = {
    // plugins: {
    //   annotation: {
    //     annotations: thresholds,
    //   },
    // },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        display: true,
        title: {
          display: true,
          text: `${chartMetadata?.metric} ${chartMetadata?.units}`,
        },
      },
    },
  };
  return (
    <div className="h-96 w-full px-4 pb-4 pt-5">
      <Line data={data} options={options} />
    </div>
  );
}

SensorChart.propTypes = {
  activeSensor: PropTypes.any,
};

export default SensorChart;
