// FIRESTORE TRANSFORMER
import { base64DecToArr } from "./utilities";
import pako from "pako";

export const contextLayerConverter = {
  fromFirestore: (snapshot) => {
    try {
      const record = snapshot.data();
      const b64decodedArray = base64DecToArr(record.data);
      record.data = JSON.parse(
        pako.inflate(b64decodedArray, {
          to: "string",
        })
      );
      record.timestamp = record.timestamp.toDate().toISOString();
      return record;
    } catch (err) {
      const record = snapshot.data();
      console.log(err);
      record.data = null;
      return record;
    }
  },
};
