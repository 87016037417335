import { useSelector, useDispatch } from "react-redux";
import { Switch } from "antd";
import {
  setActiveContextLayers,
  selectActiveContextLayerIds,
} from "./store/applicationSlice";
import { useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";

export default function ContextLayersPopup({ contextLayers }) {
  const dispatch = useDispatch();
  const activeContextLayerIds = useSelector(selectActiveContextLayerIds);
  console.log({ activeContextLayerIds });
  const [, setSearchParameters] = useSearchParams();

  return (
    <div className="absolute right-3 top-[270px] z-60 max-h-[350px] overflow-y-scroll rounded-md bg-bsr-gray-050">
      <div className="flex justify-center rounded-t-md bg-bsr-gray-300 text-white">
        Context Layers
      </div>
      <div className="py-1">
        {contextLayers.map((layer) => {
          return (
            <div
              key={layer.id}
              className="my-1 flex min-w-[200px] bg-white p-1 shadow-lg"
            >
              <div className="mr-2">
                <Switch
                  checked={activeContextLayerIds.find((id) => id === layer.id)}
                  onChange={() => {
                    if (activeContextLayerIds.find((id) => id === layer.id)) {
                      const filtered = activeContextLayerIds.filter(
                        (id) => id !== layer.id
                      );
                      if (filtered.length === 0) {
                        setSearchParameters((sp) => {
                          sp.delete("contexts");
                          return sp;
                        });
                        dispatch(setActiveContextLayers(filtered));
                      } else {
                        setSearchParameters((sp) => {
                          sp.set("contexts", JSURL.stringify(filtered));
                          return sp;
                        });
                        dispatch(setActiveContextLayers(filtered));
                      }
                    } else {
                      const updated = [...activeContextLayerIds, layer.id];
                      setSearchParameters((sp) => {
                        sp.set("contexts", JSURL.stringify(updated));
                        return sp;
                      });
                      dispatch(setActiveContextLayers(updated));
                    }
                  }}
                ></Switch>
              </div>
              <div className="flex min-h-[40px] flex-col">
                <div className="flex flex-1 justify-start font-light">
                  {layer.category}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
