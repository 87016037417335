export default function FallbackValue({ formattedData, observation }) {
  return (
    <>
      {formattedData.value ? formattedData.value : "N/A"}
      <span className={`ml-1 text-bsr-gray-500`}>
        {observation?.dataset?.metric?.units
          ? observation?.dataset?.metric?.units
          : ""}
      </span>
      <span className="text-sm text-bsr-gray-500">
        {formattedData.uncertainty_display
          ? " ±" + formattedData.uncertainty_display + "%"
          : ""}
      </span>
    </>
  );
}
