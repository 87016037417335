/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Modal({ open, onClose, width, overlayZIndex, headerTitle, children }) {
  let closeButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className={classNames(
          overlayZIndex ? `${overlayZIndex}` : "z-10",
          "fixed inset-0 overflow-y-auto"
        )}
        open={open}
        onClose={onClose}
        initialFocus={closeButtonRef}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                width ? width : "sm:w-full",
                "inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-6xl sm:align-middle"
              )}
            >
              <div className="h-14 w-full bg-gray-500 text-bsr-gray-100">
                <div className="flex h-full items-center pl-4 text-lg">
                  {headerTitle}
                </div>
                <div className="absolute right-0  top-0 block pr-4 pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-bsr-gray-400 hover:text-bsr-gray-500 focus:outline-none"
                    onClick={() => {
                      onClose(false);
                    }}
                    ref={closeButtonRef}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon
                      className="h-6 w-6 bg-gray-500 text-bsr-gray-100"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  width: PropTypes.string,
  overlayZIndex: PropTypes.string,
  headerTitle: PropTypes.string,
  children: PropTypes.any,
};

export default Modal;
