import React, { useState, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  createNotification,
  selectSiteById,
  changeSiteName,
  setSiteId,
  deleteSite,
} from "./store/applicationSlice";
import {
  ExclamationCircleIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/solid";
import Button from "./shared/Button";
import { selectAccount } from "./store/sessionSlice";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Loading from "./shared/Loading";

export default function SiteSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const site = useSelector((state) => selectSiteById(state, siteId));
  const [siteName, setSiteName] = useState(site?.name);
  const [alternateSiteId, setAlternateSiteId] = useState(null);
  const accountId = useSelector(selectAccount);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  if (!site) {
    return <Loading />;
  }

  console.log(`settings for site: ${JSON.stringify(site)}`);

  return (
    <div className="full-height-minus-header-and-sub-header">
      <div className="items-center">
        <div className="flex grow items-center space-x-12 border-bsr-gray-100 bg-white p-4 px-4 py-2 text-xl font-semibold text-bsr-gray-700 sm:px-6 lg:px-9">
          <Button
            size="s"
            type="white"
            className="font-semibold; border-none text-9xl"
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon className="-ml-0.5 mr-1 h-8 w-8" />
          </Button>
          Configure site
        </div>
        <div className="items-center px-4 py-1 sm:px-6 lg:px-9">
          <label
            htmlFor="siteName"
            className="block pt-2 text-sm font-medium text-gray-700"
          >
            Site Name
          </label>
          <div className="relative mt-1 pb-2">
            <input
              type="text"
              name="siteName"
              id="siteName"
              className={
                siteName
                  ? "w-18 rounded-md border-blue-300 pr-10 text-blue-900 placeholder-blue-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  : "w-18 rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
              }
              defaultValue={site.name}
              aria-describedby="siteName-error"
              onChange={(e) => {
                setSiteName(e.target.value);
              }}
            />
            {!siteName ? (
              <div className="pointer-events-none absolute -mt-7 pl-52">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            ) : null}
            <Button
              size="xs"
              type="white"
              className="ml-4 font-semibold"
              disabled={!siteName || siteName === site?.name}
              onClick={(e) => {
                // TODO validate the site name to make sure it is not blank. Display error state on form if it is.
                //  then if the site name is not blank, dispatch the changeSiteName action.
                dispatch(
                  changeSiteName({
                    accountId: accountId,
                    siteName: siteName,
                    siteId: siteId,
                  })
                )
                  .then(() => {
                    dispatch(
                      createNotification({
                        title: "Site name changed.",
                        description: "",
                      })
                    );
                  })
                  .then(() => {
                    navigate(`/sites/${siteId}/map`);
                  });
              }}
            >
              Rename
            </Button>
            {!siteName ? (
              <p className="mt-2 text-sm text-red-600" id="siteName-error">
                Site Name can not be blank.
              </p>
            ) : null}
          </div>
        </div>
        <div className="items-center px-4 py-1 sm:px-6 lg:px-9 ">
          <label
            htmlFor="siteName"
            className="block pt-2 text-sm font-medium text-gray-700"
          >
            Site ID
          </label>
          <div className="relative mt-1 pb-2">
            {site?.alternateSiteId ? (
              <div>{site?.alternateSiteId}</div>
            ) : (
              <>
                <input
                  type="text"
                  name="siteName"
                  id="siteName"
                  className={
                    siteName
                      ? "w-18 rounded-md border-blue-300 pr-10 text-blue-900 placeholder-blue-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                      : "w-18 rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  }
                  placeholder="Provide an optional ID for the site."
                  aria-describedby="siteName-error"
                  onChange={(e) => {
                    setAlternateSiteId(e.target.value);
                  }}
                />
                <Button
                  size="xs"
                  type="white"
                  className="ml-4 font-semibold"
                  disabled={!alternateSiteId}
                  onClick={(e) => {
                    // TODO validate the site name to make sure it is not blank. Display error state on form if it is.
                    //  then if the site name is not blank, dispatch the changeSiteName action.
                    if (alternateSiteId.length === 0) return;
                    dispatch(
                      setSiteId({
                        siteId: siteId,
                        accountId: accountId,
                        alternateSiteId: alternateSiteId,
                      })
                    )
                      .then(() => {
                        dispatch(
                          createNotification({
                            title: "Site ID set.",
                            description: "",
                          })
                        );
                      })
                      .then(() => {
                        navigate(`/sites/${siteId}`);
                      });
                  }}
                >
                  Save
                </Button>
              </>
            )}
            {!siteName ? (
              <p className="mt-2 text-sm text-red-600" id="siteName-error">
                Site ID can not be blank.
              </p>
            ) : null}
          </div>
        </div>
        {/* <div className="items-center px-4 py-1 sm:px-6 lg:px-9 ">
          <label
            htmlFor="siteTags"
            className="block pt-2 text-sm font-medium text-gray-700"
          >
            Add Site Tag
          </label>
          <div className="relative mt-1 pb-2">
            <input
              type="text"
              name="siteName"
              id="siteName"
              className="w-18 rounded-md border-blue-300 pr-10 text-blue-900 placeholder-blue-300 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
              defaultValue={""}
              onChange={(e) => {
                if (e.target.value.includes(",")) {
                  setSiteTag(e.target.value.split(","));
                }
                setSiteTag([e.target.value]);
              }}
            />
            <Button
              size="xs"
              type="white"
              className="ml-4 font-semibold"
              onClick={(e) => {
                // TODO validate the site name to make sure it is not blank. Display error state on form if it is.
                //  then if the site name is not blank, dispatch the changeSiteName action.
                dispatch(
                  addSiteTag({
                    accountId: accountId,
                    siteId: siteId,
                    tag: siteTag,
                  })
                )
                  .then(() => {
                    dispatch(
                      createNotification({
                        title: "Tag added successfully",
                        description: "",
                      })
                    );
                  })
                  .then(() => {
                    navigate(`/sites/${siteId}/map`);
                  });
              }}
            >
              Add tag
            </Button>
          </div>
        </div>

        {site.tags.length > 0 && (
          <div className="items-center px-4 py-1 sm:px-6 lg:px-9">
            <label className="mb-2 block pt-2 text-sm font-medium text-gray-700">
              Site Tags
            </label>
            <div className="flex space-x-2">
              {site.tags &&
                site.tags.map((tag) => {
                  return (
                    <span className="inline-flex items-center rounded-md bg-bsr-blue-100 px-2.5 py-0.5 text-sm font-medium text-bsr-blue-800">
                      {tag}
                    </span>
                  );
                })}
            </div>
          </div>
        )} */}

        <div
          className="mt-8 flex grow items-center space-x-12 border-bsr-gray-100 p-4 
                    px-4 py-2 text-xl font-semibold text-bsr-gray-700 sm:px-6 lg:px-9"
        >
          Danger Zone
        </div>
        <div
          className="ml-9 w-3/5 items-center items-center rounded-md border-4 border-red-600 px-4 
                    py-1 sm:px-6 lg:px-4 "
        >
          <label className="block pt-2 text-sm font-semibold text-gray-700">
            Delete this site
          </label>
          <div className="mt-1 pb-2 text-sm font-medium">
            <span className="mr-32">
              Once you delete a site, there is no going back. Please be certain.
            </span>

            <Button
              type="custom"
              size="xs"
              className="-mt-4 border-2 border-gray-400 
                            bg-gray-200 text-sm font-semibold text-red-600"
              onClick={(e) => {
                setOpen(true);
              }}
            >
              Delete this site
            </Button>
          </div>
        </div>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Delete this site
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This action cannot be undone. This will permanently
                        delete the <b> {siteName} </b>site.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      navigate(`/sites`);

                      dispatch(
                        deleteSite({
                          accountId: accountId,
                          isDeleted: new Date().toUTCString(),
                          siteId: siteId,
                        })
                      )
                        .then(() => {
                          dispatch(
                            createNotification({
                              title: "Site deleted successfully.",
                              description: "",
                            })
                          );
                        })
                        .catch(() => {
                          createNotification({
                            title: "Site delete failed.",
                            description: "",
                          });
                        });
                    }}
                  >
                    Delete this site
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
