import dayjs from "../dayjs";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { fetchAccountResources } from "./applicationSlice";

import {
  getObservations,
  aggregateObservationsByMetric,
} from "../services/Api";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchObservations = createAsyncThunk(
  "portfolio/fetchObservations",
  async (
    { activeTimePeriod, activeDatasetIds, activeGrouping },
    { getState }
  ) => {
    let {
      app: { sites, datasets, configuration },
    } = getState();

    if (activeGrouping && configuration?.groupings?.[activeGrouping]) {
      sites = sites.filter((s) =>
        configuration.groupings[activeGrouping].sites.includes(s.id)
      );
    }

    console.log({ activeGrouping, sites, datasets });

    const activeDatasets = datasets.filter((d) =>
      activeDatasetIds.includes(d.id)
    );

    const coverageDatasets = activeDatasets.reduce((acc, cur) => {
      if (cur?.data_coverage?.dataset_id) {
        acc.push(cur.data_coverage.dataset_id);
      }
      return acc;
    }, []);
    return getObservations({
      siteIds: sites.map((site) => site.id),
      datasetIds: [...activeDatasets.map((d) => d.id), ...coverageDatasets],
      cadence: activeTimePeriod.cadence,
      startDate: dayjs(activeTimePeriod.startDate).toDate(),
      endDate: dayjs(activeTimePeriod.endDate).toDate(),
    });
  }
);

export const portfolio = createSlice({
  name: "portfolio",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAccountResources.fulfilled]: (state, action) => {
      state.activeSummary = null;
      // when active summaries are fetched default to first metric
      state.activeMetrics = [];
    },
    [fetchObservations.fulfilled]: (state, action) => {
      // const aggregations = action.meta.arg.activeSummary.aggregations;
      state.status = "succeeded";
      state.data = action.payload.sort((a, b) => {
        if (a.datetime_start < b.datetime_start) {
          return -1;
        }
        if (a.datetime_start > b.datetime_start) {
          return 1;
        }
        return 0;
      });
    },
    [fetchObservations.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [fetchObservations.failed]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const selectObservationsStatus = (state) => state.portfolio.status;

export const selectAggregatedObservationsByMetric = createSelector(
  (state) => state.app.activeDatasets,
  (state) => state.app.datasets,
  (state) => state.portfolio.data,
  (activeDatasets, datasets, data) => {
    console.log("running expensive agg selector...");
    if (!data) {
      return null;
    }

    const aggregated = {};

    activeDatasets.forEach((dataset) => {
      if (!dataset) {
        return;
      }

      // some datasets store coverage information in separate datasets.
      //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
      const dataCoverageDataset = dataset?.data_coverage
        ? datasets.find((d) => d.id === dataset?.data_coverage?.dataset_id)
        : null;

      const coverageObservations = dataCoverageDataset
        ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
        : null;

      const observations = data.filter((d) => d.dataset_id === dataset?.id);

      const a = aggregateObservationsByMetric({
        observations,
        coverageObservations,
        metric: dataset.value_field,
        aggregation: dataset.agg,
        name: dataset.name,
      });
      aggregated[dataset.name] = a;
    });

    return aggregated;
  }
);

export const selectAggregatedObservationsByMetricAndDatetimeString =
  createSelector(
    (state) => state.app.activeDatasets,
    (state) => state.app.datasets,
    (state) => state.portfolio.data,
    (activeDatasets, datasets, data) => {
      if (!data) {
        return null;
      }

      const aggregated = {};

      const uniqueDatetimeStrings = new Set();
      data.forEach((observation) => {
        uniqueDatetimeStrings.add(observation.datetime_str);
      });
      activeDatasets.forEach((dataset) => {
        // some datasets store coverage information in separate datasets.
        //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
        const dataCoverageDataset = dataset?.data_coverage
          ? datasets.find((d) => d.id === dataset?.data_coverage?.dataset_id)
          : null;
        const coverageObservations = dataCoverageDataset
          ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
          : null;
        aggregated[dataset.name] = {};
        uniqueDatetimeStrings.forEach((datetime_str) => {
          const a = aggregateObservationsByMetric({
            observations: data.filter(
              (d) =>
                d.dataset_id === dataset?.id && d.datetime_str === datetime_str
            ),
            coverageObservations: coverageObservations?.filter(
              (d) => d.datetime_str === datetime_str
            ),
            metric: dataset.value_field,
            aggregation: dataset.agg,
            name: dataset.name,
          });
          aggregated[dataset.name][datetime_str] = a;
        });
      });

      return aggregated;
    }
  );

export const selectAggregatedObservationsBySite = createSelector(
  (state) => state.app.sites,
  (state) => state.app.activeDatasets,
  (state) => state.app.datasets,
  (state) => state.portfolio.data,
  (sites, activeDatasets, datasets, data) => {
    if (!data) {
      return null;
    }
    const aggregated = {};

    sites.forEach((s) => {
      aggregated[s.id] = {};

      activeDatasets.forEach((dataset) => {
        // some datasets store coverage information in separate datasets.
        // the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
        const dataCoverageDataset = dataset?.data_coverage
          ? datasets.find((d) => d.id === dataset?.data_coverage?.dataset_id)
          : null;

        const coverageObservations = dataCoverageDataset
          ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
          : null;

        const a = aggregateObservationsByMetric({
          observations: data.filter(
            (d) => d.dataset_id === dataset?.id && d.site_id === s?.id
          ),
          coverageObservations: coverageObservations?.filter(
            (d) => d.site_id === s.id
          ),
          metric: dataset?.value_field,
          aggregation: dataset?.agg,
          name: dataset?.name,
        });
        aggregated[s.id][dataset.name] = a;
      });
    });

    return aggregated;
  }
);

export const selectObservationsBySite = createSelector(
  (state) => state.app.sites,
  (state) => state.app.activeDatasets,
  (state) => state.app.datasets,
  (state) => state.portfolio.data,
  (sites, activeDatasets, datasets, data) => {
    if (!data) {
      return null;
    }
    const aggregated = {};

    sites.forEach((s) => {
      aggregated[s.id] = {};

      activeDatasets.forEach((dataset) => {
        aggregated[s.id][dataset.name] = data.filter(
          (d) => d.dataset_id === dataset?.id && d.site_id === s?.id
        );
      });
    });

    return aggregated;
  }
);

export const selectObservationsChronologically = (state) => {
  if (!state.portfolio.data) return [];
  const sorted = [...state.portfolio.data].sort((a, b) => {
    if (new Date(a.datetime_start) > new Date(b.datetime_start)) {
      return -1;
    } else if (new Date(a.datetime_start) < new Date(b.datetime_start)) {
      return 1;
    } else {
      return 0;
    }
  });
  const enriched = sorted.map((o) => {
    const dataset = state.app.datasets.find((d) => d.id === o.dataset_id);

    return {
      ...o,
      site_name: state.app.sites.find((s) => s.id === o.site_id).name,
      dataset_name: dataset.name,
      units: dataset.metric?.units,
    };
  });
  return enriched;
};

export const selectObservations = (state) => {
  if (!state.portfolio.data) return {};
  return state.portfolio.data.reduce((acc, cur) => {
    if (!acc[cur.datetime_str]) {
      acc[cur.datetime_str] = [];
    }
    const cp = { ...cur };
    cp.dataset_name = state.app.datasets.find(
      (d) => d.id === cp.dataset_id
    ).name;
    cp.site_name = state.app.sites.find((s) => s.id === cp.site_id).name;
    acc[cur.datetime_str].push(cp);
    return acc;
  }, {});
};

export default portfolio.reducer;
