const colors = ["#20639B", "#3CAEA3", "#F6D55C", "#ED553B"];

export default function RosettaLegend() {
  return (
    <div>
      <div className="flex">
        {colors.map((color) => {
          return (
            <div
              key={color}
              className="mx-0.5 h-5 w-12"
              style={{ backgroundColor: color }}
            ></div>
          );
        })}
      </div>
      <div className="flex">
        <div className="mx-0.5 h-5 w-12 text-center text-xs">low</div>
        <div className="mx-0.5 h-5 w-12"></div>
        <div className="mx-0.5 h-5 w-12"></div>
        <div className="mx-0.5 h-5 w-12 text-center text-xs">high</div>
      </div>
    </div>
  );
}
