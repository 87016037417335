import dayjs from "../../dayjs";
import { formatData } from "../../utilities";

export default function constructPathSensorHover(properties) {
  let str;
  if (properties.campaign) {
    str = `
    <div class="flex flex-col text-lg">
      <div class="font-bold">Flight Metadata</div>
      <div>Flow Rate: ${properties.value.toFixed(2)} ${properties.units}</div>
      <div>Flight Measurements: ${properties.acquisitions.length}</div>
    </div>
    `;
  } else {
    str = `
    <div class="flex flex-col text-lg">
      <div>
        ${
          properties.time
            ? dayjs(properties.time).format("MMMM Do hh:mm A")
            : properties.timestamp
            ? dayjs(properties.timestamp).format("MMMM Do hh:mm A")
            : ""
        }
      </div>
      <div>
        ${
          formatData(
            properties.scaledMetric
              ? properties[properties.scaledMetric]
              : properties["value"]
          ).value
        }
        ${String(properties?.units)}
        ${properties?.metric ? properties?.metric : ""}
      </div>
      <div>
        Concentration Enhancement: ${
          properties?.enhancement_ppm
            ? `${properties?.enhancement_ppm.toFixed(2)} ppm`
            : ""
        }
      </div>
    </div>
  `;
  }

  return str;
}
