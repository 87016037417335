import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "typeface-roboto";

import App from "./App";
import { store } from "./store/store";
import * as serviceWorker from "./serviceWorker";

import {
  Chart as ChartJS,
  LineElement,
  ArcElement,
  BarElement,
  ScatterController,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import annotationPlugin from "chartjs-plugin-annotation";
import {
  ScatterWithErrorBarsChart,
  ScatterWithErrorBarsController,
  PointWithErrorBar,
} from "chartjs-chart-error-bars";

ChartJS.register(
  LineElement,
  ArcElement,
  BarElement,
  ScatterController,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
  annotationPlugin,
  ScatterWithErrorBarsChart,
  ScatterWithErrorBarsController,
  PointWithErrorBar,
  Filler,
  TimeScale
);
// Chart.register(labelsPlugin);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
