import { stringify, parse } from "wkt";

class Site {
  constructor({
    id,
    name,
    geometry,
    created_at,
    created_by,
    isDeleted,
    account,
    status,
    total_points,
    total_sq_km,
    reported_mtCO2e_2019,
    alternateSiteId,
    tags,
    timezone,
  }) {
    this.id = id;
    this.name = name;
    this.geometry = geometry;
    this.created_at = created_at;
    this.created_by = created_by;
    this.isDeleted = isDeleted;
    this.account = account;
    this.total_points = total_points;
    this.total_sq_km = total_sq_km;
    this.alternateSiteId = alternateSiteId;
    this.reported_mtCO2e_2019 = reported_mtCO2e_2019;
    this.status = status;
    this.tags = tags || [];
    this.timezone = timezone;
  }
}

export const siteConverter = {
  toFirestore: (site) => {
    return {
      id: site.id,
      name: site.name,
      geometry: stringify(site.geometry),
      created_at: site.created_at,
      created_by: site.created_by,
      account: site.account,
      total_points: site.total_points,
      total_sq_km: site.total_sq_km,
      isDeleted: site.isDeleted,
      alternateSiteId: site?.alternateSiteId ? site?.alternateSiteId : null,
      // toFirestore requires a default value for site.status, I am not sure why
      // TODO look into this...
      status: site?.status ? site.status : "",
      tags: site.tags,
      timezone: site.timezone,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    data.geometry = parse(data.geometry);
    return new Site(data);
  },
};

export default Site;
