import { classNames } from "../utilities";
// import Tooltip from "../shared/Tooltip";
// import { CheckIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { CheckIcon } from "@heroicons/react/20/solid";

export default function DatasetCard({
  dataset,
  isActive,
  setStagedDatasets,
  showActiveCheckmark = true,
}) {
  return (
    <div
      className={classNames(
        showActiveCheckmark ? "cursor-pointer p-5" : "py-4",
        "flex items-center justify-between gap-x-4 hover:bg-gray-100"
      )}
      onClick={() => {
        if (!setStagedDatasets) return;

        if (isActive) {
          // console.warn("dataset is already active!");
          setStagedDatasets((staged) =>
            staged.filter((d) => d.id !== dataset.id)
          );
        } else {
          setStagedDatasets((datasets) => [...datasets, dataset]);
        }
      }}
    >
      {/* <img
        src="/dataset-thumbnail.png"
        alt=""
        className="h-10 w-10 flex-none bg-gray-50"
      /> */}
      <div className="flex-1">
        <p className="text-base font-normal leading-5 text-bsr-gray-900">
          {dataset.name}
        </p>
        <div className="flex w-full items-center justify-start">
          {/* <p className="mt-1 truncate text-xs text-gray-500">
            {dataset?.publisher ? dataset?.publisher : "GHGSat"} - satellite -
            global - 25m - monthly{" "}
          </p> */}
          {/* <Tooltip
            position="bottom"
            content={dataset?.description ? dataset.description : ""}
          >
            <InformationCircleIcon className="ml-2 inline h-4 w-4 text-gray-400" />
          </Tooltip> */}
        </div>
      </div>
      {showActiveCheckmark && (
        <div
          className={classNames(
            !isActive ? "invisible" : "",
            "flex items-center rounded-full bg-bsr-pink-500 px-2.5 py-0.5 text-xs font-medium text-white"
          )}
        >
          <CheckIcon className="h-5 w-5" />
          Active
        </div>
      )}
    </div>
  );
}
