import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import TextField from "./shared/TextField";
import Button from "./shared/Button";
import AppName from "./shared/AppName";
import LandingPageCopy from "./LandingPageCopy";

import splashImage from "./assets/airlogic-splash.png";

import { sanitizeReferrer } from "./utilities";

import {
  signInWithEmailAndPassword,
  signInWithGoogle,
  selectSession,
  selectSessionStatus,
} from "./store/sessionSlice";

export default function Login() {
  const dispatch = useDispatch();

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [error, setError] = useState();

  const session = useSelector(selectSession);
  const sessionStatus = useSelector(selectSessionStatus);

  const query = new URLSearchParams(useLocation().search);
  let referrer = query.get("referrer");
  query.forEach((value, key) => {
    if (key !== "referrer") {
      referrer += "&" + key + "=" + value;
    }
  });

  const login = async function (e) {
    e.preventDefault();
    setEmailError(null);
    setPasswordError(null);
    setError(null);

    // Validate fields
    if (!email) {
      setEmailError("Email is required");
      return;
    }
    if (!password) {
      setPasswordError("Password is required");
      return;
    }

    try {
      const resultAction = await dispatch(
        signInWithEmailAndPassword({ email, password })
      );
      unwrapResult(resultAction);
    } catch (err) {
      if (err.code === "auth/invalid-email") {
        setEmailError("Email is not valid");
        return;
      }

      if (err.code === "auth/wrong-password") {
        setPasswordError("Incorrect password.");
        return;
      }
      setError(err.message);
      console.error(err);
    }
  };

  // user is logged in, redirect to their desired page or root route by default
  if (sessionStatus === "succeeded" && session) {
    let safeReferrer = sanitizeReferrer(referrer);
    if (referrer) return <Navigate to={safeReferrer} />;

    return <Navigate to="/" />;
  }

  return (
    <div
      style={{ backgroundImage: `url(${splashImage})` }}
      className="min-h-screen"
    >
      <div className="md:flex">
        <div className="hidden md:flex md:grow md:flex-col md:justify-center md:px-12">
          <div className="mx-auto w-full bg-bsr-blue-900 bg-opacity-80 p-12 text-bsr-blue-050 shadow">
            <div className="mb-4 flex items-center">
              <AppName size="lg" />
            </div>
            <LandingPageCopy />
          </div>
        </div>
        <div className="md:w-full md:max-w-md md:flex-none">
          <div className="flex h-screen flex-col justify-center bg-bsr-gray-050 px-4 py-8 sm:px-10">
            <h2 className="mb-8 text-center text-3xl font-extrabold text-bsr-gray-700">
              Sign in to your account
            </h2>
            <form className="space-y-6" action="#" method="POST">
              <TextField
                name="email"
                label="Email"
                error={emailError}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(undefined);
                }}
              />
              <TextField
                name="password"
                label="Password"
                error={passwordError}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(undefined);
                }}
              />

              <div className="flex items-center justify-center">
                <div className="text-sm">
                  <Link
                    to="/reset"
                    className="font-medium text-bsr-blue-700 hover:text-bsr-blue-600"
                  >
                    Forgot your password?
                  </Link>
                </div>
              </div>

              {error && (
                <p className="mt-2 text-sm text-red-600" id={"error"}>
                  {error}
                </p>
              )}
              <div>
                <Button
                  type="primary"
                  fullWidth
                  disabled={sessionStatus === "loading"}
                  onClick={(e) => login(e)}
                >
                  Sign in
                </Button>
              </div>
            </form>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-bsr-gray-050 px-2 text-gray-500">Or</span>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <button
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                    onClick={signInWithGoogle}
                  >
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        d="M17.64 9.2c0-.637-.057-1.251-.164-1.84H9v3.481h4.844c-.209 1.125-.843 2.078-1.796 2.717v2.258h2.908c1.702-1.567 2.684-3.874 2.684-6.615z"
                        fillRule="evenodd"
                        fillOpacity="1"
                        fill="#4285f4"
                        stroke="none"
                      ></path>
                      <path
                        d="M9.003 18c2.43 0 4.467-.806 5.956-2.18L12.05 13.56c-.806.54-1.836.86-3.047.86-2.344 0-4.328-1.584-5.036-3.711H.96v2.332C2.44 15.983 5.485 18 9.003 18z"
                        fillRule="evenodd"
                        fillOpacity="1"
                        fill="#34a853"
                        stroke="none"
                      ></path>
                      <path
                        d="M3.964 10.712c-.18-.54-.282-1.117-.282-1.71 0-.593.102-1.17.282-1.71V4.96H.957C.347 6.175 0 7.55 0 9.002c0 1.452.348 2.827.957 4.042l3.007-2.332z"
                        fillRule="evenodd"
                        fillOpacity="1"
                        fill="#fbbc05"
                        stroke="none"
                      ></path>
                      <path
                        d="M9.003 3.58c1.321 0 2.508.454 3.44 1.345l2.582-2.58C13.464.891 11.428 0 9.002 0 5.485 0 2.44 2.017.96 4.958L3.967 7.29c.708-2.127 2.692-3.71 5.036-3.71z"
                        fillRule="evenodd"
                        fillOpacity="1"
                        fill="#ea4335"
                        stroke="none"
                      ></path>
                    </svg>{" "}
                    <span className="ml-2">Continue with Google</span>
                  </button>
                </div>
              </div>

              <div className="mt-6">
                <div>
                  <Link
                    to={
                      "/login/sso" + (referrer ? "?referrer=" + referrer : "")
                    }
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
                  >
                    Continue with SSO
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center justify-center">
                <div className="text-sm">
                  Need an account?{" "}
                  <Link
                    to="/signup"
                    className="font-medium text-bsr-blue-700 hover:text-bsr-blue-600"
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex flex-col items-center justify-center">
                <AppName
                  withTm
                  size="sm"
                  iconColor="#1F2933"
                  textColor="text-bsr-gray-900"
                />
                <div className="flex">
                  Version 4.3 by
                  <a
                    href="https://blueskyresources.com"
                    className="ml-1 font-medium text-bsr-blue-700 hover:text-bsr-blue-600"
                  >
                    BlueSky Resources
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
