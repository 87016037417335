import { Link } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import dayjs from "dayjs";

function formatValue(value) {
  try {
    if (value === null) {
      return "Non-detect";
    } else if (Number.isInteger(value)) {
      return value;
    } else {
      return value.toFixed(0);
    }
  } catch (err) {
    console.log(err);
    console.log("failed to parse measurement value");
    return "";
  }
}

export default function MeasurementHeader({ measurement, site, datasets }) {
  //get the site and timezone
  const timestamp = measurement?.measured_time;
  const percentError = (
    (measurement?.uncertainty_value / measurement?.value) *
    100
  ).toFixed(1);
  //convert to local time
  const tz = site?.timezone;
  const dayjsLocal = dayjs(timestamp);
  const dayjsAmerica = dayjsLocal.tz(tz);
  const timeZoneAbbr = dayjsAmerica.format("z");
  const localTime = dayjs(dayjsAmerica).format("MMMM D, YYYY h:mma");
  return (
    <nav className="mx-auto flex-col justify-start truncate whitespace-nowrap">
      <div className="flex items-center text-sm text-bsr-gray-300 ">
        <Link className="hover:text-white" to="/map">
          Sites
        </Link>
        <ChevronRightIcon className="h-5 w-8" />
        <Link className="hover:text-white" to={`/map/${site.id}`}>
          {site.name} ({site.alternateSiteId})
        </Link>
      </div>
      <div className="items-left flex pt-1">
        <p className="text-lg font-bold text-bsr-gray-300">
          Emission Detection
          <span className="ml-2 text-white">
            {localTime
              ? `${localTime} (${timeZoneAbbr})`
              : `${measurement?.measured_time} (UTC)`}
          </span>
          <span className="text-sm font-normal text-white">
            <span className="mx-1 text-bsr-gray-300">
              by{" "}
              {datasets?.[measurement?.dataset_id]?.dataset_name
                ? datasets[measurement.dataset_id].dataset_name
                : measurement.dataset_id}
            </span>
            {measurement?.uncertainty_value ? (
              <>
                <span className="text-lg font-bold">
                  {formatValue(measurement.value)}
                </span>
                {measurement && measurement.value && (
                  <span className="ml-1">{`kg/hr ±${percentError}%`}</span>
                )}
              </>
            ) : (
              <span className="font-bold">
                {formatValue(measurement.value)}
              </span>
            )}
          </span>
        </p>
      </div>
    </nav>
  );
}
