import dayjs from "./dayjs";
import { getTimeFormat } from "./utilities";

export default function DataCoverageLegend({ timeSeries, cadence }) {
  return (
    <div className="flex">
      <div className="mr-5 flex items-center justify-center font-bold">
        Legend
      </div>
      <div className="flex flex-col justify-start border-l border-bsr-gray-200 py-5 pl-3">
        {dayjs(Object.keys(timeSeries)[0]).format(getTimeFormat(cadence))}
      </div>
      <div className="grid w-[200px] grid-cols-6 gap-1  px-3 py-5">
        {Object.keys(timeSeries).map((timeStr, i) => {
          return (
            <div
              className="flex items-center justify-center"
              key={`legend-circle-${timeStr}`}
            >
              <div className="h-5 w-5 rounded-full border border-bsr-blue-800 bg-bsr-gray-050"></div>
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-end border-r border-bsr-gray-200 py-5 pr-3">
        {dayjs(
          Object.keys(timeSeries)[Object.keys(timeSeries).length - 1]
        ).format(getTimeFormat(cadence))}
      </div>
      <div className="flex items-center space-x-2 px-3">
        <div className="flex items-center justify-center">
          <div className="h-5 w-5 rounded-full border border-bsr-blue-800 bg-bsr-blue-800"></div>
        </div>
        <div>At least one measurement</div>
      </div>
    </div>
  );
}
