import React from "react";
import { createPopper } from "@popperjs/core";
import PropTypes from "prop-types";

const Tooltip = ({ position, children, title, content, width }) => {
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const compRef = React.createRef();
  const tooltipRef = React.createRef();
  const openTooltip = () => {
    createPopper(compRef.current, tooltipRef.current, {
      placement: position,
    });
    setTooltipShow(true);
  };
  const closeTooltip = () => {
    setTooltipShow(false);
  };
  let widthClass;
  if (width === 300) {
    widthClass = "w-[300px] ";
  }
  return (
    <>
      <div onMouseEnter={openTooltip} onMouseLeave={closeTooltip} ref={compRef}>
        {children}
      </div>
      <div
        className={
          (widthClass ? widthClass : "") +
          (tooltipShow ? "" : "hidden ") +
          "z-90 mt-3 block max-w-sm break-words rounded-lg border-0 bg-bsr-gray-600 text-left text-sm font-normal leading-normal no-underline"
        }
        ref={tooltipRef}
      >
        {title && (
          <div
            className={
              "border-blueGray-100 mb-0 rounded-t-lg border-b border-solid bg-bsr-gray-600 p-3 font-semibold uppercase text-white opacity-75"
            }
          >
            {title}
          </div>
        )}

        <div className="z-90 flex break-words p-3 text-white">{content}</div>
      </div>
    </>
  );
};

Tooltip.propTypes = {
  position: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default Tooltip;
