import { useState } from "react";

import { useSelector } from "react-redux";
import { selectSiteById } from "../store/applicationSlice";

import { db } from "./firebase";
import { useParams } from "react-router";
import { saveAs } from "file-saver";
import { CogIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";

const CsvDownloadButton = ({ activeMetrics }) => {
  const account = useSelector((state) => state.session.data.account);
  const { siteId } = useParams();
  const site = useSelector((state) => selectSiteById(state, siteId));

  const [downloading, setDownloading] = useState(false);
  async function downloadCsv() {
    try {
      setDownloading(true);
      const snapshots = await Promise.all(
        activeMetrics.map((m) => {
          return db
            .collection("accounts")
            .doc(account)
            .collection("sites")
            .doc(siteId)
            .collection("datasets")
            .doc(m.id)
            .collection("observations")
            .get();
        })
      );

      let c = "datetime_start,";
      let arrDataset = [];
      let arrSiteData = [];

      activeMetrics.forEach((d, index) => {
        arrDataset.push(d.id);
        c += d.metric.units
          ? `${d.metric.name} ( ${d.metric.units} )`
          : `${d.metric.name}`;
        if (activeMetrics.length > index + 1) c += `,`;
      });
      c += `\n`;

      var uniqueDatasetID = arrDataset.filter((v, i, a) => a.indexOf(v) === i);

      snapshots.forEach((el) => {
        el.docs.forEach((elSub) => {
          var record = elSub.data();
          for (var i = 0; i < uniqueDatasetID.length; i++) {
            if (record.dataset_id === uniqueDatasetID[i]) {
              arrSiteData.push({
                id: record.dataset_id,
                date: record.datetime_str,
                value: record.value,
              });
            }
          }
        });
      });

      var uniqueDates = arrSiteData
        .filter((n, i, x) => x.findIndex((vr) => vr.date === n.date) === i)
        .sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });

      uniqueDates.forEach((ele) => {
        c += `${ele.date},`;

        uniqueDatasetID.forEach((item) => {
          var getData = arrSiteData.filter(
            (el) => el.id === item && el.date === ele.date
          );
          if (getData.length > 0) c += `${getData[0].value},`;
          else c += `,`;
        });
        c += `\n`;
      });

      var cBlob = new Blob([c], { type: "text/csv" });
      saveAs(cBlob, site.name + ".csv");
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.warn("something went wrong while attempting to download .csv:");
      console.error(err);
    }
  }
  return (
    <div
      onClick={downloadCsv}
      className="flex h-full cursor-pointer items-center p-2 text-sm font-medium text-bsr-gray-500 hover:bg-bsr-gray-100"
    >
      {downloading ? (
        <CogIcon className="mr-2 h-5 w-5 animate-spin" />
      ) : (
        <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
      )}
      Export
    </div>
  );
};

export default CsvDownloadButton;
