import React, { useState } from "react";
import { Link } from "react-router-dom";
import TextField from "./shared/TextField";
import Button from "./shared/Button";
import { auth } from "./shared/firebase";
import { ReactComponent as Logo } from "./assets/logo-blue.svg";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [status, setStatus] = useState("idle");
  const [emailError, setEmailError] = useState(null);

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="mx-auto h-12 w-auto" alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-bsr-gray-700">
          Reset Your Password
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          {(() => {
            if (status === "succeeded") {
              return (
                <div className="w-full text-gray-600">
                  <p className="mb-5 text-lg font-bold leading-snug">
                    Success. Check your email for instructions to reset your
                    password
                  </p>
                  <p className="text-sm">
                    Didn't get the email? Check your spam folder or{" "}
                    <Button
                      type="tertiary"
                      onClick={(e) => {
                        e.preventDefault();
                        setEmail("");
                        setError(null);
                        setStatus("idle");
                      }}
                    >
                      resend
                    </Button>
                  </p>
                </div>
              );
            }

            return (
              <form onSubmit={(e) => e.preventDefault()} className="space-y-6">
                <p className="text-sm text-gray-600">
                  Enter the email address associated with your account and we'll
                  send you a link to reset your password.
                </p>
                <TextField
                  name="email"
                  label="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError(undefined);
                    setStatus("idle");
                  }}
                  error={emailError}
                />
                {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    setEmailError(null);
                    setError(null);

                    if (!email) {
                      setEmailError("Email is required");
                      setStatus("failed");
                      return;
                    }

                    setStatus("loading");
                    try {
                      await auth.sendPasswordResetEmail(email);
                      setStatus("succeeded");
                    } catch (error) {
                      setStatus("failed");

                      if (error.code === "auth/invalid-email") {
                        console.log(error);
                        setEmailError("Email is not valid");
                        return;
                      }

                      setError(error.message);
                    }
                  }}
                  type="primary"
                  fullWidth
                  disabled={status === "loading"}
                >
                  Continue
                </Button>
                <div className="flex w-full justify-center text-sm  font-medium text-bsr-blue-700 hover:text-bsr-blue-600">
                  <Link to="/login">Return to sign in</Link>
                </div>
              </form>
            );
          })()}
        </div>
      </div>
    </div>
  );
}
