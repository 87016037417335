const colors = ["#20639B", "#3CAEA3", "#F6D55C", "#ED553B"];

export default function PortfolioLegend() {
  return (
    <div className="absolute bottom-12 left-28 rounded-md bg-white p-4 opacity-90">
      <div className="flex">
        <div
          key={"no-data"}
          className="mx-0.5 mr-5 h-5 w-12"
          style={{ backgroundColor: "#9AA5B1" }}
        ></div>
        {colors.map((color) => {
          return (
            <div
              key={color}
              className="mx-0.5 h-5 w-12"
              style={{ backgroundColor: color }}
            ></div>
          );
        })}
      </div>
      <div className="flex">
        <div className="mx-0.5 mr-5 h-12 w-12 text-center text-xs">
          no readings
        </div>
        <div className="mx-0.5 h-12 w-12 text-center text-xs">
          normal readings detected
        </div>
        <div className="mx-0.5 h-5 w-12"></div>
        <div className="mx-0.5 h-5 w-12"></div>
        <div className="mx-0.5 h-12 w-12 text-center text-xs">
          elevated readings detected
        </div>
      </div>
    </div>
  );
}
