import PathSensorHover from "./PathSensorHover";
import PlumeHover from "./PlumeHover";

const componentByType = {
  PathSensorLegacyLayer: PathSensorHover,
  PathSensorLayer: PathSensorHover,
  PlumeLayer: PlumeHover,
};

const ObservationHover = ({ type, ...rest }) => {
  const SelectedComponent = componentByType[type];
  if (SelectedComponent) {
    console.log("rendering v2 tooltip");
    return <SelectedComponent {...rest} />;
  }
  return null;
};

export default ObservationHover;
