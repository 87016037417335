import dayjs from "./dayjs";
// import {
// useEffect,
// useState,
// } from "react";
import { useSelector } from "react-redux";
// import { getObservations } from "../services/Api";
import {
  selectActiveTimePeriod,
  // selectDatasets,
} from "./store/applicationSlice";
import {
  // datetimeStrToBounds,
  // findDatasetForAggregation,
  formatData,
} from "./utilities";

const CoverageCellHover = ({
  arrowRef,
  tooltipRef,
  getArrowProps,
  getTooltipProps,
  placement,
  datetimeStr,
  // siteId,
  numReadings,
}) => {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  // const [
  //   data,
  //setData
  // ] = useState({});
  // const datasets = useSelector(selectDatasets);
  // const dataset = findDatasetForAggregation(
  //   sensor,
  //   activeTimePeriod.cadence === "yearly" ? "monthly" : "daily",
  //   datasets
  // );
  // const [startDate, endDate] = datetimeStrToBounds(
  //   datetimeStr,
  //   activeTimePeriod.cadence
  // );
  // useEffect(() => {
  //   async function fetchData() {
  //     const observations = await getObservations({
  //       siteIds: [siteId],
  //       datasetIds: [dataset.id],
  //       cadence: activeTimePeriod.cadence === "yearly" ? "monthly" : "daily",
  //       startDate: dayjs(startDate).toDate(),
  //       endDate: dayjs(endDate).toDate(),
  //     });

  //     const data = observations.reduce(
  //       (acc, cur) => {
  //         if (cur.value > acc.max) {
  //           acc.max = cur.value;
  //         }
  //         if (cur.value < acc.min) {
  //           acc.min = cur.value;
  //         }
  //         acc.total++;
  //         return acc;
  //       },
  //       {
  //         min: Infinity,
  //         max: 0,
  //         total: 0,
  //       }
  //     );
  //     setData(data);
  //   }
  //   fetchData();
  // }, [activeTimePeriod.cadence, dataset.id, endDate, startDate, siteId]);
  // console.log(data);
  return (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "tooltip-container",
        /* your props here */
      })}
    >
      <div
        {...getArrowProps({
          ref: arrowRef,
          className: "tooltip-arrow",
          "data-placement": placement,
          /* your props here */
        })}
      />
      <p className="font-bold">
        {dayjs(datetimeStr).format(
          activeTimePeriod.cadence === "yearly"
            ? "YYYY"
            : activeTimePeriod.cadence === "monthly"
            ? "MMM YYYY"
            : "MMM D, YYYY"
        )}
      </p>
      <p className="text-sm">
        Num. measurements: {formatData(numReadings ?? 0).value}
      </p>
      {/* <p className="text-sm">Min: (coming soon)</p>
      <p className="text-sm">Max: (coming soon)</p> */}
    </div>
  );
};

export default CoverageCellHover;
