import React, { useState, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSite,
  fetchAccountResources,
  createNotification,
} from "../store/applicationSlice";

import { nanoid } from "@reduxjs/toolkit";
import Button from "./Button";

import { CogIcon } from "@heroicons/react/24/outline";
import area from "@turf/area";

import MapCreate from "./MapCreate";
import { useNavigate } from "react-router-dom";

export default function CreateSite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const uid = useSelector((state) => state.session.data.uid);
  const account = useSelector((state) => state.session.data.account);
  const [map, setMap] = useState(null);
  const [search, setSearch] = useState("");
  const initialState = {
    status: "idle",
    createdSiteId: null,
    totalSqKm: 0,
    totalPoints: 0,
    features: [],
    geoJson: null,
    siteName: "",
    tags: [],
  };

  const [state, reactDispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "CREATE_SITE_SUCCEEDED":
        return {
          ...state,
          createdSiteId: action.payload,
          status: "succeeded",
        };
      case "ADD_FEATURE":
        let updatedTotalPoints = state.totalPoints;
        if (action.payload.type === "marker") {
          updatedTotalPoints += 1;
        }
        return {
          ...state,
          features: [...state.features, action.payload],
          totalPoints: updatedTotalPoints,
        };
      case "UPDATE_SITE_NAME":
        return { ...state, siteName: action.payload };
      case "ADD_SITE_TAG":
        // TODO super fragile solution. need to make more robust tag entry UI.
        if (action.payload.includes(",")) {
          return { ...state, tags: action.payload.split(",") };
        }
        return { ...state, tags: [action.payload] };
      case "UPDATE_AOI_DRAWING":
        const featureCollection = action.payload;
        // wkt accepts Geometry or GeometryCollection but not FeatureCollection
        // so we make the simple conversion below:
        const geoJson =
          featureCollection.features.length === 1
            ? featureCollection.features[0].geometry
            : {
                type: "GeometryCollection",
                geometries: featureCollection.features.map(
                  (feature) => feature.geometry
                ),
              };
        const totalSqKm = area(featureCollection) / 1000000; // 1e-6 sq. meters to sq. kilometers;
        return { ...state, geoJson, totalSqKm };
      case "CLEAR_AOI_DRAWING":
        return {
          ...state,
          totalSqKm: 0,
          totalPoints: 0,
          features: [],
          geoJson: null,
        };
      case "CREATE_SITE_PENDING":
        return {
          ...state,
          status: "loading",
        };
      case "RESET_STATE":
        return initialState;
      default:
        throw new Error("Invalid action type submitted to CreateSite reducer.");
    }
  }, initialState);

  const clearAoi = () => {
    // clears internally managed drawings.
    state.features.forEach((feature) => {
      feature.overlay.setMap(null);
    });
    // clears managed data layer we leverage for its convenient toGeoJson() method
    if (map && map.data) {
      map.data.forEach((feature) => {
        map.data.remove(feature);
      });
    }
    // reset reducer state
    reactDispatch({ type: "CLEAR_AOI_DRAWING" });
  };

  const isInvalidSite = () => {
    // MOVE THESE LIMITS TO SETTINGS.JS
    let invalid = false;
    if (state.siteName.length === 0) invalid = true;
    if (state.totalSqKm > 100000) invalid = true;
    if (state.features.length === 0) invalid = true;
    // prevent user from submitting zero point polygons
    if (state.totalSqKm === 0) invalid = true;
    if (state.geoJson?.type === "GeometryCollection") {
      state.geoJson.geometries.forEach((geometry) => {
        if (area(geometry) < 1) {
          // prevent user from submitting zero point polygons
          invalid = true;
        }
      });
    }
    return invalid;
  };

  const handleCreateSite = () => {
    reactDispatch({ type: "CREATE_SITE_PENDING" });
    const siteId = nanoid();
    dispatch(
      createSite({
        id: siteId,
        name: state.siteName,
        tags: state.tags,
        geometry: state.geoJson,
        createdAt: Date.now(),
        isDeleted: false,
        createdBy: uid,
        account,
        totalPoints: state.totalPoints,
        totalSqKm: state.totalSqKm,
      })
    ).then(() => {
      dispatch(
        createNotification({
          title: "Site created successfully",
          description: "",
        })
      );
      dispatch(fetchAccountResources());
      navigate(`/map/${siteId}`);
    });
  };

  return (
    <>
      <div className="h-screen">
        <div className="no-scrollbar fixed bottom-3 left-24 top-3 z-30 w-1/4 overflow-y-scroll">
          <div
            style={{ minWidth: "300px" }}
            className="mr-2 rounded-md bg-bsr-gray-050 shadow-md"
          >
            <div className="border-b border-bsr-gray-100 bg-white p-4 text-center text-xl font-semibold text-bsr-gray-700">
              Create site
            </div>
            <div className="p-4">
              <div className="my-5">
                A site is an area of interest on the map. When you create a
                site, AirLogic starts collecting and analyzing emissions and air
                quality measurements over that site.
              </div>
              <div className="my-2 font-semibold">Find a location</div>
              <div className="z-30 w-full">
                <input
                  className="w-full rounded-sm p-1"
                  id="autocomplete"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  type="text"
                  placeholder="search for a location by name..."
                ></input>
              </div>
              <div className="my-2 font-semibold">Name your site</div>
              <input
                value={state.siteName}
                className="w-full rounded-sm border border-solid border-bsr-gray-500 p-1"
                onChange={(e) =>
                  reactDispatch({
                    type: "UPDATE_SITE_NAME",
                    payload: e.target.value,
                  })
                }
                placeholder="pick a name..."
              ></input>
              {process.env.REACT_APP_TAGS_FEATURE_ENABLED === true && (
                <>
                  <div className="my-2 font-semibold">Add tag (optional)</div>
                  <input
                    value={state.tags.length > 0 ? state.tags[0] : ""}
                    className="w-full rounded-sm border border-solid border-bsr-gray-500 p-1"
                    onChange={(e) =>
                      reactDispatch({
                        type: "ADD_SITE_TAG",
                        payload: e.target.value,
                      })
                    }
                    placeholder="enter a tag name..."
                  ></input>
                </>
              )}
              <div className="my-5 font-semibold">Draw your site</div>
              <div className="my-5">
                Use the drawing tools at the top of the map to draw your site.
              </div>
              <div>
                <Button
                  type="primary"
                  className="mr-5"
                  style={{ padding: "2px" }}
                  onClick={handleCreateSite}
                  disabled={isInvalidSite() || state.status === "loading"}
                >
                  {state.status === "loading" ? (
                    <>
                      <CogIcon className="mr-2 h-3 w-3 animate-spin" /> Create
                      Site
                    </>
                  ) : (
                    "Create site"
                  )}
                </Button>
                <Button type="secondary" onClick={clearAoi}>
                  Clear drawing
                </Button>
              </div>
            </div>
          </div>
        </div>
        <MapCreate
          calculateAoi={() =>
            map.data.toGeoJson((geojson) => {
              reactDispatch({ type: "UPDATE_AOI_DRAWING", payload: geojson });
            })
          }
          addFeature={(newFeature) => {
            reactDispatch({ type: "ADD_FEATURE", payload: newFeature });
          }}
          suggestName={(name) =>
            reactDispatch({
              type: "UPDATE_SITE_NAME",
              payload: name,
            })
          }
          passMap={(map) => setMap(map)}
        />
      </div>
    </>
  );
}
