import { useState, useReducer, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useParams } from "react-router-dom";
import dayjs from "./dayjs";
import {
  selectDatasetsByActiveCadence,
  selectActiveDatasets,
  selectDatasetCategories,
  setActiveDatasets,
  selectSites,
  selectActiveTimePeriod,
  selectHideEmptyDatasets,
  updateHideEmptyDatasets,
} from "./store/applicationSlice";
import * as JSURL from "jsurl";
import { Switch } from "antd";
import DatasetCard from "./shared/DatasetCard";
import Button from "./shared/Button";

import { checkDatasets } from "./services/Api";

import { isEqual } from "lodash";
import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { classNames, safeToLowerCase } from "./utilities";

export default function DatasetsPanel({ closePanel }) {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const siteIds = useSelector(selectSites).map((s) => s.id);
  const sitesForQuery = siteId ? [siteId] : siteIds;
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const hideEmptyDatasets = useSelector(selectHideEmptyDatasets);
  const datasets = useSelector(selectDatasetsByActiveCadence);
  const activeDatasets = useSelector(selectActiveDatasets);
  const [stagedDatasets, setStagedDatasets] = useState(activeDatasets);

  const initialState = {
    status: "idle", // idle | in_progress | complete | failed
  };

  const [datasetsReport, datasetsReportDispatch] = useReducer(
    (state, action) => {
      if (action.type === "dataset_checked") {
        if (
          state.hasOwnProperty(action.payload.datasetId) &&
          state[action.payload.datasetId] === false
        ) {
          return state;
        } else {
          return {
            ...state,
            [action.payload.datasetId]: action.payload.empty,
          };
        }
      } else if (action.type === "check_complete") {
        console.log(state);
        return {
          ...state,
          status: "complete",
        };
      } else if (action.type === "check_started") {
        return {
          status: "in_progress",
        };
      } else if (action.type === "clear_state") {
        return initialState;
      } else {
        return state;
      }
    },
    initialState
  );

  useEffect(
    () => {
      if (datasets && datasets.length > 0 && hideEmptyDatasets) {
        checkDatasets(
          {
            datasetIds: datasets.map((d) => d.id),
            siteIds: sitesForQuery,
            cadence: activeTimePeriod.cadence,
            startDate: dayjs(activeTimePeriod.startDate).toDate(),
            endDate: dayjs(activeTimePeriod.endDate).toDate(),
          },
          datasetsReportDispatch
        );
      }
    },
    // eslint-disable-next-line
    [
      activeTimePeriod.cadence,
      activeTimePeriod.startDate,
      activeTimePeriod.endDate,
      hideEmptyDatasets,
    ]
  );

  const activeDatasetIds = activeDatasets.map((d) => d.id);
  const stagedDatasetIds = stagedDatasets.map((d) => d.id);

  const datasetCategories = useSelector(selectDatasetCategories);
  const tabs = datasetCategories.map((category) => {
    return {
      name: category,
      href: "#category-" + safeToLowerCase(category).replace(" ", "-"),
      current: false,
    };
  });
  tabs[0].current = true;

  const datasetsByCategory = datasets.reduce((acc, cur) => {
    if (!acc[cur.category]) {
      acc[cur.category] = [cur];
    } else {
      acc[cur.category].push(cur);
    }
    return acc;
  }, {});

  const [, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState("");

  const results = !searchTerm
    ? datasets.slice()
    : datasets
        .filter(
          (dataset) =>
            safeToLowerCase(dataset.name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(dataset.category).includes(
              searchTerm.toLocaleLowerCase()
            )
        )
        .slice();

  // sort by name
  results.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  return (
    <div className="nearly-full-height-minus-header-and-sub-header bg-white ring-1 ring-black ring-opacity-5 ">
      <div className="flex h-full">
        <div className="h-full flex-1 p-5">
          <div className="flex h-full flex-col">
            {/* height matches the heading in the Acitve Datasets div to the right */}
            <div className="flex-0 flex h-12 items-center justify-between">
              <div className="mr-5 text-xl font-semibold">Datasets</div>
              <div className="mr-5 grow">
                <div className="relative mt-2 flex flex-grow pb-1">
                  <div className="absolute inset-y-2 left-2">
                    <MagnifyingGlassIcon
                      className="h-5 w-5 text-bsr-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="block w-full rounded-md border-bsr-gray-200 pl-10 text-bsr-gray-700 focus:border-bsr-blue-500 focus:ring-bsr-blue-500 sm:text-sm"
                    placeholder="Search datasets"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    value={searchTerm}
                  />
                  {searchTerm && (
                    <div className="absolute inset-y-2 right-0 cursor-pointer pr-3">
                      <XMarkIcon
                        className="h-5 w-5 text-bsr-gray-400"
                        aria-hidden="true"
                        onClick={() => {
                          setSearchTerm("");
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              {sitesForQuery.length === 1 && (
                <>
                  <div className="flex items-center justify-between">
                    <label className="mr-2 text-xs text-bsr-gray-500">
                      Hide empty datasets
                    </label>
                    <div>
                      <Switch
                        style={{
                          backgroundColor:
                            datasetsReport.status === "idle"
                              ? "#616E7C"
                              : "#2BB0ED",
                        }}
                        checked={hideEmptyDatasets}
                        loading={datasetsReport.status === "in_progress"}
                        onChange={(checked) => {
                          if (checked) {
                            dispatch(updateHideEmptyDatasets(true));
                            checkDatasets(
                              {
                                datasetIds: datasets.map((d) => d.id),
                                siteIds: sitesForQuery,
                                cadence: activeTimePeriod.cadence,
                                startDate: dayjs(
                                  activeTimePeriod.startDate
                                ).toDate(),
                                endDate: dayjs(
                                  activeTimePeriod.endDate
                                ).toDate(),
                              },
                              datasetsReportDispatch
                            );
                          } else {
                            dispatch(updateHideEmptyDatasets(false));
                            datasetsReportDispatch({ type: "clear_state" });
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex-0 mb-5 mt-2">
              <div className="sm:hidden">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                <select
                  id="tabs"
                  name="tabs"
                  className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-bsr-blue-500 focus:outline-none focus:ring-bsr-blue-500 sm:text-sm"
                  defaultValue={tabs.find((tab) => tab.current).name}
                >
                  {tabs.map((tab) => (
                    <option key={tab.name}>{tab.name}</option>
                  ))}
                </select>
              </div>
              <div className="flex-0 hidden sm:block">
                <div className="border-b border-gray-200">
                  <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                    {tabs.map((tab) => (
                      <a
                        key={tab.name}
                        href={tab.href}
                        className={classNames(
                          tab.current
                            ? "border-bsr-blue-500 text-bsr-blue-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                          "whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
                        )}
                        aria-current={tab.current ? "page" : undefined}
                      >
                        {tab.name}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
            <div className="flex-1 overflow-y-scroll">
              {searchTerm ? (
                <>
                  <div className="mb-4">
                    <div className="text text-gray-600">
                      Showing results for{" "}
                      <span className="font-medium">"{searchTerm}"</span>{" "}
                      <span
                        className="cursor-pointer underline"
                        onClick={() => setSearchTerm("")}
                      >
                        Clear search
                      </span>
                    </div>
                  </div>
                  <ul className="mb-10 divide-y divide-gray-100">
                    {results.map((dataset, i) => {
                      if (
                        datasetsReport.status === "complete" &&
                        datasetsReport[dataset.id]
                      ) {
                        return null;
                      } else {
                        return (
                          <li key={dataset.id}>
                            <DatasetCard
                              key={dataset.id + i}
                              dataset={dataset}
                              isActive={stagedDatasets.find(
                                (d) => d.id === dataset.id
                              )}
                              setStagedDatasets={setStagedDatasets}
                              showActiveCheckmark={true}
                            />
                          </li>
                        );
                      }
                    })}
                  </ul>
                </>
              ) : (
                <div>
                  {tabs.map((tab, i) => {
                    return (
                      <div key={tab.name + i} className="mb-2">
                        <div
                          className="mb-3 text-2xl font-medium"
                          id={`category-${safeToLowerCase(tab.name).replace(
                            " ",
                            "-"
                          )}`}
                          name={tab.name}
                        >
                          {tab.name}
                        </div>

                        {datasetsByCategory[tab.name] // check that at least one dataset in the category contains some measurements
                          .map((dataset) =>
                            datasetsReport.status === "complete" &&
                            datasetsReport[dataset.id]
                              ? false
                              : true
                          )
                          .some((v) => v) ? (
                          <ul className="mb-10 divide-y divide-gray-100">
                            {datasetsByCategory[tab.name].map((dataset, i) => {
                              if (
                                datasetsReport.status === "complete" &&
                                datasetsReport[dataset.id]
                              ) {
                                return null;
                              } else {
                                return (
                                  <li key={dataset.id}>
                                    <DatasetCard
                                      key={dataset.id + i}
                                      dataset={dataset}
                                      isActive={stagedDatasets.find(
                                        (d) => d.id === dataset.id
                                      )}
                                      setStagedDatasets={setStagedDatasets}
                                      showActiveCheckmark={true}
                                    />
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        ) : (
                          <p className="mb-10 pl-5 text-sm italic text-bsr-gray-300">
                            No data available for currently selected time
                            period.
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex-0 border-l-1 h-full w-[420px] border-l border-bsr-gray-100 bg-bsr-gray-050 p-5">
          <div className="flex h-full flex-col">
            {/* height matches the heading in the Datasets div to the left */}
            <div className="flex-0 flex h-12 items-center justify-between">
              <div className="flex-1 text-xl font-semibold">
                Active Datasets
              </div>
              {/* <div
                onClick={() => closePanel()}
                className="flex-0 cursor-pointer text-sm font-medium uppercase text-bsr-gray-500 hover:bg-bsr-gray-100"
              >
                <XMarkIcon className="mr-2 h-5 w-5 text-bsr-gray-500" />
              </div> */}
            </div>
            <div className="no-scrollbar flex-1 overflow-y-scroll">
              {stagedDatasets.map((dataset, i) => {
                return (
                  <div
                    key={dataset.id}
                    className="flex-0 flex items-center justify-between"
                  >
                    <div className="flex-1">
                      <DatasetCard
                        key={dataset.id + i}
                        dataset={dataset}
                        setStagedDatasets={null}
                        isActive={true}
                        showActiveCheckmark={false}
                      />
                    </div>
                    <div className="flex-0 flex">
                      <div
                        onClick={() => {
                          setStagedDatasets((staged) =>
                            staged.filter((d) => d.id !== dataset.id)
                          );
                        }}
                        title="deselect site"
                        className="cursor-pointer text-sm font-medium leading-4 text-bsr-blue-700 hover:text-bsr-blue-600"
                      >
                        Remove
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-auto flex items-center justify-end">
              <Button onClick={closePanel} type="tertiary">
                Cancel
              </Button>
              <Button
                type="primary"
                disabled={isEqual(activeDatasetIds, stagedDatasetIds)}
                onClick={() => {
                  setSearchParams((sp) => {
                    sp.set("datasets", JSURL.stringify(stagedDatasetIds));
                    return sp;
                  });
                  dispatch(setActiveDatasets(stagedDatasetIds));
                  closePanel();
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
