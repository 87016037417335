import { useSelector, useDispatch } from "react-redux";
import {
  useParams,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useState } from "react";
import { isEmpty } from "lodash";
import {
  PlusCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
  CogIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import {
  CheckCircleIcon,
  StarIcon as SolidStarIcon,
} from "@heroicons/react/24/solid";
import { classNames, safeToLowerCase } from "./utilities";

import {
  selectSites,
  selectSiteById,
  clearActiveObservations,
  updateActiveOpticalLayer,
  updateActiveGrouping,
  selectGroupings,
  selectActiveGrouping,
} from "./store/applicationSlice";
import { favoriteSite } from "./services/Api";
import useUserSettingsListener from "./hooks/useUserSettingsListener";

export default function SitesPanel({ closePanel }) {
  // this hook re-hyrdates user settings ( favorite sites array )
  // in redux store whenever a site doc changes
  useUserSettingsListener();
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const site = useSelector((state) => selectSiteById(state, siteId));
  const settings = useSelector((state) => state.app.settings);
  const favoriteSites = settings.favorite_site_ids;
  const [searchTerm, setSearchTerm] = useState("");
  const sites = useSelector(selectSites);
  const groupings = useSelector(selectGroupings);
  const activeGrouping = useSelector(selectActiveGrouping);
  const navigate = useNavigate();
  const [searchParams, setSearchParameters] = useSearchParams();

  const location = useLocation();
  const view = location.pathname.split("/")[1];

  const results = !searchTerm
    ? sites.slice()
    : sites
        .filter(
          (site) =>
            safeToLowerCase(site.name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(site.alternateSiteId).includes(
              searchTerm.toLocaleLowerCase()
            )
        )
        .slice();

  // sort by name
  results.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <div className="nearly-full-height-minus-header-and-sub-header ring-1 ring-black ring-opacity-5">
      <div className="flex h-full flex-col bg-white">
        <div className="flex-0 mx-3 mt-3">
          {site && (
            <div className="mb-6 flex items-center justify-between bg-bsr-gray-100 px-3 py-5">
              <div>
                <span className="text-sm tracking-wide text-bsr-gray-900">
                  Selected site
                </span>
                <div className="truncate text-xl font-extrabold tracking-wider text-bsr-gray-900">
                  {site.name && site?.alternateSiteId ? (
                    <>
                      {site?.name}{" "}
                      <span className="font-light">
                        {site?.alternateSiteId}
                      </span>
                    </>
                  ) : (
                    site?.name
                  )}
                </div>
              </div>
              <div className="flex">
                <Link to={`/sites/${site.id}/edit`} title="edit site">
                  <CogIcon className="mr-2 h-5 w-5 text-bsr-gray-500" />
                </Link>
                <div
                  onClick={() => {
                    searchParams.delete("x");
                    searchParams.delete("y");
                    searchParams.delete("z");
                    navigate({
                      pathname: `/${view}`,
                      search: searchParams.toString(),
                    });
                    closePanel();
                  }}
                  title="deselect site"
                  className="cursor-pointer"
                >
                  <XMarkIcon className="mr-2 h-5 w-5 text-bsr-gray-500" />
                </div>
              </div>
            </div>
          )}
          <div className="mb-2 flex items-center justify-between">
            <div className="text-lg font-bold">Sites ({sites.length})</div>
            <div className="relative flex pb-1">
              <div className="absolute inset-y-2 left-2">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-bsr-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="block w-full rounded-md border-bsr-gray-200 pl-10 text-bsr-gray-700 focus:border-bsr-blue-500 focus:ring-bsr-blue-500 sm:text-sm"
                placeholder="Search in sites"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                value={searchTerm}
              />
              {searchTerm && (
                <div className="absolute inset-y-2 right-0 cursor-pointer pr-3">
                  <XMarkIcon
                    className="h-5 w-5 text-bsr-gray-400"
                    aria-hidden="true"
                    onClick={() => {
                      setSearchTerm("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {!isEmpty(groupings) &&
            !site &&
            Object.keys(groupings).map((grouping) => {
              return (
                <div key={grouping} className="relative mb-2 flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="priority"
                      aria-describedby="priority-description"
                      name="a grouping of sites"
                      type="checkbox"
                      checked={activeGrouping?.id === grouping}
                      onChange={(e) => {
                        if (e.target.checked) {
                          // delete grouping from URL and clear active grouping in redux
                          dispatch(updateActiveGrouping(groupings[grouping]));
                          setSearchParameters((sp) => {
                            sp.set("grouping", grouping);
                            return sp;
                          });
                        } else {
                          setSearchParameters((sp) => {
                            sp.delete("grouping");
                            return sp;
                          });
                          dispatch(updateActiveGrouping(null));
                        }
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-bsr-blue-600 focus:ring-bsr-blue-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="priority"
                      className="font-medium text-gray-900"
                    >
                      {groupings[grouping].name}
                    </label>
                    <p id="comments-description" className="text-gray-500">
                      {groupings[grouping].description}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mx-3 mb-6 flex-1 overflow-scroll">
          {results.map((s) => (
            <div
              key={s.id}
              className={classNames(
                s.id === siteId
                  ? "border-2 border-bsr-blue-500"
                  : "border-b border-bsr-gray-100",
                "flex cursor-pointer items-center justify-between px-2 py-2 hover:bg-bsr-gray-100"
              )}
            >
              <div className="flex w-full items-center">
                {process.env.REACT_APP_FAVORITE_SITES_ENABLED && (
                  <div
                    onClick={() => {
                      favoriteSite(s.id);
                    }}
                    className="mr-4"
                  >
                    {favoriteSites.includes(s.id) ? (
                      <SolidStarIcon className="h-5 w-5 text-yellow-500" />
                    ) : (
                      <StarIcon className="h-5 w-5 text-bsr-gray-500" />
                    )}
                  </div>
                )}

                <div
                  key={s.id}
                  onClick={() => {
                    searchParams.delete("x");
                    searchParams.delete("y");
                    searchParams.delete("z");
                    searchParams.delete("observations");
                    searchParams.delete("grouping");
                    dispatch(clearActiveObservations());
                    dispatch(updateActiveOpticalLayer(null));
                    dispatch(updateActiveGrouping(null));
                    navigate({
                      pathname: `/${view}/${s.id}`,
                      search: searchParams.toString(),
                    });
                    closePanel();
                  }}
                  className="grow truncate text-sm text-bsr-gray-600"
                >
                  {s.name}{" "}
                  <span className="text-sm text-bsr-gray-400">
                    {s.alternateSiteId}
                  </span>
                </div>
                <div className="ml-auto">
                  {s.id === siteId ? (
                    <CheckCircleIcon className="h-5 w-5 text-bsr-blue-500" />
                  ) : (
                    <ChevronRightIcon className="h-3 w-3 text-bsr-gray-200" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex-0">
          {process.env.REACT_APP_AIR_AWARE === "true" && (
            <Link
              to="/sites/new"
              className="flex h-14 items-center justify-center bg-bsr-blue-600 p-5 font-bold tracking-wider hover:bg-bsr-blue-400"
            >
              <PlusCircleIcon
                className="-ml-5 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span className="ml-2 text-sm text-white">Create a new site</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
