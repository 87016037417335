import { useSelector } from "react-redux";
import {
  selectObservationsChronologically,
  selectObservationsStatus,
} from "./store/siteSlice";

import MeasurementsTable from "./shared/MeasurementsTable";
import { selectActiveTimePeriod } from "./store/applicationSlice";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Loading from "./shared/Loading";

export default function Recent() {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const observationsStatus = useSelector(selectObservationsStatus);
  const observations = useSelector(selectObservationsChronologically);

  // switch time period to last 30 days
  // useEffect(() => {
  //   if (activeTimePeriod.name === timeFrames[3].name) return;
  //   console.log("firing");
  //   const newDatasetIds = activeDatasets.map((d) => {
  //     return translateDatasetToActiveTimePeriod(d.id, timeFrames[3].cadence);
  //   });
  //   setSearchParams((sp) => {
  //     sp.set("datasets", JSURL.stringify(newDatasetIds));
  //     sp.set("timeframe", JSURL.stringify(timeFrames[3]));
  //     return sp;
  //   });
  // }, [activeTimePeriod.name, activeDatasets, setSearchParams]);

  if (observationsStatus === "loading") {
    return <Loading />;
  }

  return (
    <main className="mt-8 min-h-screen max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
      <h2 className="mb-5 inline-flex items-center text-2xl leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
        <span className="font-bold">Recent measurements</span>
        <ChevronRightIcon
          className="h-7 w-7 flex-shrink-0"
          aria-hidden="true"
        />
        {activeTimePeriod.name && activeTimePeriod.name.replace("Custom", "")}
      </h2>
      {observations.length ? (
        <MeasurementsTable data={observations} />
      ) : (
        <div className="mt-4 flex w-full items-center justify-center rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 py-5 text-center hover:border-gray-300">
          <p className="block text-sm font-medium text-bsr-gray-700">
            No data available. Select another dataset, site, and/or time period.
          </p>
        </div>
      )}
    </main>
  );
}
