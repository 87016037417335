import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function TextField({ name, label, error, onChange }) {
  // Most of the time autoComplete is just the name of the field, except
  //   autoComplete value for password is "current-password", not "password"
  //   see https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands
  const autoComplete = name === "password" ? "current-password" : name;

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div
        className={classNames(
          error ? "relative rounded-md shadow-sm" : undefined,
          "mt-1"
        )}
      >
        <input
          id={name}
          name={name}
          type={name}
          autoComplete={autoComplete}
          required
          onChange={onChange}
          className={classNames(
            error
              ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500"
              : "border-gray-300 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500",
            "block w-full appearance-none rounded-md border px-3 py-2 shadow-sm focus:outline-none sm:text-sm"
          )}
          aria-invalid={error ? "true" : undefined}
          aria-describedby={error ? name + "-error" : undefined}
        />
        {error && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id={name + "-error"}>
          {error}
        </p>
      )}
    </div>
  );
}

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default TextField;
