import { format, parse as mparse } from "mathjs";
import { parse } from "wkt";
import { feature, featureCollection, point } from "@turf/helpers";
import { scaleOrdinal, scaleThreshold } from "d3-scale";
import { isPlainObject } from "lodash";
import dayjs from "./dayjs";

export function appendValueToAggregate(
  agg,
  n,
  total,
  total_uncertainty,
  value,
  uncertainty
) {
  if (isNullish(value)) return { total, total_uncertainty, n };

  if (isNullish(total)) {
    total = value;
    total_uncertainty = isNullish(uncertainty) ? null : uncertainty;
    n = 1;
    return { total, total_uncertainty, n };
  }

  if (agg === "sum") {
    total = total + value;
    if (!isNullish(uncertainty)) {
      if (!isNullish(total_uncertainty)) {
        total_uncertainty = (total_uncertainty ** 2 + uncertainty ** 2) ** 0.5;
      } else {
        total_uncertainty = uncertainty;
      }
    }
  } else if (agg === "avg") {
    total = (total * n + value) / (n + 1);
    if (!isNullish(uncertainty)) {
      if (!isNullish(total_uncertainty)) {
        total_uncertainty =
          (n ** 2 * total_uncertainty ** 2 + uncertainty ** 2) ** 0.5 / (n + 1);
      } else {
        total_uncertainty = uncertainty;
      }
    }
  } else if (agg === "min") {
    if (value <= total) {
      total = value;
      total_uncertainty = uncertainty;
    }
  } else if (agg === "max") {
    if (value >= total) {
      total = value;
      total_uncertainty = uncertainty;
    }
  }

  return { total, total_uncertainty, n: n + 1 };
}

// enable a dataset to be used in multiple aggregations. For example:
//  purple air exceedance count, and putple air total readings
//  given a dataset id, find any aggregations that use the dataset
export function findAggregationsForDatasetId(dataset_id, aggregations) {
  if (!aggregations) {
    return [];
  }

  const matchedAggregations = aggregations.filter((agg) => {
    const regex = new RegExp(agg.dataset_matcher);
    return regex.test(dataset_id);
  });

  return matchedAggregations;
}

export function findDatasetForAggregation(aggregation, frequency, datasets) {
  if (!aggregation || !frequency || !datasets) {
    return null;
  }

  const regex = new RegExp(aggregation.dataset_matcher);

  const matchingDataset = datasets.find((dataset) => {
    return dataset.frequency === frequency && regex.test(dataset.id);
  });

  if (matchingDataset) {
    return matchingDataset;
  } else {
    console.warn(
      `could not find a matching dataset for the following aggregation and cadence ${aggregation.dataset_matcher} ${frequency}`
    );
    return null;
  }
}

export function aggregationsToDatasetIds(aggregations, datasets, timePeriod) {
  if (!aggregations || !datasets || !timePeriod) return null;

  return datasets
    .filter((dataset) => {
      return dataset.frequency === timePeriod.cadence;
    })
    .map((dataset) => dataset.id)
    .filter((id) => {
      return aggregations.some((aggregation) => {
        const regex = new RegExp(aggregation.dataset_matcher);
        return regex.test(id);
      });
    });
}

export function translateDatasetsByCadence(
  previousCadence,
  currentCadence,
  activeDatasets,
  datasets
) {
  const activeDatasetsForCurrentCadence = activeDatasets.map((d) => {
    const newId = d.id.replace(previousCadence, currentCadence);
    const dataset = datasets.find((d) => d.id === newId);
    return dataset;
  });
  return activeDatasetsForCurrentCadence;
}

export function findChangePercentage(first, last) {
  if (isNullish(first) || isNullish(last)) {
    return null;
  } else if (first === 0) {
    return undefined;
  } else {
    return ((last - first) / Math.abs(first)) * 100;
  }
}

// Allow aggregations to use properties of observations other than
//  the value field
export function getValueForAggregation(aggregation, observation) {
  return {
    value:
      aggregation?.value_field === "value"
        ? observation?.[aggregation?.value_field]
        : observation?.metadata?.[aggregation?.value_field],
    uncertainty: observation?.metadata?.uncertainty,
  };
}

export function generateTimeSeries(startDatetime, endDatetime, cadence) {
  const obj = {};
  let unit;
  let format;
  if (cadence === "yearly") {
    unit = "year";
    format = "YYYY";
  } else if (cadence === "monthly") {
    unit = "month";
    format = "YYYY-MM";
  } else if (cadence === "daily") {
    unit = "day";
    format = "YYYY-MM-DD";
  } else if (!unit || !cadence) {
    throw new Error(
      "Unrecognized cadence argument provided to generateTimeSeries"
    );
  }
  for (
    let x = dayjs(startDatetime);
    x.isSameOrBefore(dayjs(endDatetime), unit);
    x = x.add(1, unit)
  ) {
    obj[x.format(format)] = null;
  }
  return obj;
}

export function datetimeStrToBounds(datetimeStr, cadence) {
  const map = {
    daily: "day",
    monthly: "month",
    yearly: "year",
  };
  const startDate = dayjs(datetimeStr);
  const endDate = startDate.add(1, map[cadence]);
  return [startDate, endDate];
}

export function calculateStatus(data, threshold) {
  if (!threshold) {
    return "gray";
  }
  let status = "green";
  data.forEach((measurement) => {
    if (
      measurement.value > threshold[0] &&
      measurement.value < threshold[1] &&
      status === "green"
    ) {
      status = "yellow";
    } else if (measurement.value >= threshold[1]) {
      status = "red";
    }
  });
  return status;
}

export function calculateSingleStatus(value, threshold) {
  let status = "green";
  if (value > threshold[0] && value < threshold[1]) {
    status = "yellow";
  } else if (value >= threshold[1]) {
    status = "red";
  }
  return status;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function toScientificNotation(value) {
  if (isNullish(value)) return null;
  try {
    return format(mparse(value), {
      precision: 4,
      upperExp: 4,
      lowerExp: -3,
    });
  } catch (error) {
    console.log("failed to parse: " + value);
    return null;
  }
}

export const formatData = (value, uncertainty) => {
  if (value === null || isNullish(value)) {
    return { value: null, uncertainty: null };
  }
  if (value === undefined && uncertainty === undefined) return "";
  const uncertainty_display = formatUncertainty(value, uncertainty);

  var absValue = Math.abs(value);
  try {
    if (absValue >= 10000) {
      const formatter = Intl.NumberFormat("en", { notation: "compact" });
      return {
        value: Number.isInteger(value)
          ? formatter.format(value)
          : formatter.format(value.toFixed(0)),
        uncertainty_display,
      };
    } else if (absValue >= 1000) {
      const formatter = Intl.NumberFormat("en");
      return {
        value: Number.isInteger(value)
          ? formatter.format(value)
          : formatter.format(value.toFixed(0)),
        uncertainty_display,
      };
    } else if (absValue < 1000 && absValue >= 1) {
      return {
        value: Number.isInteger(value) ? value : value.toFixed(2),
        uncertainty_display,
      };
    } else if (absValue < 1 && absValue >= 0.001) {
      return { value: value.toFixed(3), uncertainty_display };
    } else if (absValue < 0.001 && absValue > 0) {
      return { value: value.toExponential(2), uncertainty_display };
    } else {
      return { value: value.toFixed(0), uncertainty_display };
    }
  } catch (error) {
    console.log("failed to parse table cell data:");
    console.log(value, uncertainty);
    return "";
  }
};

export function getTimeFormat(frequency) {
  if (frequency === "yearly") {
    return "YYYY";
  } else if (frequency === "monthly") {
    return "MMM YYYY";
  } else {
    return "MMM Do YYYY";
  }
}

export function isNullish(val) {
  return val === undefined || Number.isNaN(val) || val === null;
}

export function safeToLowerCase(val) {
  if (typeof val === "string") {
    return val.toLowerCase();
  }
  return "";
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : [0, 0, 0];
}

export function getD3ScaleForVis(vis) {
  if (vis.type === "threshold") {
    return scaleThreshold().domain(vis.domain).range(vis.range);
  }

  if (vis.type === "ordinal") {
    return scaleOrdinal().domain(vis.domain).range(vis.range);
  }

  throw Error(`unkown scaleType ${vis.type} ${vis}`);
}

export function computeColor(vis, value) {
  const scale = getD3ScaleForVis(vis);
  return scale(value);
}

export function computeStoneColor(dataset, value) {
  if (isNullish(value)) return "#9AA5B1";

  const scale = getD3ScaleForVis(dataset.metric.vis);
  const colorValue = scale(value);
  return colorValue;
}

// dayjs().isSameOrBefore('2011-01-01', 'year')

export function tailwindClassToHex(className) {}

export function formatUncertainty(value, uncertainty) {
  try {
    if (!isNullish(uncertainty)) {
      if (value === 0) {
        return null;
      }
      return (Math.abs(uncertainty / value) * 100).toFixed(1);
    }
  } catch (error) {
    console.log("failed to parse table cell data:");
    console.log(value, uncertainty);
    return null;
  }
}

export function sortByTimeAscending(a, b) {
  if (dayjs(a.datetime_start).isBefore(b.datetime_start)) {
    return -1;
  }
  if (dayjs(a.datetime_start).isAfter(b.datetime_start)) {
    return 1;
  }
  return 0;
}

export function relativeTimeToDatesForDisplay(selection) {
  // this utility needs work. want to support more flexibility
  const today = dayjs();
  let startDate = today
    .startOf(selection.startOf)
    .subtract(selection.units, selection.interval)
    .format(selection.interval === "month" ? "MMM YYYY" : "YYYY");

  let endDate = today
    .startOf(selection.startOf)
    .subtract(1, "second")
    .format(selection.interval === "month" ? "MMM YYYY" : "YYYY");

  if (selection.name === "This year") {
    startDate = dayjs()
      .startOf(selection.startOf)
      .format(selection.interval === "month" ? "MMM YYYY" : "YYYY");
    endDate = dayjs()
      .endOf(selection.startOf)
      .format(selection.interval === "month" ? "MMM YYYY" : "YYYY");
  }

  return `${startDate} - ${endDate}`;
}

export function relativeTimeToDatesForQuery(timePeriod) {
  // this utility needs work. want to support more flexibility
  let endOfTimePeriod = dayjs().startOf(timePeriod.startOf);
  let startOfTimePeriod = endOfTimePeriod.subtract(
    timePeriod.units,
    timePeriod.interval
  );

  if (timePeriod.name === "This year") {
    endOfTimePeriod = dayjs().endOf(timePeriod.startOf);
    startOfTimePeriod = dayjs().startOf(timePeriod.startOf);
  }

  return [
    startOfTimePeriod.format(
      timePeriod.interval === "month" ? "YYYY-MM" : "YYYY"
    ),
    endOfTimePeriod.format(
      timePeriod.interval === "month" ? "YYYY-MM" : "YYYY"
    ),
  ];
}

export function toFrequencyFromInterval(interval) {
  if (interval === "day") {
    return "daily";
  } else if (interval === "month") {
    return "monthly";
  } else if (interval === "year") {
    return "yearly";
  }
}

export function formatTimeFrame(frame, format) {
  return `${dayjs(frame[0]).format(format)} - ${dayjs(frame[1]).format(
    format
  )}`;
}

/*\
|*|
|*|  Base64 / binary data / UTF-8 strings utilities
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Base64_encoding_and_decoding
|*|
\*/

/* Array of bytes to Base64 string decoding */

function b64ToUint6(nChr) {
  return nChr > 64 && nChr < 91
    ? nChr - 65
    : nChr > 96 && nChr < 123
    ? nChr - 71
    : nChr > 47 && nChr < 58
    ? nChr + 4
    : nChr === 43
    ? 62
    : nChr === 47
    ? 63
    : 0;
}

export function base64DecToArr(sBase64, nBlocksSize) {
  var sB64Enc = sBase64.replace(/[^A-Za-z0-9+/]/g, ""),
    nInLen = sB64Enc.length,
    nOutLen = nBlocksSize
      ? Math.ceil(((nInLen * 3 + 1) >> 2) / nBlocksSize) * nBlocksSize
      : (nInLen * 3 + 1) >> 2,
    taBytes = new Uint8Array(nOutLen);

  for (
    var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0;
    nInIdx < nInLen;
    nInIdx++
  ) {
    nMod4 = nInIdx & 3;
    nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (6 * (3 - nMod4));
    if (nMod4 === 3 || nInLen - nInIdx === 1) {
      for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
        taBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
      }
      nUint24 = 0;
    }
  }

  return taBytes;
}

export function buildObservationId(datasetId, datetimeStr) {
  return `${datasetId}___${datetimeStr}`;
}

export function calculatePageName(path) {
  if (path === "/signup") return "signup";
  if (path === "/login") return "login";
  if (path === "/map") return "portfolio map";
  if (path === "/outliers") return "outliers";
  if (path === "/trends") return "portfolio trends";
  if (path.includes("/trends")) return "site trends";
  if (path.includes("/map")) return "site map";
  return "airlogic app";
}

export function sanitizeReferrer(referrer) {
  if (
    RegExp(/^\/map|^\/trends^|\/sites|^\/coverage|^\/outliers/gm).test(referrer)
  ) {
    return referrer;
  } else {
    return "/";
  }
}

export function translateDatasetToActiveTimePeriod(id, cadence) {
  try {
    const translated = id.replace(RegExp(/daily|monthly|yearly/), cadence);
    return translated;
  } catch (err) {
    console.warn("unable to retrieve dataset based on current time period");
    return null;
  }
}

export function readingsToGeojson(readings) {
  let featureCol;
  try {
    let features = [];
    readings.forEach((record) => {
      try {
        const feat = feature(parse(record.geometry));
        feat.properties = record;
        features.push(feat);
      } catch (err) {
        console.log("failed to parse metadata record:");
        console.log(record);
      }
    });
    // filter out null geometries
    features = features.filter((feature) => feature.geometry);
    featureCol = featureCollection(features);
    return featureCol;
  } catch (err) {
    console.log("something went wrong while constructing SEM layer:");
    console.log(err);
  }
}

export function getHtml(properties) {
  if (properties.layerType === "PlumeLayer") {
    let geometry = properties?.geometry;
    let uncertainty_normalized =
      properties?.uncertainty || properties?.uncertainty_outside;
    let emission_normalized =
      properties?.emission_rate || properties?.emission_rate_outside;
    const { value, uncertainty_display } = formatData(
      emission_normalized,
      uncertainty_normalized
    );
    let str = `
      <div>${dayjs(properties["time"]).format("YYYY-MM-DD H:mm")} UTC</div>
      <div>${value ? `emission rate: ${value} kg/hr` : ""} ${
      uncertainty_display ? `±${uncertainty_display}%` : ""
    }</div>
      <div>Lat: ${geometry?.coordinates[1]}, Lng: ${
      geometry?.coordinates[0]
    }</div>
      `;
    return str;
  } else if (
    properties.layerType === "SensorLayer" ||
    properties.layerType === "SemLayer" ||
    properties.layerType === "PathSensorLayer" ||
    properties.layerType === "PathSensorLegacyLayer"
  ) {
    console.log("properties: ");
    console.log(properties);
    let str = `
    <div class="flex flex-col text-lg">
      <div>
        ${
          properties.time
            ? dayjs(properties.time).format("MMMM Do hh:mm A")
            : properties.timestamp
            ? dayjs(properties.timestamp).format("MMMM Do hh:mm A")
            : ""
        }
      </div>
      <div>
        ${
          formatData(
            properties.scaledMetric
              ? properties[properties.scaledMetric]
              : properties["value"]
          ).value
        }
        ${String(properties?.units)}
        ${properties?.metric ? properties?.metric : ""}
      </div>
    </div>
    `;
    return str;
  } else if (properties.layerType === "PathSensorLayerCampaign") {
    console.log(properties);
    let str = `
        <div class="flex flex-col text-lg">
          <div>start: ${dayjs(properties.start_time).format(
            "YYYY-MM-DD HH:mm"
          )} UTC</div>
          <div>end: ${dayjs(properties.end_time).format(
            "YYYY-MM-DD HH:mm"
          )} UTC</div>
          <div>num. measurements: ${
            formatData(properties.num_measurements).value
          }</div>
          <div>average: ${
            formatData(properties.avg_concentration).value
          } ppm-m</div>
          <div>drone2flux est. emission rate: ${
            formatData(properties.DRONE2FLUX_emission_est).value
          } kg/hr</div>
          <div>irandoost est. emission rate: ${
            formatData(properties.Irandoost_emission_est).value
          } kg/hr</div>
          <div>area: ${formatData(properties.area / 1000000).value} km²</div>
          <div>${
            properties?.operation ? `operation: ${properties.operation}` : ""
          }</div>
        </div>
      `;
    return str;
  } else if (properties.layerType === "LgmsImpairedLayer") {
    console.log(properties);
    let str = `
        <div class="flex flex-col text-lg">
          ${
            properties.sensor_name &&
            `<div>Well ID: ${properties.sensor_name}</div>`
          }
          <div>start: ${dayjs(properties.acquisitions[0].time).format(
            "YYYY-MM-DD HH:mm"
          )} UTC</div>
          <div>Initial Flowrate: ${
            formatData(properties.acquisitions[0].flowrate).value
          } SCFM</div>
          <div>Adjusted Flowrate: ${
            formatData(properties.acquisitions[0].adjflowrate).value
          } SCFM</div>
          <div>Static Pressure: ${
            formatData(properties.acquisitions[0].refstaticpress).value
          } IN WC</div>
          <div>System Pressure: ${
            formatData(properties.acquisitions[0].systempressure).value
          } IN WC</div>
          <div>CH4 %: ${
            formatData(properties.acquisitions[0].ch4).value
          } %</div>
          <div>CO2 %: ${
            formatData(properties.acquisitions[0].co2).value
          } %</div>
          <div>O2 %: ${formatData(properties.acquisitions[0].o2).value} %</div>
        </div>
      `;
    return str;
  } else if (properties.layerType === "GroundSensorLayer") {
    let str = `
    <div className="flex flex-col">
    <div className="text-lg">
      ${properties?.name}
    </div>
    <div>
      ${formatData(properties?.value).value}
      ${String(properties?.units)}
      ${properties?.metric ? properties?.metric : ""}
    </div>
    ${
      properties.metadata
        ? Object.keys(properties.metadata)
            .map((key) => {
              return `<div>${properties.metadata[key]}</div>`;
            })
            .join(" ")
        : ""
    }
    `;
    return str;
  } else if (properties.layerType === "localWindLayer") {
    let str = `
    <div className="flex flex-col">
    <div className="text-lg">
      name: ${properties?.name}
    </div>
    <div>
      min: ${properties?.min}
      max: ${properties?.max}
      avg: ${properties?.avg}
    </div>
    `;
    return str;
  } else if (properties.layerType === "SemHeatMap") {
    console.log(properties);
    let str = `
      <div className="flex flex-col">
        <div>
         Mean: ${formatData(properties?.mean_reading).value} ppm
        </div>
        <div>
         Max: ${formatData(properties?.max_reading).value} ppm
        </div>
        <div>
         Min: ${formatData(properties?.min_reading).value} ppm
        </div>
        <div>
         Num. readings: ${formatData(properties?.total_readings).value}
        </div>
        <div>
         Num. exceedances: ${formatData(properties?.total_exceedances).value}
        </div>
      </div>
  `;
    return str;
  } else if (properties.layerType === "SemFluxHeatMap") {
    console.log(properties);
    let str = `
      <div className="flex flex-col">
        <div>
         ${formatData(properties?.total_rate).value} kg/hr
        </div>
        <div>
         from ${
           formatData(properties?.total_readings).value
         } interpolated points
        </div>
      </div>
  `;
    return str;
  } else {
    const privateProperties = ["geometry", "tags"];
    return Object.keys(properties).reduce((accumulator, key) => {
      if (!privateProperties.includes(key)) {
        let str = `<div>${key}: ${properties[key]}</div>`;
        accumulator += str;
      }
      return accumulator;
    }, "");
  }
}

export function createPoints(readings, properties) {
  let featureCol;
  try {
    let features = [];
    for (let sensor of readings) {
      const { geometry, lat, lon, ...rest } = sensor;
      if (!lat && !lon && !geometry) {
        console.error(
          "skipping reading because it does NOT contain lat/lon or geometry field(s)"
        );
        continue;
      }
      try {
        if (geometry) {
          const feat = feature(parse(geometry), {
            ...rest,
            ...properties,
          });
          features.push(feat);
          continue;
        } else if (lat && lon) {
          const feat = point([lon, lat], {
            ...rest,
            ...properties,
          });
          features.push(feat);
          continue;
        } else {
          continue;
        }
      } catch (err) {
        console.warn(`unuable to parse reading ${sensor}`);
        continue;
      }
    }
    // sorting results in measurements with higher values being plotted on top of lower measurement values
    features = features.sort(
      (a, b) =>
        a.properties[
          properties.scaledMetric ? properties.scaledMetric : "value"
        ] -
        b.properties[
          properties.scaledMetric ? properties.scaledMetric : "value"
        ]
    );
    featureCol = featureCollection(features);
    return featureCol;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const sortFunction = (field1, field2, sortAscending) => {
  let comparison = 0;

  const site1Value = isPlainObject(field1) ? field1?.total : field1;

  const site2Value = isPlainObject(field2) ? field2?.total : field2;

  if (sortAscending) {
    // sort nulls to bottom of list - remove this if block if you don't want this
    if (!isNullish(site1Value) && isNullish(site2Value)) {
      comparison = -1;
    } else if (!isNullish(site2Value) && isNullish(site1Value)) {
      comparison = 1; // sort nulls to bottom of list. change to -1 if you don't want this
    } else if (site1Value > site2Value) {
      comparison = 1;
    } else if (site2Value > site1Value) {
      comparison = -1;
    }
  } else {
    if (!isNullish(site1Value) && isNullish(site2Value)) {
      comparison = -1;
    } else if (site2Value > site1Value) {
      comparison = 1;
    } else if (site1Value > site2Value) {
      comparison = -1;
    }
  }
  return comparison;
};

export const formatDate = (date, { cadence, precise = false }) => {
  if (cadence === "daily") {
    let format = `MMM DD, YYYY`;
    if (precise) {
      format = `MMM DD, YYYY HH:mm`;
    }
    return dayjs(date).format(format);
  } else if (cadence === "monthly") {
    return dayjs(date).format("MMM YYYY");
  } else if (cadence === "yearly") {
    return dayjs(date).format("YYYY");
  } else {
    return dayjs(date).format("MMM DD, YYYY");
  }
};

export function updateUrlParameter(url, param, value) {
  var regex = new RegExp("(" + param + "=)[^&]+");
  return url.replace(regex, "$1" + value);
}

export function roundToNearestGranularity(num, granularity) {
  // Calculate the scaling factor based on the granularity
  var scale = 1 / granularity;

  // Round the number to the nearest multiple of the granularity
  var roundedNum = Math.round(num * scale) / scale;

  return roundedNum;
}

export function isTokenExpired(token) {
  console.log("checking expiration of token");
  const tokenParts = token.split(".");
  if (tokenParts.length !== 3) {
    return true; // Invalid token format
  }

  const payload = JSON.parse(atob(tokenParts[1]));
  if (!payload.exp) {
    return true; // Token doesn't have expiration time
  }

  const expirationTime = payload.exp * 1000; // JWT exp is in seconds, Date() requires milliseconds
  const currentTime = new Date().getTime();

  const isExpired = currentTime >= expirationTime;
  console.log({ isExpired });
  return isExpired;
}

export function convertToUnits(value, units) {
  if (units === "kg / hr") {
    return value;
  } else if (units === "SCFM") {
    return value / 1.15;
  }
}

export function calculateMovingAverageTimeBased(data, windowDays) {
  let movingAverage = [];

  for (let i = 0; i < data.length; i++) {
    let endDate = dayjs(data[i].x);
    let startDate = endDate.subtract(windowDays, "day");

    let sum = 0;
    let count = 0;

    for (let j = 0; j < data.length; j++) {
      let currentDate = dayjs(data[j].x);
      if (
        (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) ||
        currentDate.isSame(startDate) ||
        currentDate.isSame(endDate)
      ) {
        sum += data[j].y;
        count++;
      }
    }

    if (count > 0) {
      movingAverage.push({
        x: data[i].x,
        y: sum / count,
      });
    } else {
      movingAverage.push({
        x: data[i].x,
        y: null,
      });
    }
  }
  return movingAverage;
}

export function parseWktPoint(wktString) {
  try {
    const wktObject = parse(wktString);
    if (wktObject.type === "Point") {
      const [x, y] = wktObject.coordinates;
      return { lat: y, lng: x, geojson: wktObject };
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
}
