import PropTypes from "prop-types";
import { ReactComponent as Logo } from "../assets/logo-blue.svg";

function NoAccount({ user }) {
  return (
    <div className="flex min-h-screen flex-col justify-center bg-bsr-gray-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <Logo className="mx-auto h-12 w-auto" alt="Workflow" />
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="text-center text-3xl font-extrabold text-bsr-gray-600">
          Thank you for signing up for Airlogic
        </h2>
        <div className="my-5 px-4 text-center text-lg leading-snug text-bsr-gray-300 sm:px-10">
          <p>
            We are setting up your account{" "}
            <span className="font-bold">{user}.</span> We will be in touch via
            email soon!
          </p>
        </div>
      </div>
    </div>
  );
}

NoAccount.propTypes = {
  user: PropTypes.string,
};

export default NoAccount;
