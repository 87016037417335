import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import TextField from "./shared/TextField";
import Button from "./shared/Button";

import splashImage from "./assets/airlogic-splash.png";
import AppName from "./shared/AppName";

import {
  signInWithSso,
  selectSession,
  selectSessionStatus,
} from "./store/sessionSlice";

export default function Login() {
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();
  const [error, setError] = useState();

  const session = useSelector(selectSession);
  const sessionStatus = useSelector(selectSessionStatus);

  const query = new URLSearchParams(useLocation().search);
  let referrer = query.get("referrer");
  query.forEach((value, key) => {
    if (key !== "referrer") {
      referrer += "&" + key + "=" + value;
    }
  });

  const login = async function (e) {
    e.preventDefault();
    setEmailError(null);
    setError(null);

    // Validate fields
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    try {
      await signInWithSso(email);
    } catch (err) {
      setError(err.message);
    }
  };

  // user is logged in, redirect to their desired page or root route by default
  if (sessionStatus === "succeeded" && session) {
    if (referrer) return <Navigate to={referrer} />;

    return <Navigate to="/" />;
  }

  return (
    <div
      style={{ backgroundImage: `url(${splashImage})` }}
      className="flex min-h-screen flex-col justify-center bg-bsr-gray-100 py-12 sm:px-6 lg:px-8"
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex justify-center">
          <AppName size="lg" />
        </div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
          <h2 className="mb-8 text-center text-2xl font-bold text-bsr-gray-700">
            Sign in with SSO
          </h2>
          <form className="space-y-6" action="#" method="POST">
            <TextField
              name="email"
              label="Email"
              error={emailError}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(undefined);
              }}
            />

            {error && (
              <p className="mt-2 text-sm text-red-600" id={"error"}>
                {error}
              </p>
            )}
            <div>
              <Button
                type="primary"
                fullWidth
                disabled={sessionStatus === "loading"}
                onClick={(e) => login(e)}
              >
                Continue with SSO
              </Button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-white px-2 text-gray-500">Or</span>
              </div>
            </div>
          </div>
          <div className="mt-6">
            <div className="flex items-center justify-center">
              <div className="text-sm">
                <Link
                  to={"/login" + (referrer ? "?referrer=" + referrer : "")}
                  className="font-medium text-bsr-blue-700 hover:text-bsr-blue-600"
                >
                  Use your password instead
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
