import React from "react";
import { getD3ScaleForVis, formatData, isNullish } from "./utilities";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import centroid from "@turf/centroid";
import "./PortfolioMap.css";
import PortfolioLegend from "./EnhancedPortfolioLegend";
import XMarkSvg from "./assets/x-mark.svg";
import UpChevron from "./assets/up-chevron.svg";
import DownChevron from "./assets/down-chevron.svg";
import { isEqual } from "lodash";

class PortfolioMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];

    this.state = {
      markers: [],
      activeMarkers: [],
    };
  }

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");
    const { x, y, z } = this.props;

    if (this.mapRef.current) {
      const map = new Map(this.mapRef.current, {
        center: { lat: x || 37.4239163, lng: y || -122.0947209 },
        zoom: z || 17,
        mapId: process.env.REACT_APP_MAP_ID,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
      });

      map.addListener("idle", () => {
        const latLng = map.getCenter();
        const zoom = map.getZoom();

        this.props.searchParameters.set("x", latLng.lat());
        this.props.searchParameters.set("y", latLng.lng());
        this.props.searchParameters.set("z", zoom);

        this.props.setSearchParameters(this.props.searchParameters, {
          replace: true,
        });
      });

      map.addListener("bounds_changed", () => {
        var bounds = map.getBounds();
        const markers_in_viewport = [];
        for (var i = 0; i < this.markers.length; i++) {
          if (bounds.contains(this.markers[i].position)) {
            markers_in_viewport.push(this.markers[i]);
          }
        }
        this.setState({ markers: markers_in_viewport });
      });

      return map;
    }
  }

  async componentDidMount() {
    const { x, y, z } = this.props;
    const { AdvancedMarkerElement, PinElement } =
      await window.google.maps.importLibrary("marker");

    this.AdvancedMarkerElement = AdvancedMarkerElement;
    this.PinElement = PinElement;
    this.map = await this.initMap();
    if (!x || !y || !z) {
      this.zoomToAllSites();
    }
    this.constructSitesLayer(AdvancedMarkerElement, PinElement);
  }

  clearMarkers() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers(); // Clear the MarkerClusterer
      this.markerClusterer = null;
    }

    this.markers.forEach((marker) => marker.setMap(null)); // Clear the markers
    this.markers = [];
  }

  componentDidUpdate(prevProps) {
    // this.clearMarkers();
    if (!isEqual(prevProps.sites, this.props.sites)) {
      this.clearMarkers();
      this.constructSitesLayer(this.AdvancedMarkerElement, this.PinElement);
    }
  }

  zoomToAllSites() {
    try {
      var bounds = new window.google.maps.LatLngBounds();
      this.props.sites.forEach((site) => {
        const center = centroid(site.geometry).geometry;
        bounds.extend(
          new window.google.maps.LatLng(
            center.coordinates[1],
            center.coordinates[0]
          )
        );
      });

      if (this.props.sites.length > 1) {
        this.map.fitBounds(bounds, { top: 134 });
      } else {
        // when portfolio contains only 1 site we want a higher default zoom level
        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(10);
      }
    } catch (err) {
      console.error("unable to zoom to all sites bounds");
      console.error(err);
    }
  }

  calculateMarkerColor(siteId) {
    let color = "#9AA5B1";
    let minDelta = 100;
    let scale;

    for (const datasetName in this.props.observationsBySite[siteId]) {
      const observations = this.props.observationsBySite[siteId][datasetName];
      const definition = this.props.activeDatasets.find(
        (d) => d.name === datasetName
      );
      try {
        scale = getD3ScaleForVis(definition.metric.vis);
      } catch (err) {
        scale = null;
        console.warn(
          `failed to generate scale for this dataset: ${definition.id}`
        );
      }
      for (const observation of observations) {
        const graded_value =
          definition?.value_field && definition?.value_field !== "value"
            ? observation.metadata[definition?.value_field]
            : observation.value;
        if (!isNullish(graded_value) && scale) {
          const scaleColor = scale(graded_value);
          const palette = definition.metric.vis.range;
          const index = palette.indexOf(scaleColor);
          const delta = palette.length - index;
          // track how close we are to finding max palette color
          // increment if we get closer and update to that palette
          if (index !== -1 && delta < minDelta) {
            minDelta = delta;
            color = palette[index];
          }
        }
      }
    }
    return [color, minDelta];
  }

  addMarker(position, site) {
    const [color, minDelta] = this.calculateMarkerColor(site.id);
    const observations = this.props.observationsBySite[site.id];
    const flat_observations = Object.keys(observations).reduce((acc, cur) => {
      const copy = [...observations[cur]];
      const with_colors = copy.map((observation) => {
        const definition = this.props.activeDatasets.find(
          (d) => d.name === cur
        );
        const scale = getD3ScaleForVis(definition.metric.vis);
        const graded_value =
          definition?.value_field && definition?.value_field !== "value"
            ? observation.metadata[definition?.value_field]
            : observation.value;
        const color = scale(graded_value);
        // isolate to test certain sites
        // if (site.id === "P6Enu5VXUIaSHJrf8HOUJ") {
        //   console.log(`computing color for ${site.id}`);
        //   console.log({ definition, scale, color, observation, graded_value });
        // }
        return { ...observation, color, graded_value };
      });
      acc.push(...with_colors);
      return acc;
    }, []);

    const element = document.createElement("div");

    element.className = "text-white p-2 rounded-full";
    element.style.backgroundColor = color;
    element.innerHTML = `
    <div class="preview">
      <span>${site.name}</span>
    </div>
    <div class="details">
      <div class="text-center font-bold py-1">Recent Observations</div>
      ${
        flat_observations.length
          ? `
        <div id="observations-container-${
          site.id
        }" class="max-h-40 overflow-y-scroll">
        <table>
          <tr>
            <th class="sticky bg-white top-0"></th>
            <th class="sticky bg-white top-0">Date</th>
            <th class="sticky bg-white top-0">Value</th>
            <th class="sticky bg-white top-0">Dataset</th>
          </tr>
          ${flat_observations
            .sort((a, b) => {
              return a.datetime_str - b.datetime_str;
            })
            .filter((ob) => !isNullish(ob.value))
            .map((ob) => {
              const { value, uncertainty_display } = formatData(
                ob.graded_value,
                ob?.metadata?.uncertainty
              );
              const { name, units } = this.props.activeDatasets.find(
                (d) => d.id === ob.dataset_id
              );
              return `
                <tr>
                  <td><div class="rounded-full w-3 h-3" style="background-color:${
                    ob.color
                  }"></div></td>
                  <td>${ob.datetime_str}</td>
                  <td>
                    ${!isNullish(value) ? value : ""} 
                    ${!isNullish(units) ? units : ""} 
                    ${uncertainty_display ? `±${uncertainty_display}` : ""}
                  </td>
                  <td>${name}</td>
                </tr>
              `;
            })
            .join("")}
        </table>
        </div>
        `
          : `<div class="text-center text-bsr-gray-700">no measurements</div>`
      }
      ${
        flat_observations.length > 6
          ? `
          <div class="my-2 flex items-center justify-between rounded-sm">
            <div class="h-5 w-5" id="down-btn-${site.id}">
              <img src="${DownChevron}"></img>
            </div>
            <div class="h-5 w-5" id="up-btn-${site.id}">
              <img src="${UpChevron}"></img>
            </div>
          </div>`
          : ``
      }
      <div class="flex items-center mt-2 rounded-sm">
        <div id="close-btn-${
          site.id
        }" class="button-container h-7 w-7 mr-2 bg-bsr-gray-300 flex justify-center items-center rounded-md">
          <img src="${XMarkSvg}"></img>
        </div>
        <div id="button-container-${site.id}" 
             class="button-container grow" 
             style="color:white;background-color:${color}">
          <button id="button-${site.id}">Open In Rosetta</button>
        </div>
      </div>
    </div>
    `;

    const marker = new this.AdvancedMarkerElement({
      map: this.map,
      position,
      title: site.name,
      content: element,
    });

    marker.color = color;
    marker.priority = minDelta;
    marker.observations = observations;
    marker.siteId = site.id;

    marker.addListener("click", () => {
      try {
        if (!this.state.activeMarkers.find((m) => m.id === site.id)) {
          this.setState({ activeMarkers: [...this.state.activeMarkers, site] });
          this.centerCameraOnMarker(site);
          marker.zIndex = 1;
          marker.content.classList.add("active");
          marker.content.classList.remove("rounded-full");
          marker.content.classList.add("rounded-md");
        }

        // add btn listeners
        const rosettaLink = document.getElementById(
          `button-container-${site.id}`
        );
        rosettaLink.addEventListener("click", () => {
          let params = new URL(document.location).searchParams;
          params.delete("x");
          params.delete("y");
          params.delete("z");
          const queryString =
            params && params.toString() ? `?${params.toString()}` : "";
          this.props.navigate(`/map/${site.id}${queryString}`);
        });

        const upButton = document.getElementById(`up-btn-${site.id}`);
        const downButton = document.getElementById(`down-btn-${site.id}`);
        const closeButton = document.getElementById(`close-btn-${site.id}`);

        closeButton.addEventListener("click", (e) => {
          this.setState({
            activeMarkers: this.state.activeMarkers.filter(
              (m) => m.id !== site.id
            ),
          });
          marker.zIndex = null;
          marker.content.classList.remove("active");
          marker.content.classList.add("rounded-full");
          marker.content.classList.remove("rounded-md");
          // required to avoid bug where event propogates and opens the popup again
          e.stopPropagation();
        });

        downButton.addEventListener("click", () => {
          document.getElementById(
            `observations-container-${site.id}`
          ).scrollTop += 20;
        });

        upButton.addEventListener("click", () => {
          document.getElementById(
            `observations-container-${site.id}`
          ).scrollTop -= 20;
        });
      } catch (err) {
        console.log("failed to set up onclick handlers on marker");
        console.log(err);
      }
    });

    this.markers.push(marker);

    return marker;
  }

  constructSitesLayer() {
    this.markers = this.props.sites.map((site) => {
      const center = centroid(site.geometry).geometry.coordinates;
      return this.addMarker({ lat: center[1], lng: center[0] }, site);
    });

    this.markerClusterer = new MarkerClusterer({
      map: this.map,
      markers: this.markers,
      renderer: {
        render: ({ count, position, markers }, stats) => {
          let color = "#9AA5B1";
          let minDelta = 100;

          for (const marker of markers) {
            if (marker.priority < minDelta) {
              color = marker.color;
              minDelta = marker.priority;
            }
          }

          const svg = window.btoa(`
            <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
              <circle cx="120" cy="120" opacity=".6" r="70" />
              <circle cx="120" cy="120" opacity=".3" r="90" />
              <circle cx="120" cy="120" opacity=".2" r="110" />
              <circle cx="120" cy="120" opacity=".1" r="130" />
            </svg>`);
          // create marker using svg icon
          return new window.google.maps.Marker({
            position,
            icon: {
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new window.google.maps.Size(45, 45),
            },
            label: {
              text: String(count),
              color: "rgba(255,255,255,0.9)",
              fontSize: "12px",
            },
            // adjust zIndex to be above other markers
            zIndex: 1000 + count,
          });
        },
      },
    });
  }

  componentWillUnmount() {
    // hopefully JS garbage collection will free up memory once we set map to null.
    // TODO: keep an eye on memory usage and if we set up event listeners for this class component
    // be sure to un-register them here!
    this.map = null;
  }

  centerCameraOnMarker(site) {
    try {
      const center = centroid(site.geometry).geometry;
      const latLng = new window.google.maps.LatLng(
        center.coordinates[1],
        center.coordinates[0]
      );
      this.map.setCenter(latLng);
    } catch (err) {
      console.log("failed to zoom to active site");
      console.error(err);
    }
  }

  render() {
    return (
      <main className="full-height-minus-header-and-sub-header flex bg-bsr-gray-100">
        <div className="absolute right-8 top-24 z-50 flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-full bg-bsr-gray-600 p-3 text-white opacity-75 hover:opacity-100">
          <div className="text-xs">viewing</div>
          <div className="text-xl font-bold"> {this.state.markers.length}</div>
          <div className="text-xs">sites</div>
        </div>
        <div className="h-full w-full" ref={this.mapRef}></div>
        <PortfolioLegend />
      </main>
    );
  }
}

export default PortfolioMap;
