import AppName from "./AppName";

export default function Loading() {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-bsr-gray-200">
      <div className="flex h-24 w-24 animate-pulse items-center justify-center rounded-full">
        <AppName withName={false} size="lg" />
      </div>
    </div>
  );
}
