import { useSelector } from "react-redux";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";
import { ANSWERS_DATASET_MAP } from "../constants";
import { selectAccount } from "../store/sessionSlice";

const retainSiteIdModules = ["/trends", "/map", "/recent", "/estimates"];

export const NavLinkWithQuery = ({ children, to, ...props }) => {
  const account = useSelector(selectAccount);
  const [searchParameters] = useSearchParams();
  const { siteId } = useParams();
  searchParameters.delete("x");
  searchParameters.delete("y");
  searchParameters.delete("z");
  if (to === "/estimates") {
    searchParameters.delete("datasets");
    searchParameters.set(
      "timeframe",
      // default to this year
      JSURL.stringify(ANSWERS_DATASET_MAP[account].defaultTimeFrame)
    );
  }
  return (
    <NavLink
      to={`${to}${
        siteId && retainSiteIdModules.includes(to) ? `/${siteId}` : ""
      }${
        searchParameters.toString().length
          ? `?${searchParameters.toString()}`
          : ""
      }`}
      {...props}
    >
      {children}
    </NavLink>
  );
};
