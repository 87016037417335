import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSession } from "./store/sessionSlice";
import { Switch, Select } from "antd";
import dayjs from "./dayjs";
import {
  selectSites,
  selectUserSettings,
  updateSettingsDocument,
} from "./store/applicationSlice";
import { Link } from "react-router-dom";
import { Spin, Button } from "antd";
import {
  CheckIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { LogoutOutlined } from "@ant-design/icons";
import { ANSWERS_DATASET_MAP } from "./constants";

const AccountPage = () => {
  const session = useSelector(selectSession);
  const sites = useSelector(selectSites);
  const dispatch = useDispatch();
  const settings = useSelector(selectUserSettings);
  const COMING_SOON_DATASETS =
    ANSWERS_DATASET_MAP[session.account]?.comingSoonDatasets;
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 500);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const options = sites.map((site) => ({
    label: site.name,
    value: site.id,
  }));

  return (
    <div className="flex h-screen flex-col bg-gray-100 p-6">
      <div className="flex justify-between">
        <div className="mb-4 text-2xl font-semibold">Notification Settings</div>
        {settings.modified_ts && (
          <div className="flex items-center text-bsr-gray-300">
            <div className="mx-2">
              {settings?.updating ? (
                <Spin size="small" />
              ) : (
                <CheckIcon className="h-5 w-5 text-green-500" />
              )}
            </div>
            <div>
              last modified {dayjs(settings.modified_ts).from(currentTime)}
            </div>
          </div>
        )}
      </div>
      <div className="mb-6 flex items-center">
        <Switch
          loading={settings?.updating}
          checked={settings.notifications_enabled}
          onChange={(checked) =>
            dispatch(
              updateSettingsDocument({
                property: "notifications_enabled",
                value: checked,
                record: settings,
              })
            )
          }
        ></Switch>
        <span className="ml-3 text-lg">Allow notifications</span>
      </div>
      <div className="mb-6">
        <label className="mb-2 block text-gray-800">
          Notify <strong>{session.email}</strong> about emission detections and
          non-detections
        </label>
        <label className="mb-2 block text-gray-800">
          For the following sites
        </label>
        {settings.sites_subscription === "selected" &&
          settings.selected_site_ids.length === 0 && (
            <div className="mb-2 rounded-md bg-yellow-50 p-4">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <ExclamationTriangleIcon
                    aria-hidden="true"
                    className="h-5 w-5 text-yellow-400"
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm text-yellow-700">
                    <p>Select at least one site to receive notifications</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        <div className="rounded bg-white p-4 shadow">
          <label className="mb-2 flex items-center">
            <input
              type="radio"
              value="all"
              checked={settings.sites_subscription === "all"}
              disabled={!settings.notifications_enabled || settings?.updating}
              onChange={() =>
                dispatch(
                  updateSettingsDocument({
                    property: "sites_subscription",
                    value: "all",
                    record: settings,
                  })
                )
              }
              className="form-radio text-bsr-blue-500"
            />
            <span className="ml-2">All sites</span>
          </label>
          <label className="mb-2 flex items-center">
            <input
              type="radio"
              value="selected"
              disabled={!settings.notifications_enabled || settings?.updating}
              checked={settings.sites_subscription === "selected"}
              onChange={() =>
                dispatch(
                  updateSettingsDocument({
                    property: "sites_subscription",
                    value: "selected",
                    record: settings,
                  })
                )
              }
              className="form-radio text-bsr-blue-500"
            />
            <span className="ml-2">My selected sites</span>
          </label>
          <Select
            mode="multiple"
            allowClear
            style={{
              width: "100%",
              outline: "none",
              boxShadow: "none",
              borderColor: "initial",
            }}
            disabled={
              !settings.notifications_enabled ||
              settings.sites_subscription !== "selected" ||
              settings?.updating
            }
            placeholder="Please select a site"
            defaultValue={settings.selected_site_ids}
            // this allows us to search labels ( site names ) instead of values ( site ids )
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            value={settings.selected_site_ids}
            onChange={(sites) => {
              dispatch(
                updateSettingsDocument({
                  property: "selected_site_ids",
                  value: sites,
                  record: settings,
                })
              );
            }}
            options={options}
          />
        </div>
      </div>
      <div>
        <label className="mb-2 block text-gray-800">
          For the following data providers
        </label>
        {settings.dataset_ids.length === 0 && (
          <div className="mb-2 rounded-md bg-yellow-50 p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  aria-hidden="true"
                  className="h-5 w-5 text-yellow-400"
                />
              </div>
              <div className="ml-3">
                <div className="text-sm text-yellow-700">
                  <p>Select at least one provider to receive notifications</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="rounded bg-white p-4 shadow">
          <label className="mb-2 flex items-center">
            <input
              type="checkbox"
              checked={
                settings.dataset_ids.includes("carbon-mapper") &&
                settings.dataset_ids.includes("ghgsat")
              }
              disabled={!settings.notifications_enabled || settings?.updating}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: ["carbon-mapper", "ghgsat"],
                      record: settings,
                    })
                  );
                } else {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: [],
                      record: settings,
                    })
                  );
                }
              }}
              className="form-checkbox text-bsr-blue-500"
            />
            <span className="ml-2">All Providers</span>
          </label>
          <label className="mb-2 flex items-center">
            <input
              type="checkbox"
              checked={settings.dataset_ids.includes("carbon-mapper")}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: [...settings.dataset_ids, "carbon-mapper"],
                      record: settings,
                    })
                  );
                } else {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: settings.dataset_ids.filter(
                        (d) => d !== "carbon-mapper"
                      ),
                      record: settings,
                    })
                  );
                }
              }}
              disabled={!settings.notifications_enabled || settings?.updating}
              className="form-checkbox text-bsr-blue-500"
            />
            <span className="ml-2">CarbonMapper</span>
          </label>
          <label className="mb-2 flex items-center">
            <input
              type="checkbox"
              checked={settings.dataset_ids.includes("ghgsat")}
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: [...settings.dataset_ids, "ghgsat"],
                      record: settings,
                    })
                  );
                } else {
                  dispatch(
                    updateSettingsDocument({
                      property: "dataset_ids",
                      value: settings.dataset_ids.filter((d) => d !== "ghgsat"),
                      record: settings,
                    })
                  );
                }
              }}
              disabled={!settings.notifications_enabled || settings?.updating}
              className="form-checkbox text-bsr-blue-500"
            />
            <span className="ml-2">GHGSat</span>
          </label>
          {COMING_SOON_DATASETS?.length &&
            COMING_SOON_DATASETS.map((dataset) => {
              return (
                <label className="mb-2 flex items-center">
                  <input
                    type="checkbox"
                    checked={false}
                    disabled={true}
                    className="form-checkbox text-bsr-blue-500"
                  />
                  <span className="ml-2">
                    {dataset}
                    <span className="ml-2 inline-flex items-center rounded-md bg-bsr-blue-050 px-2 py-1 text-xs font-medium text-bsr-blue-700 ring-1 ring-inset ring-bsr-blue-700/10">
                      Coming Soon
                    </span>
                  </span>
                </label>
              );
            })}
        </div>
      </div>
      <div className="mt-auto">
        <Link to="/logout">
          <Button icon={<LogoutOutlined />} ghost type="primary">
            Log out
          </Button>
        </Link>
      </div>
    </div>
  );
};
export default AccountPage;
