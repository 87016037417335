import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateTimeSeries } from "./utilities";
import PropTypes from "prop-types";
import RosettaRow from "./RosettaRow";
import RosettaLegend from "./RosettaLegend";
import RosettaBreadcrumbs from "./RosettaBreadcrumbs";
import TimeTick from "./TimeTick";
// import { useDraggableContext, moveItems } from "react-tiny-dnd";
// import { useAutoScroll } from "react-tiny-autoscroll";
import { useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";
import dayjs from "./dayjs";
import {
  selectActiveTimePeriod,
  updateActiveTimeframe,
  selectActiveDatasets,
  selectShowPlumeImagery,
  toggleShowPlumeImagery,
  selectShowRosettaValues,
  toggleShowRosettaValues,
} from "./store/applicationSlice";
import AppName from "./shared/AppName";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  MinusIcon,
} from "@heroicons/react/24/solid";
import { Switch } from "antd";
import CsvDownloadButton from "./shared/CsvDownload";

function Rosetta({ rosettaOpen, setRosettaOpen }) {
  const dispatch = useDispatch();
  const [activeColumnIndex, setColumnActive] = useState(null);

  const containerRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const activeDatasets = useSelector(selectActiveDatasets);
  const showRosettaValues = useSelector(selectShowRosettaValues);
  const showPlumeImagery = useSelector(selectShowPlumeImagery);

  if (!activeDatasets.length || !activeTimePeriod) {
    return null;
  }

  const timeSeries = generateTimeSeries(
    activeTimePeriod.startDate,
    activeTimePeriod.endDate,
    activeTimePeriod.cadence
  );

  // height of rosetta row * number of rows + height of header h-12
  let rosettaHeight = "h-[135px]";
  if (activeDatasets?.length === 2) {
    rosettaHeight = "h-[175px]";
  } else if (activeDatasets?.length >= 3) {
    rosettaHeight = "h-[215px]";
  }
  // else if (activeDatasets?.length === 4) {
  //   rosettaHeight = "h-[265px]";
  // } else if (activeDatasets?.length >= 5) {
  //   rosettaHeight = "h-[325px]";
  // }

  if (rosettaOpen) {
    return (
      <div
        ref={containerRef}
        className="fixed bottom-5 left-24 right-4 z-50 mx-auto max-w-screen-2xl bg-white text-bsr-gray-900 shadow-2xl"
      >
        <div className={`${rosettaHeight} overflow-y-scroll`}>
          <div className="sticky top-0 z-10 flex h-12 items-center justify-between bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter">
            <div className="w-1/5 pl-4 text-lg font-bold">
              Rosetta<span>&trade;</span>
            </div>
            <div className="w-4/5">
              <div className="flex h-10">
                <div
                  onClick={() => {
                    let startDate;
                    let endDate;
                    if (activeTimePeriod.cadence === "daily") {
                      startDate = dayjs(activeTimePeriod.startDate).subtract(
                        30,
                        "day"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).subtract(
                        30,
                        "day"
                      );
                    } else if (activeTimePeriod.cadence === "monthly") {
                      startDate = dayjs(activeTimePeriod.startDate).subtract(
                        1,
                        "year"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).subtract(
                        1,
                        "year"
                      );
                    } else if (activeTimePeriod.cadence === "yearly") {
                      startDate = dayjs(activeTimePeriod.startDate).subtract(
                        2,
                        "year"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).subtract(
                        2,
                        "year"
                      );
                    }
                    const timeframe = {
                      startDate: startDate.toISOString(),
                      endDate: endDate.toISOString(),
                      cadence: activeTimePeriod.cadence,
                      name: `${startDate.format("MMM D, YYYY")} - ${endDate
                        .subtract(1, "second")
                        .format("MMM D, YYYY")}`,
                    };

                    let newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.set(
                      "timeframe",
                      JSURL.stringify(timeframe)
                    );
                    setSearchParams(newSearchParams);
                    dispatch(updateActiveTimeframe(timeframe));
                  }}
                  className="flex h-full w-5 cursor-pointer items-center  text-bsr-gray-500 hover:bg-bsr-gray-500 hover:text-white"
                >
                  <ChevronLeftIcon className="h-6 w-6" />
                </div>
                <div className="flex w-full">
                  {Object.keys(timeSeries).map((datetimeStr, i) => {
                    return (
                      <TimeTick
                        key={datetimeStr + "-" + i}
                        index={i}
                        firstIndex={0}
                        lastIndex={Object.keys(timeSeries).length - 1}
                        setColumnActive={setColumnActive}
                        activeColumnIndex={activeColumnIndex}
                        metrics={activeDatasets}
                        datetimeStr={datetimeStr}
                        cadence={activeTimePeriod.cadence}
                      />
                    );
                  })}
                </div>
                <div
                  onClick={() => {
                    let startDate;
                    let endDate;
                    if (activeTimePeriod.cadence === "daily") {
                      startDate = dayjs(activeTimePeriod.startDate).add(
                        30,
                        "day"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).add(30, "day");
                    } else if (activeTimePeriod.cadence === "monthly") {
                      startDate = dayjs(activeTimePeriod.startDate).add(
                        1,
                        "year"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).add(1, "year");
                    } else if (activeTimePeriod.cadence === "yearly") {
                      startDate = dayjs(activeTimePeriod.startDate).add(
                        2,
                        "year"
                      );
                      endDate = dayjs(activeTimePeriod.endDate).add(2, "year");
                    }
                    const timeframe = {
                      startDate: startDate.toISOString(),
                      endDate: endDate.toISOString(),
                      cadence: activeTimePeriod.cadence,
                      name: `${startDate.format("MMM D, YYYY")} - ${endDate
                        .subtract(1, "second")
                        .format("MMM D, YYYY")}`,
                    };
                    let newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.set(
                      "timeframe",
                      JSURL.stringify(timeframe)
                    );
                    setSearchParams(newSearchParams);
                    dispatch(updateActiveTimeframe(timeframe));
                  }}
                  className="flex h-full w-5 cursor-pointer items-center  text-bsr-gray-500 hover:bg-bsr-gray-500 hover:text-white"
                >
                  <ChevronRightIcon className="h-6 w-6" />
                </div>
              </div>
            </div>
          </div>
          <div>
            {activeDatasets &&
              activeDatasets.map((dataset, i) => {
                return (
                  <RosettaRow
                    key={dataset.id}
                    firstRow={i === 0}
                    lastRow={i === activeDatasets?.length - 1}
                    activeTimePeriod={activeTimePeriod}
                    showRosettaValues={showRosettaValues}
                    index={i}
                    dataset={dataset}
                    timeSeries={timeSeries}
                  />
                );
              })}
          </div>
        </div>
        <div className="mb-2 mr-1 flex items-center text-bsr-gray-500">
          <div className="flex w-1/5 justify-center">
            <RosettaLegend />
          </div>
          <div className="w-4/5">
            <div className="flex h-10 items-center justify-between">
              <div className="ml-5 flex items-center text-sm">
                <RosettaBreadcrumbs />
              </div>
              <div className="flex items-center space-x-2">
                <div className="flex items-center">
                  <Switch
                    checked={showRosettaValues}
                    onChange={() => {
                      dispatch(toggleShowRosettaValues());
                    }}
                  ></Switch>
                  <div className="ml-2 flex text-sm font-medium text-bsr-gray-500">
                    Show Values
                  </div>
                </div>
                <div className="flex items-center">
                  <Switch
                    checked={showPlumeImagery}
                    onChange={() => {
                      dispatch(toggleShowPlumeImagery());
                    }}
                  ></Switch>
                  <div className="ml-2 flex text-sm font-medium text-bsr-gray-500">
                    Show Plume Imagery
                  </div>
                </div>
                <CsvDownloadButton activeMetrics={activeDatasets} />
                <div
                  onClick={() => setRosettaOpen(false)}
                  className="flex h-full cursor-pointer items-center p-2 text-sm font-medium text-bsr-gray-500 hover:bg-bsr-gray-100"
                >
                  <MinusIcon className="mr-2 h-5 w-5" /> Minimize
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <CollapsedRosetta setRosettaOpen={setRosettaOpen} />;
  }
}

function CollapsedRosetta({ setRosettaOpen }) {
  return (
    <div
      className="text-md absolute bottom-3 left-1/2 z-20 -translate-x-1/2 transform cursor-pointer rounded-sm bg-white p-3 text-bsr-gray-600 shadow-md hover:text-bsr-gray-900 focus:outline-none focus:ring-2 focus:ring-bsr-blue-500 focus:ring-offset-2"
      onClick={() => {
        setRosettaOpen(true);
      }}
    >
      <div className="flex">
        <AppName size="sm" iconColor="#52606D" withName={false} />
        <div className="font-semibold">
          Rosetta<span>&trade;</span>
        </div>
      </div>
    </div>
  );
}

Rosetta.propTypes = {
  setRosettaOpen: PropTypes.func,
  site: PropTypes.any,
};

export default Rosetta;
