import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";
import { random, merge } from "lodash";

const constructTmsLayer = (url, options) => {
  const baseOptions = {
    id: `tile-${random(100)}`,
    data: url,
    opacity: 1,
    minZoom: 0,
    maxZoom: 21,
    tileSize: 256,
  };
  const mergedOptions = merge(baseOptions, options);
  return new TileLayer({
    ...mergedOptions,
    renderSubLayers: (props) => {
      const {
        bbox: { west, south, east, north },
      } = props.tile;
      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north],
      });
    },
  });
};

export default constructTmsLayer;
