import { useState, useEffect } from "react";
import { usePrevious } from "./usePrevious";
import { isEqual } from "lodash";

const calculateRange = (data, rowsPerPage) => {
  const range = [];
  const num = Math.ceil(data.length / rowsPerPage);
  for (let i = 1; i <= num; i++) {
    range.push(i);
  }
  return range;
};

const sliceData = (data, page, rowsPerPage) => {
  return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
};

const useTable = (data, page, rowsPerPage) => {
  const [tableRange, setTableRange] = useState([]);
  const [slice, setSlice] = useState([]);
  const previous = usePrevious(data);
  const previousPage = usePrevious(page);

  useEffect(() => {
    // if the data did not change and the page argument did not change then nothing happened
    // but we must return to avoid an infinite loop because of react
    if (isEqual(previous, data) && isEqual(previousPage, page)) return;
    const range = calculateRange(data, rowsPerPage);
    setTableRange([...range]);

    const slice = sliceData(data, page, rowsPerPage);
    setSlice([...slice]);
  }, [
    data,
    setTableRange,
    page,
    setSlice,
    rowsPerPage,
    previous,
    previousPage,
  ]);

  return { slice, range: tableRange };
};

export default useTable;
