import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { signOut } from "./store/sessionSlice";
import { useDispatch, useSelector } from "react-redux";
import { createNotification } from "./store/applicationSlice";

function Loading() {
  return <p>Spinner here...</p>;
}

export default function Logout() {
  const dispatch = useDispatch();
  const sessionStatus = useSelector((state) => state.session.status);

  useEffect(() => {
    dispatch(signOut()).then(() =>
      dispatch(
        createNotification({
          title: "You are now logged out.",
          description: "Thank you for visiting.",
        })
      )
    );
  });

  if (sessionStatus === "loading") return <Loading />;

  return <Navigate to="/" />;
}
