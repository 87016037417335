import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import DropdownButton from "./shared/DropdownButton";

import { selectActiveDatasets } from "./store/applicationSlice";
import DatasetsPanel from "./DatasetsPanel";

export default function DatasetsDropdown() {
  const activeDatasets = useSelector(selectActiveDatasets);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button>
            <DropdownButton
              selected={
                activeDatasets.length > 0 ? (
                  activeDatasets.map((d) => d.name).join(", ")
                ) : (
                  <span className="font-semibold text-bsr-gray-300">
                    Select datasets...
                  </span>
                )
              }
              open={open}
              label="Datasets"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-[-625px] z-60 transform px-4 sm:px-0">
              {({ close }) => <DatasetsPanel closePanel={close} />}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
