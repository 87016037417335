import { isNullish } from "../../utilities";

export default function PlumeValue({ observation, formattedData }) {
  if (isNullish(formattedData.value)) {
    const detections = observation.metadata.acquisitions.reduce((acc, acq) => {
      if (acq.lat && acq.lon && !isNullish(acq.emission_rate)) {
        acc++;
      }
      return acc;
    }, 0);
    return detections > 0 ? "No quantity published" : "No detects";
  } else {
    return (
      <>
        {formattedData.value}
        <span className={`ml-1 text-bsr-gray-500`}>
          {observation?.dataset?.metric?.units
            ? observation?.dataset?.metric?.units
            : ""}
        </span>
        <span className="text-sm text-bsr-gray-500">
          {formattedData.uncertainty_display
            ? " ±" + formattedData.uncertainty_display + "%"
            : ""}
        </span>
      </>
    );
  }
}
