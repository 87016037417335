const SensorHover = ({
  arrowRef,
  tooltipRef,
  getArrowProps,
  getTooltipProps,
  placement,
  sensor,
}) => (
  <div
    {...getTooltipProps({
      ref: tooltipRef,
      className: "tooltip-container",
      /* your props here */
    })}
  >
    <div
      {...getArrowProps({
        ref: arrowRef,
        className: "tooltip-arrow",
        "data-placement": placement,
        /* your props here */
      })}
    />
    <p>
      <span className="font-bold">{sensor.name}</span>
      <span className="text-sm capitalize">
        {sensor.type ? ` ${sensor.type}` : ""}
      </span>
    </p>
    {sensor.detection_limit && (
      <p className="text-sm">
        Reported detection limit: {sensor.detection_limit}
      </p>
    )}
    {/* {sensor.metrics && sensor.metrics.length > 0 && (
      <p className="text-sm capitalize">Metrics: {sensor.metrics.join(", ")}</p>
    )} */}
    {sensor.resolution && (
      <p className="text-sm capitalize">Resolution: {sensor.resolution}</p>
    )}
    {sensor.update_frequency && (
      <p className="text-sm capitalize">Frequency: {sensor.update_frequency}</p>
    )}
  </div>
);

export default SensorHover;
