import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/solid";

import centroid from "@turf/centroid";
import WKT from "wkt";
import { saveAs } from "file-saver";
import Button from "./shared/Button";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { AiOutlineAreaChart } from "react-icons/ai";
import { COLORS } from "./constants";
import {
  isNullish,
  sortFunction,
  safeToLowerCase,
  formatData,
  classNames,
} from "./utilities";

import {
  selectObservationsStatus,
  selectAggregatedObservationsBySite,
  selectAggregatedObservationsByMetricAndDatetimeString,
} from "./store/portfolioSlice";

import {
  selectActiveTimePeriod,
  selectSites,
  selectSelectedDatasets,
  selectActiveDatasets,
} from "./store/applicationSlice";

import TotalsChart from "./TotalsChart";
import MetricGroup from "./shared/MetricGroup";
import Loading from "./shared/Loading";

export default function PortfolioTrends() {
  const navigate = useNavigate();
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const activeDatasets = useSelector(selectActiveDatasets);
  const sites = useSelector(selectSites);
  const observationsStatus = useSelector(selectObservationsStatus);
  const selectedDatasets = useSelector(selectSelectedDatasets);
  const [searchParams] = useSearchParams();

  const aggregatedObservationsBySite = useSelector((state) =>
    selectAggregatedObservationsBySite(state)
  );
  const aggregatedObservationsByMetricAndDatetimeString = useSelector(
    selectAggregatedObservationsByMetricAndDatetimeString
  );

  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("id");
  const [sortAscending, setSortAscending] = useState(false);

  function reSort(field) {
    // only flip the sort order if the user clicked on the active field
    if (field === sortBy) setSortAscending(!sortAscending);
    else if (field !== sortBy) setSortBy(field);
  }

  function exportDataTable() {
    const startDate = activeTimePeriod.startDate;
    const endDate = activeTimePeriod.endDate;

    let rows = [];
    let csvHeaders = [
      "Name",
      "Start date",
      "End date",
      "Geometry",
      "Latitude",
      "Longitude",
    ];

    activeDatasets.forEach((aggregation) => {
      csvHeaders.push(aggregation.name);
    });

    rows.push(csvHeaders);

    results.forEach((site, siteIdx) => {
      let resultRow = [
        `"${site.name}"`,
        `"${startDate}"`,
        `"${endDate}"`,
        `"${WKT.stringify(site.geometry)}"`,
        centroid(site.geometry).geometry.coordinates[0],
        centroid(site.geometry).geometry.coordinates[1],
      ];

      activeDatasets.forEach((aggregation) => {
        if (isNullish(site?.[aggregation.name]?.total)) {
          resultRow.push("");
        } else {
          resultRow.push(site?.[aggregation.name]?.total);
        }
      });

      rows.push(resultRow);
    });

    var cBlob = new Blob([rows.join("\n")], { type: "text/csv" });
    saveAs(cBlob, `airlogic_portfolio_${startDate}_${endDate}.csv`);
  }

  if (observationsStatus === "idle" || observationsStatus === "loading") {
    return <Loading />;
  }

  // decide colors for all metrics ahead of time so they stay
  // the same as metrics are toggled on / off
  const colorHash = activeDatasets?.reduce((acc, cur, idx) => {
    acc[cur.name] = COLORS[idx];
    return acc;
  }, {});

  const tableRows = [];

  sites.forEach((site) => {
    const row = {
      ...site,
      ...aggregatedObservationsBySite?.[site.id],
    };
    tableRows.push(row);
  });

  const results = !searchTerm
    ? tableRows
        .slice()
        .sort((a, b) => sortFunction(a[sortBy], b[sortBy], sortAscending))
    : tableRows
        .filter(
          (site) =>
            safeToLowerCase(site.name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(site.parent_company_name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(site["function"]).includes(
              searchTerm.toLocaleLowerCase()
            )
        )
        .slice()
        .sort((a, b) => sortFunction(a[sortBy], b[sortBy], sortAscending));

  return (
    <main className="mt-8 min-h-screen max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
      <h2 className="mb-4 text-2xl font-bold leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
        Trends
      </h2>

      <div className="flex">
        <div className="mb-4 shadow">
          <MetricGroup multipleSelect />
        </div>
      </div>
      <div className="min-h-[225px] w-full overflow-hidden bg-white shadow">
        <div className="w-full px-4 py-5 sm:px-6">
          {observationsStatus === "succeeded" &&
            (selectedDatasets?.length === 0 ? (
              <div className="w-full grow border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-bsr-gray-200">
                <AiOutlineAreaChart className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
                  Click a metric above to add data to the chart
                </span>
              </div>
            ) : (
              <TotalsChart
                selectedDatasets={selectedDatasets}
                multiMetricMode={selectedDatasets.length > 1}
                activeTimePeriod={activeTimePeriod}
                colorHash={colorHash}
                aggregatedObservationsByMetricAndDatetimeString={
                  aggregatedObservationsByMetricAndDatetimeString
                }
              />
            ))}
        </div>
      </div>
      {/* <div className="mt-12 mb-2 pb-2">
        <h3 className="text-xl font-medium leading-6 text-bsr-gray-900">
          Data coverage
        </h3>
      </div>
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          {observationsStatus === "succeeded" &&
            (activeMetrics?.length === 0 ? (
              <div className="w-full grow rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-bsr-gray-200">
                <AiOutlineAreaChart className="mx-auto h-12 w-12 text-gray-400" />
                <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
                  Click a metric above to add data to the chart
                </span>
              </div>
            ) : (
              <CoverageChart
                activeMetrics={activeMetrics}
                allMetrics={allMetrics}
                multiMetricMode={activeMetrics.length > 1}
                activeTimePeriod={activeTimePeriod}
                colorHash={colorHash}
                aggregatedObservationsByMetricAndDatetimeString={
                  aggregatedObservationsByMetricAndDatetimeString
                }
              />
            ))}
        </div>
      </div> */}
      <div className="mb-2 mt-12 flex items-center pb-2">
        <div className="flex min-w-0 flex-1 items-center">
          <h3 className="text-xl font-medium leading-6 text-bsr-gray-900">
            Metrics by site
          </h3>
        </div>
        <div className="flex">
          <div className="mr-2">
            <Button
              type="white"
              className="relative ml-8 inline-flex items-center rounded border border-bsr-gray-200 bg-white px-8 py-2 text-sm font-medium text-bsr-gray-500 shadow-xl  hover:bg-bsr-gray-050 focus:z-10 focus:border-bsr-blue-500 focus:outline-none focus:ring-1 focus:ring-bsr-blue-500"
              onClick={exportDataTable}
            >
              <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
              Export
            </Button>
          </div>
          <div>
            <div className="absolute ml-2 mt-2">
              <FunnelIcon
                className="h-5 w-5 text-bsr-gray-500"
                aria-hidden="true"
              />
            </div>
            <div className="flex">
              <input
                type="text"
                name="search"
                id="search"
                className="block w-full border-bsr-gray-200 pl-10 text-bsr-gray-700 focus:border-bsr-blue-500 focus:ring-bsr-blue-500 sm:text-sm"
                placeholder="Filter sites by name..."
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          {results.length === 0 ? (
            <div className="text-center text-bsr-gray-700">
              <h3 className="text-xl font-extrabold">No results found </h3>
              <p className="mt-4">
                We can’t find anything with that term at the moment, try
                searching something else.
              </p>
            </div>
          ) : (
            <>
              <div className="flex flex-col">
                <div className="-my-2 sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                    <div className="">
                      <table
                        className="min-w-full border-separate"
                        style={{ borderSpacing: 0 }}
                      >
                        <thead className="">
                          <tr>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 w-auto cursor-pointer border-b border-bsr-gray-200 bg-white bg-opacity-75 px-6 py-3 text-left text-xs font-bold tracking-wider text-bsr-gray-900 backdrop-blur backdrop-filter"
                            >
                              <div className="inline-flex">
                                <p>Rank</p>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 w-auto cursor-pointer border-b border-bsr-gray-200 bg-white bg-opacity-75 px-6 py-3 text-left text-xs font-bold tracking-wider text-bsr-gray-900 backdrop-blur backdrop-filter"
                              onClick={(e) => {
                                reSort("name");
                              }}
                            >
                              <div className="inline-flex">
                                <p>Name</p>
                                {sortBy === "name" &&
                                  (sortAscending ? (
                                    <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-bsr-gray-500" />
                                  ) : (
                                    <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-bsr-gray-500" />
                                  ))}
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="sticky top-0 z-10 w-auto cursor-pointer border-b border-bsr-gray-200 bg-white bg-opacity-75 px-6 py-3 text-left text-xs font-bold tracking-wider text-bsr-gray-900 backdrop-blur backdrop-filter"
                            >
                              <div className="inline-flex">
                                <p>Site ID</p>
                              </div>
                            </th>
                            {activeDatasets.map((aggregation, index) => (
                              <th
                                scope="col"
                                key={`th-${aggregation.name}`}
                                className="sticky top-0 z-10 w-auto cursor-pointer border-b border-bsr-gray-200 bg-white bg-opacity-75 px-6 py-3 text-right text-xs font-bold tracking-wider text-bsr-gray-900 backdrop-blur backdrop-filter"
                                onClick={() => {
                                  reSort(aggregation.name);
                                }}
                              >
                                <div
                                  className={classNames(
                                    sortBy === aggregation.name
                                      ? "flex items-center"
                                      : ""
                                  )}
                                >
                                  <div>
                                    <div>{aggregation.name}</div>
                                    <div className="font-normal text-bsr-gray-500">
                                      {aggregation.units
                                        ? aggregation.units
                                        : ""}
                                    </div>
                                  </div>
                                  {sortBy === aggregation.name &&
                                    (sortAscending ? (
                                      <BarsArrowUpIcon className="ml-1 h-4 w-4 font-bold text-bsr-gray-500" />
                                    ) : (
                                      <BarsArrowDownIcon className="ml-1 h-4 w-4 font-bold text-bsr-gray-500" />
                                    ))}
                                </div>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-100 text-sm">
                          {results.map((site, siteIdx) => (
                            <tr
                              key={`site-${siteIdx}-${site.id}`}
                              className="cursor-pointer bg-white hover:bg-bsr-gray-050"
                              onClick={() => {
                                navigate({
                                  pathname: `/map/${site.id}`,
                                  search: searchParams.toString(),
                                });
                              }}
                            >
                              <td className="px-6 py-4">
                                <div className="text-md text-bsr-gray-900">
                                  {siteIdx + 1}
                                </div>
                              </td>
                              <td className="max-w-[250px] truncate px-6 py-4">
                                <div className="text-md truncate font-semibold text-bsr-gray-900">
                                  {site.name}
                                </div>
                              </td>
                              <td className="px-6 py-4">
                                <div className="text-md font-semibold text-bsr-gray-900">
                                  {site?.alternateSiteId}
                                </div>
                              </td>
                              {activeDatasets.map((aggregation, index) => {
                                const d = formatData(
                                  site?.[aggregation.name]?.total,
                                  site?.[aggregation.name]?.total_uncertainty
                                );
                                return (
                                  <td
                                    key={`td-${aggregation.name}`}
                                    className="text-md whitespace-nowrap px-6 py-4 text-right text-bsr-gray-900"
                                  >
                                    {!isNullish(d.value) ? (
                                      <span>
                                        {d.value + " "}
                                        <span className="text-xs">
                                          {d.uncertainty_display
                                            ? `±${d.uncertainty_display}%`
                                            : ""}
                                        </span>
                                      </span>
                                    ) : (
                                      "--"
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}
