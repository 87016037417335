import { useSelector } from "react-redux";
import { selectActiveTimePeriod } from "../../store/applicationSlice";
import { formatDate, isNullish } from "../../utilities";
import "react-popper-tooltip/dist/styles.css";

export default function PathSensorHover({
  dataset,
  observation,
  formattedData,
  stoneColor,
  setTooltipRef,
  getTooltipProps,
  getArrowProps,
  visible,
}) {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  if (visible) {
    return (
      <div
        ref={setTooltipRef}
        {...getTooltipProps({
          className: "tooltip-container",
        })}
      >
        <div {...getArrowProps({ className: "tooltip-arrow" })}></div>
        <div className="my-1 text-sm font-semibold text-bsr-gray-700">
          {formatDate(observation.datetime_str, {
            cadence: activeTimePeriod.cadence,
          })}
        </div>
        <div style={{ color: stoneColor }} className="text-lg">
          {isNullish(formattedData.value) ? "n/a" : formattedData.value}
          <span className={`ml-1 text-bsr-gray-500`}>
            {dataset?.metric?.units}
          </span>
          <span className="text-sm text-bsr-gray-500">
            {formattedData.uncertainty_display
              ? " ±" + formattedData.uncertainty_display + "%"
              : ""}
          </span>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
