import PlumeValue from "./PlumeValue";
import FallbackValue from "./FallbackValue";

const componentByType = {
  PlumeLayer: PlumeValue,
};

const ObservationValue = ({ type, ...rest }) => {
  const SelectedComponent = componentByType[type];
  if (SelectedComponent) {
    // console.log("rendering v2 tooltip");
    return <SelectedComponent {...rest} />;
  } else {
    // console.log("rendering v2 fallback tooltip");
    return <FallbackValue {...rest} />;
  }
};

export default ObservationValue;
