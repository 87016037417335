import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  selectObservationsChronologically,
  selectObservationsStatus,
} from "./store/portfolioSlice";

import MeasurementsTable from "./shared/MeasurementsTable";
import { selectActiveTimePeriod, selectSites } from "./store/applicationSlice";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import Loading from "./shared/Loading";

export default function Recent() {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const observationsStatus = useSelector(selectObservationsStatus);
  const sites = useSelector(selectSites);
  const siteIds = sites.map((s) => s.id);

  let observations = useSelector(selectObservationsChronologically);

  const { siteId } = useParams();

  if (siteId) {
    observations = observations.filter((o) => o.site_id === siteId);
  } else {
    observations = observations.filter((o) => siteIds.includes(o.site_id));
  }

  if (observationsStatus === "loading") {
    return <Loading />;
  }

  return (
    <main className="mt-8 min-h-screen max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
      <h2 className="mb-5 inline-flex items-center text-2xl leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
        <span className="font-bold">Recent measurements</span>
        <ChevronRightIcon
          className="h-7 w-7 flex-shrink-0"
          aria-hidden="true"
        />
        {activeTimePeriod.name && activeTimePeriod.name.replace("Custom", "")}
      </h2>
      {observations.length ? (
        <MeasurementsTable data={observations} />
      ) : (
        <div className="mt-4 flex w-full items-center justify-center rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 py-5 text-center hover:border-gray-300">
          <p className="block text-sm font-medium text-bsr-gray-700">
            No data available. Select another dataset, site, and/or time period.
          </p>
        </div>
      )}
    </main>
  );
}
