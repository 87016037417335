export default function ComposableSubNav({ children }) {
  return (
    <div className="sticky top-0 z-90 bg-white drop-shadow-md">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="hidden md:block">
              {/* NOTE: leading-3 removes extra pixels from line-height. I don't know why line height impacts the overall height of popover div */}
              <div className="flex space-x-3 leading-3">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
