import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { AiOutlineAreaChart } from "react-icons/ai";
import { COLORS } from "./constants";

import {
  selectAggregatedObservationsByMetricAndDatetimeString,
  selectObservationsStatus,
  fetchObservations,
} from "./store/siteSlice";

import {
  selectActiveTimePeriod,
  selectActiveDatasets,
  selectSelectedDatasets,
  selectSiteById,
} from "./store/applicationSlice";

import TotalsChart from "./TotalsChart";
import MetricGroup from "./shared/MetricGroup";

import { useDispatch } from "react-redux";
import { LinkWithQuery } from "./LinkWithQuery";
import Loading from "./shared/Loading";

export default function SiteTrends() {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const activeDatasets = useSelector(selectActiveDatasets);
  const selectedDatasets = useSelector(selectSelectedDatasets);
  const observationsStatus = useSelector(selectObservationsStatus);
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  const site = useSelector((state) => selectSiteById(state, siteId));

  // decide colors for all metrics ahead of time so they stay
  // the same as metrics are toggled on / off
  const colorHash = activeDatasets.reduce((acc, cur, idx) => {
    acc[cur.name] = COLORS[idx];
    return acc;
  }, {});

  const aggregatedObservationsByMetricAndDatetimeString = useSelector((state) =>
    selectAggregatedObservationsByMetricAndDatetimeString(
      state,
      activeDatasets,
      siteId
    )
  );

  useEffect(() => {
    console.log({ activeTimePeriod, activeDatasets, siteId });
    if (!siteId || !activeTimePeriod || !activeDatasets.length) return;
    if (observationsStatus === "idle") {
      dispatch(fetchObservations({ activeTimePeriod, activeDatasets, siteId }));
    }
  }, [dispatch, activeTimePeriod, activeDatasets, observationsStatus, siteId]);

  if (!siteId || !activeDatasets.length || !activeTimePeriod) {
    return <Loading />;
  }

  return (
    <>
      <main className="mt-8 min-h-screen max-w-screen-2xl px-4 pb-12 sm:px-6 lg:px-8">
        <nav
          className="mb-4 flex text-3xl text-bsr-gray-900"
          aria-label="Breadcrumb"
        >
          <ol className="flex items-center">
            <li>
              <div className="flex items-center">
                <LinkWithQuery
                  className="flex items-center hover:text-gray-500"
                  to="/trends"
                >
                  <h2 className="text-2xl font-bold leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
                    Trends
                  </h2>
                </LinkWithQuery>
              </div>
            </li>
            <li key={site.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-7 w-7 flex-shrink-0"
                  aria-hidden="true"
                />
                <h2 className="eading-7 text-2xl text-bsr-gray-700 sm:truncate sm:text-3xl">
                  {site.name}
                </h2>
              </div>
            </li>
          </ol>
        </nav>
        <div className="flex">
          <div className="mb-4 shadow">
            <MetricGroup multipleSelect />
          </div>
        </div>

        <div className="mt-4 min-h-[225px] overflow-hidden bg-white shadow">
          <div className="px-4 py-5 sm:px-6">
            {observationsStatus === "succeeded" &&
              (selectedDatasets?.length === 0 ? (
                <div
                  type="button"
                  className="relative block h-full w-full rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-gray-300"
                >
                  <AiOutlineAreaChart className="mx-auto h-12 w-12 text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
                    Click a metric above to add data to the chart
                  </span>
                </div>
              ) : (
                <TotalsChart
                  selectedDatasets={selectedDatasets}
                  multiMetricMode={selectedDatasets.length > 1}
                  activeTimePeriod={activeTimePeriod}
                  colorHash={colorHash}
                  aggregatedObservationsByMetricAndDatetimeString={
                    aggregatedObservationsByMetricAndDatetimeString
                  }
                />
              ))}
          </div>
        </div>

        {/* <div className="mt-12 mb-2 pb-2">
          <h3 className="text-xl font-medium leading-6 text-bsr-gray-800">
            Data coverage
          </h3>
        </div>
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            {observationsStatus === "succeeded" &&
              (activeMetrics?.length === 0 ? (
                <div className="w-full grow rounded-lg border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-gray-300">
                  <AiOutlineAreaChart className="mx-auto h-12 w-12 text-gray-400" />
                  <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
                    Click a metric above to add data to the chart
                  </span>
                </div>
              ) : (
                <CoverageChart
                  activeMetrics={activeMetrics}
                  activeDatasets={activeDatasets}
                  multiMetricMode={activeMetrics.length > 1}
                  activeTimePeriod={activeTimePeriod}
                  colorHash={colorHash}
                  aggregatedObservationsByMetricAndDatetimeString={
                    aggregatedObservationsByMetricAndDatetimeString
                  }
                  hideSitesReportingToggle={true}
                />
              ))}
          </div>
                </div> */}
      </main>
    </>
  );
}
