import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AiOutlineAreaChart } from "react-icons/ai";

import {
  selectSiteById,
  selectActiveObservations,
  selectActiveSensor,
  selectActiveContextLayers,
  clearActiveSensor,
  selectActiveDatasets,
  selectContextLayers,
  selectOpticalLayers,
  selectActiveOpticalLayer,
  createNotification,
  updateActiveObservations,
} from "./store/applicationSlice";

// import Legend from "../shared/map/Legend";
import SensorChart from "./SensorChart";
import GroundSensorLayerChart from "./GroundSensorLayerChart";
import ModalWithHeader from "./shared/ModalWithHeader";
import SiteMap from "./SiteMap";
import Rosetta from "./Rosetta";
import LegendManager from "./LegendManager";

function SiteMapWrapper() {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const mapLayerOpacity = useSelector((state) => state.app.mapLayerOpacity);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const activeObservations = useSelector(selectActiveObservations) || [];
  const activeContextLayers = useSelector(selectActiveContextLayers);
  const contextLayers = useSelector(selectContextLayers);
  const opticalLayers = useSelector(selectOpticalLayers);
  const activeOpticalLayer = useSelector(selectActiveOpticalLayer);
  const activeDatasets = useSelector(selectActiveDatasets);
  const activeSensor = useSelector((state) => state.app.activeSensor);
  const showPlumeImagery = useSelector((state) => state.app.showPlumeImagery);
  const opticalTokens = useSelector((state) => state.app.opticalTokens);
  const site = useSelector((state) => selectSiteById(state, siteId));

  let [rosettaOpen, setRosettaOpen] = useState(true);
  const [showLatLng, setShowLatLng] = useState(false);
  const [contextModalOpen, toggleContextModal] = useState(false);
  const [opticalModalOpen, toggleOpticalModal] = useState(false);

  if (activeDatasets.length === 0) {
    return (
      <div className="m-3 flex h-full grow items-center justify-center border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-bsr-gray-200">
        <div>
          <AiOutlineAreaChart className="mx-auto h-20 w-20 text-gray-400" />
          <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
            Activate a dataset using the datasets dropdown above to get started
          </span>
        </div>
      </div>
    );
  }

  return (
    <>
      <SiteMap
        x={parseFloat(searchParameters.get("x"))}
        y={parseFloat(searchParameters.get("y"))}
        z={parseFloat(searchParameters.get("z"))}
        searchParams={searchParameters}
        activeObservations={activeObservations}
        contextLayers={contextLayers}
        activeContextLayers={activeContextLayers}
        opticalLayers={opticalLayers}
        activeOpticalLayer={activeOpticalLayer}
        selectActiveSensor={(sensor) => dispatch(selectActiveSensor(sensor))}
        setSearchParameters={setSearchParameters}
        searchParameters={searchParameters}
        toggleContextModal={() => toggleContextModal((open) => !open)}
        contextModalOpen={contextModalOpen}
        opticalModalOpen={opticalModalOpen}
        toggleOpticalModal={() => toggleOpticalModal((open) => !open)}
        opticalTokens={opticalTokens}
        showPlumeImagery={showPlumeImagery}
        mapLayerOpacity={mapLayerOpacity}
        showLatLng={showLatLng}
        setShowLatLng={setShowLatLng}
        createNotification={(notification) =>
          dispatch(createNotification(notification))
        }
        site={site}
        paramsSiteId={siteId}
      />
      {activeObservations && activeObservations.length > 0 && (
        <LegendManager
          activeObservations={activeObservations}
          numberOfRosettaMetrics={activeDatasets.length}
          rosettaOpen={rosettaOpen}
        />
      )}
      {activeSensor && (
        <ModalWithHeader
          open
          width="w-2/3"
          overlayZIndex={"z-80"}
          headerTitle={`Sensor Detail: ${activeSensor.name}`}
          onClose={() => {
            const filtered = activeObservations.filter(
              (o) => o.id !== activeSensor.activeObservation.id
            );
            dispatch(clearActiveSensor());
            dispatch(updateActiveObservations(filtered));
          }}
        >
          {activeSensor.layerType === "GroundSensorLayer" ? (
            <GroundSensorLayerChart activeSensor={activeSensor} />
          ) : (
            <SensorChart activeSensor={activeSensor} />
          )}
        </ModalWithHeader>
      )}
      <Rosetta
        rosettaOpen={rosettaOpen}
        setRosettaOpen={setRosettaOpen}
        site={site}
      />
    </>
  );
}

export default SiteMapWrapper;
