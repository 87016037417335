import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "./shared/firebase";
import { selectAccount } from "./store/sessionSlice";
import {
  selectActiveTimePeriod,
  selectActiveDatasets,
} from "./store/applicationSlice";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { timeFrames } from "./constants";
import { translateDatasetToActiveTimePeriod } from "./utilities";
import * as JSURL from "jsurl";

function Report({ report }) {
  return (
    <div className="flex flex-col justify-between overflow-hidden rounded bg-white shadow-lg">
      <div className="px-6 py-4">
        <div className="mb-2 text-2xl font-bold text-bsr-gray-800">
          {report.name}
        </div>
        <div className="mb-2 text-bsr-gray-600">{report.publisher}</div>
        <p className="text-base text-gray-700">{report.description}</p>
      </div>
      <div className="px-6 pb-2 pt-4">
        {report.tags.map((tag) => {
          return (
            <span
              key={tag}
              className="mb-2 mr-2 inline-block rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700"
            >
              #{tag}
            </span>
          );
        })}
      </div>
      <Link to={report.href} target="_blank" rel="noopener noreferrer">
        <div className="flex cursor-pointer justify-end">
          <div className="h-10 w-10 p-2">
            <ArrowTopRightOnSquareIcon />
          </div>
        </div>
      </Link>
    </div>
  );
}

export default function Reports() {
  const [, setSearchParams] = useSearchParams();
  const account = useSelector(selectAccount);
  const [reports, setReports] = useState([]);
  const [status, setStatus] = useState("loading");
  const activeDatasets = useSelector(selectActiveDatasets);
  const { startDate, endDate, name } = useSelector(selectActiveTimePeriod);

  console.log({ reports });

  // switch time period to all time
  useEffect(() => {
    const allTime = timeFrames[timeFrames.length - 1];
    if (name === allTime.name) return;
    const newDatasetIds = activeDatasets.map((d) => {
      return translateDatasetToActiveTimePeriod(d.id, allTime.cadence);
    });
    setSearchParams((sp) => {
      sp.set("datasets", JSURL.stringify(newDatasetIds));
      sp.set("timeframe", JSURL.stringify(allTime));
      return sp;
    });
  }, [name, activeDatasets, setSearchParams]);

  useEffect(() => {
    function fetchReports() {
      db.collection(`/accounts/${account}/reports`)
        .where("published", ">=", new Date(startDate))
        .where("published", "<", new Date(endDate))
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => doc.data());
          setReports(data);
          setStatus("success");
        })
        .catch((err) => {
          console.log(err);
          setReports([]);
          setStatus("failed");
        });
    }
    fetchReports();
  }, [account, startDate, endDate]);

  return (
    <main className="min-h-screen max-w-screen-2xl bg-bsr-gray-100 px-4 py-8 sm:px-6 lg:px-8">
      <h2 className="mb-4 text-2xl font-bold leading-7 text-bsr-gray-700 sm:truncate sm:text-3xl">
        Reports
      </h2>
      <div className="grid grid-cols-2 gap-2 xl:grid-cols-3 xl:gap-4">
        {(() => {
          if (reports.length > 0 && status === "success") {
            return reports.map((report, idx) => {
              return (
                <Report report={report} key={`report-${idx}-${report.href}`} />
              );
            });
          } else if (reports.length === 0 && status === "success") {
            return (
              <div className="flex justify-start text-bsr-gray-300">
                <div className="mb-2 text-2xl font-bold">No reports found</div>
              </div>
            );
          } else {
            return (
              <div className="flex justify-start text-bsr-gray-300">
                <div className="mb-2 text-2xl font-bold">Loading...</div>
              </div>
            );
          }
        })()}
      </div>
    </main>
  );
}
