import { useSelector } from "react-redux";
import Tooltip from "./shared/Tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { selectObservationsByDatasetId } from "./store/siteSlice";
import Observation from "./Observation";

export default function RosettaRow({
  firstRow,
  lastRow,
  dataset,
  index,
  timeSeries,
  showRosettaValues,
}) {
  let observations = useSelector((state) =>
    selectObservationsByDatasetId(state, dataset.id)
  );

  return (
    <div key={dataset.id + index} className="mb-2.5 flex h-10 w-full text-xs">
      <div className="w-1/5">
        <div className="flex h-full flex-1 items-center">
          <div className="flex min-w-[150px] max-w-[250px] flex-grow flex-col pl-3">
            <div className="truncate text-sm">{dataset.name}</div>
            {dataset.units && (
              <div className="truncate text-xs text-bsr-gray-300">
                {dataset.units}
              </div>
            )}
          </div>
          {dataset.description && (
            <div className="ml-1 self-start">
              <Tooltip
                position={firstRow ? "bottom" : "top"}
                width={300}
                content={dataset.description}
              >
                <InformationCircleIcon className="h-4 w-4 text-bsr-gray-300" />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="flex w-4/5 px-5">
        {Object.keys(timeSeries).map((datetimeStr, i) => {
          return (
            <Observation
              key={dataset.id + "___" + datetimeStr}
              singleRow={lastRow && index === 0}
              observation={observations?.[datetimeStr]}
              showRosettaValues={showRosettaValues}
              dataset={dataset}
              index={i}
            />
          );
        })}
      </div>
    </div>
  );
}
