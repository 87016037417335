import dayjs from "./dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  updateActiveTimeframe,
  selectActiveDatasets,
} from "./store/applicationSlice";
import {
  classNames,
  datetimeStrToBounds,
  translateDatasetToActiveTimePeriod,
} from "./utilities";
import { useSearchParams } from "react-router-dom";
import * as JSURL from "jsurl";

export default function TimeTick({
  datetimeStr,
  cadence,
  index,
  firstIndex,
  lastIndex,
}) {
  const dispatch = useDispatch();
  const activeDatasets = useSelector(selectActiveDatasets);
  const [searchParams, setSearchParams] = useSearchParams();

  const time = dayjs(datetimeStr);
  let formatted = null;
  if (cadence === "yearly") {
    formatted = time.format("YYYY");
  } else if (cadence === "monthly") {
    if (
      time.format("MMM") === "Jan" ||
      index === firstIndex ||
      index === lastIndex
    ) {
      formatted = time.format("MMM YYYY");
    } else {
      formatted = time.format("MMM");
    }
  } else {
    if (
      time.format("DD") === "01" ||
      index === firstIndex ||
      index === lastIndex
    ) {
      formatted = time.format("MMM DD");
    } else {
      formatted = time.format("DD");
    }
  }

  return (
    <div
      onClick={() => {
        if (cadence !== "daily") {
          const [startDate, endDate] = datetimeStrToBounds(
            datetimeStr,
            cadence
          );
          const newCadence = cadence === "yearly" ? "monthly" : "daily";
          const customTimeframe = {
            name: `${startDate.format("MMM D, YYYY")} - ${endDate
              .subtract(1, "second")
              .format("MMM D, YYYY")}`,
            cadence: newCadence,
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
          };
          let newSearchParams = new URLSearchParams(searchParams);
          const newDatasetIds = activeDatasets.map((d) => {
            return translateDatasetToActiveTimePeriod(d.id, newCadence);
          });
          newSearchParams.set("datasets", JSURL.stringify(newDatasetIds));
          newSearchParams.set("timeframe", JSURL.stringify(customTimeframe));
          setSearchParams(newSearchParams);
          dispatch(updateActiveTimeframe(customTimeframe));
        }
      }}
      className={classNames(
        cadence !== "daily" ? "cursor-pointer hover:bg-bsr-gray-100" : "",
        "mx-0.5 flex w-full items-center justify-center text-center text-sm font-bold uppercase first:ml-0 last:mr-0"
      )}
      title="drill down to this time period"
    >
      <div>{formatted}</div>
    </div>
  );
}
