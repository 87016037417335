import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import dayjs from "../dayjs";
import { fetchAccountResources } from "./applicationSlice";

import { isNullish, findChangePercentage } from "../utilities";

import {
  getObservations,
  aggregateObservationsByMetric,
} from "../services/Api";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchObservations = createAsyncThunk(
  "site/fetchObservations",
  async ({ siteId, activeTimePeriod, activeDatasetIds }, { getState }) => {
    const {
      app: { datasets },
    } = getState();
    const activeDatasets = datasets.filter((d) =>
      activeDatasetIds.includes(d.id)
    );
    const coverageDatasets = activeDatasets.reduce((acc, cur) => {
      if (cur?.data_coverage?.dataset_id) {
        acc.push(cur.data_coverage.dataset_id);
      }
      return acc;
    }, []);

    console.log("get obs");

    return getObservations({
      siteIds: [siteId],
      datasetIds: [...activeDatasets.map((d) => d.id), ...coverageDatasets],
      cadence: activeTimePeriod.cadence,
      startDate: dayjs(activeTimePeriod.startDate).toDate(),
      endDate: dayjs(activeTimePeriod.endDate).toDate(),
    });
  }
);

export const siteSlice = createSlice({
  name: "site",
  initialState,
  extraReducers: {
    [fetchAccountResources.fulfilled]: (state, action) => {
      state.activeSummary = null;
      // when active summaries are fetched default to first metric
      state.activeMetrics = [];
    },
    [fetchObservations.fulfilled]: (state, action) => {
      // const aggregations = action.meta.arg.activeSummary.aggregations;
      state.status = "succeeded";
      state.data = action.payload.sort((a, b) => {
        if (a.datetime_start < b.datetime_start) {
          return -1;
        }
        if (a.datetime_start > b.datetime_start) {
          return 1;
        }
        return 0;
      });
    },
    [fetchObservations.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [fetchObservations.failed]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { refetch } = siteSlice.actions;

export const selectAggregatedObservationsByMetric = (state, siteId) => {
  let data = state.site.data;

  if (!data) {
    return null;
  }

  if (siteId) {
    data = state.site.data.filter(
      (observation) => observation.site_id === siteId
    );
  }

  const aggregated = {};

  state.app.activeDatasets.forEach((dataset) => {
    // some datasets store coverage information in separate datasets.
    //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
    const dataCoverageDataset = dataset?.data_coverage
      ? state.app.datasets.find(
          (d) => d.id === dataset?.data_coverage?.dataset_id
        )
      : null;

    const coverageObservations = dataCoverageDataset
      ? state.site.data.filter((d) => d.dataset_id === dataCoverageDataset.id)
      : null;

    const a = aggregateObservationsByMetric({
      observations: data.filter((d) => d.dataset_id === dataset?.id),
      coverageObservations,
      metric: dataset.value_field,
      aggregation: dataset.agg,
      name: dataset.name,
    });
    aggregated[dataset.name] = a;
  });

  return aggregated;
};

export const selectAggregatedObservationsByMetricAndDatetimeString = (
  state,
  datasets,
  siteId
) => {
  let data = state.site.data;

  if (!state.site.data) {
    return null;
  }

  if (siteId) {
    data = state.site.data.filter(
      (observation) => observation.site_id === siteId
    );
  }

  const aggregated = {};

  const uniqueDatetimeStrings = new Set();
  data.forEach((observation) => {
    uniqueDatetimeStrings.add(observation.datetime_str);
  });
  datasets.forEach((dataset) => {
    // some datasets store coverage information in separate datasets.
    //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
    const dataCoverageDataset = dataset?.data_coverage
      ? state.app.datasets.find(
          (d) => d.id === dataset?.data_coverage?.dataset_id
        )
      : null;
    const coverageObservations = dataCoverageDataset
      ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
      : null;
    aggregated[dataset.name] = {};
    uniqueDatetimeStrings.forEach((datetime_str) => {
      const a = aggregateObservationsByMetric({
        observations: data.filter(
          (d) => d.dataset_id === dataset?.id && d.datetime_str === datetime_str
        ),
        coverageObservations: coverageObservations?.filter(
          (d) => d.datetime_str === datetime_str
        ),
        metric: dataset.value_field,
        aggregation: dataset.agg,
        name: dataset.name,
      });
      aggregated[dataset.name][datetime_str] = a;
    });
  });

  // console.log(aggregated, aggregated2);
  // Object.keys(aggregated2).forEach((siteId) => {
  //   Object.keys(aggregated2[siteId]).forEach((aggregationName) => {
  //     const a = aggregated?.[siteId]?.[aggregationName];
  //     const b = aggregated2?.[siteId]?.[aggregationName];
  //     if (
  //       a?.total !== b?.total ||
  //       a?.total_uncertainty !== b?.total_uncertainty ||
  //       a?.n !== b?.n ||
  //       a?.sites_reporting !== b?.sites_reporting ||
  //       // a?.sites_reporting_ids !== b?.sites_reporting_ids ||
  //       a?.total_readings !== b?.total_readings
  //     ) {
  //       console.log(`${siteId} ${aggregationName}`, a, b);
  //     }
  //   });
  // });
  return aggregated;
};

export const selectPercentChangeForAggregatedObservationsByMetric = (
  state,
  aggregations,
  siteId
) => {
  const aggregationsByDatetimeString =
    selectAggregatedObservationsByMetricAndDatetimeString(
      state,
      aggregations,
      siteId
    );

  if (isNullish(aggregationsByDatetimeString)) {
    return {};
  }
  const obj = {};

  Object.keys(aggregationsByDatetimeString).forEach((metricKey) => {
    obj[metricKey] = null;
    let first = null;
    let last = null;
    const dateKeys = Object.keys(aggregationsByDatetimeString[metricKey]);
    dateKeys.forEach((dateKey) => {
      // need to find the first and last values that have a total in the dataset
      if (
        first === null &&
        aggregationsByDatetimeString[metricKey][dateKey].total !== null
      ) {
        first = aggregationsByDatetimeString[metricKey][dateKey];
      }

      if (aggregationsByDatetimeString[metricKey][dateKey].total !== null) {
        last = aggregationsByDatetimeString[metricKey][dateKey];
      }
    });

    // if there is only one value, there is no change
    if (first === last) {
      obj[metricKey] = null;
    } else {
      obj[metricKey] = findChangePercentage(first?.total, last?.total);
    }
  });

  return obj;
};

export const selectObservationsByDatasetId = createSelector(
  (state) => state.site.data,
  (state, datasetId) => datasetId,
  (data, datasetId) => {
    if (!data) return null;
    return data
      .filter((observation) => observation.dataset_id === datasetId)
      .reduce((acc, cur) => {
        acc[cur.datetime_str] = cur;
        return acc;
      }, {});
  }
);

export const selectObservationsByDatetimeStr = (state, datetimeStr) => {
  if (!state.site.data) return null;
  return state.site.data.filter(
    (observation) => observation.datetime_str === datetimeStr
  );
};

export const selectObservationsChronologically = (state) => {
  if (!state.site.data) return [];
  const sorted = [...state.site.data].sort((a, b) => {
    if (new Date(a.datetime_start) > new Date(b.datetime_start)) {
      return -1;
    } else if (new Date(a.datetime_start) < new Date(b.datetime_start)) {
      return 1;
    } else {
      return 0;
    }
  });
  const enriched = sorted.map((o) => {
    const dataset = state.app.datasets.find((d) => d.id === o.dataset_id);

    return {
      ...o,
      site_name: state.app.sites.find((s) => s.id === o.site_id).name,
      dataset_name: dataset.name,
      units: dataset.metric?.units,
    };
  });
  return enriched;
};

export const selectObservationsStatus = (state) => state.site.status;

export default siteSlice.reducer;
