import { H3HexagonLayer } from "@deck.gl/geo-layers";
import { isEqual, merge, random } from "lodash";
import { computeColor, hexToRgb } from "../utilities";

const constructH3Layer = (data, options) => {
  const baseOptions = {
    id: `hexagon-layer-${random(100)}`,
    data,
    dataComparator: isEqual,
    pickable: true,
    wireframe: false,
    filled: true,
    stroked: true,
    extruded: false,
    getFillColor: (d) => {
      const color = computeColor(
        options.dataset.details.vis,
        d.properties[options.scaledMetric]
      );
      return hexToRgb(color);
    },
  };
  const mergedOptions = merge(baseOptions, options);
  return new H3HexagonLayer({
    ...mergedOptions,
  });
};

export default constructH3Layer;
