import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo-blue.svg";

export default function NotFound() {
  return (
    <div className="flex min-h-screen flex-col justify-center bg-bsr-gray-100 py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo className="mx-auto h-12 w-auto" alt="Workflow" />
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="text-center text-3xl font-extrabold text-bsr-gray-600">
          Page not found
        </h2>
        <div className="mt-3 px-4 text-center text-lg leading-snug text-bsr-gray-300 sm:px-10">
          We couldn't find the page you were looking for. Try going back to the{" "}
          <Link to="/" className="text-bsr-blue-700 hover:text-bsr-blue-600">
            Home Page
          </Link>
          .
        </div>
      </div>
    </div>
  );
}
