import { useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
  selectActiveDatasetIds,
  selectActiveTimePeriod,
  selectActiveObservationIds,
  selectActiveGrouping,
  selectActiveContextLayerIds,
} from "../store/applicationSlice";
import { isEmpty } from "lodash";
import * as JSURL from "jsurl";

export default function useBaseParameters() {
  const { siteId } = useParams();
  const [searchParameters] = useSearchParams();
  const activeTimeFrame = useSelector(selectActiveTimePeriod);
  const activeDatasetIds = useSelector(selectActiveDatasetIds);
  const activeObservationIds = useSelector(selectActiveObservationIds);
  const activeGrouping = useSelector(selectActiveGrouping);
  const activeContextLayerIds = useSelector(selectActiveContextLayerIds);

  return {
    siteId,
    activeTimePeriod: searchParameters.get("timeframe")
      ? JSURL.parse(searchParameters.get("timeframe"))
      : activeTimeFrame,
    activeDatasetIds: !isEmpty(searchParameters.get("datasets"))
      ? JSURL.parse(searchParameters.get("datasets"))
      : activeDatasetIds,
    activeObservationIds: !isEmpty(searchParameters.get("observations"))
      ? JSURL.parse(searchParameters.get("observations"))
      : activeObservationIds,
    activeGrouping: searchParameters.get("grouping")
      ? searchParameters.get("grouping")
      : activeGrouping,
    activeContextLayerIds: searchParameters.get("contexts")
      ? JSURL.parse(searchParameters.get("contexts"))
      : activeContextLayerIds,
  };
}
