import { useSelector } from "react-redux";
import dayjs from "./dayjs";
import "react-popper-tooltip/dist/styles.css";

import { selectActiveTimePeriod } from "./store/applicationSlice";
import { isNullish, formatData } from "./utilities";

export default function ObservationHover({
  dataset,
  observation,
  formattedData,
  stoneColor,
  setTooltipRef,
  getTooltipProps,
  getArrowProps,
  visible,
}) {
  const activeTimePeriod = useSelector(selectActiveTimePeriod);
  console.log("rendering v1 tooltip");
  return (
    <>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: "tooltip-container",
          })}
        >
          <div {...getArrowProps({ className: "tooltip-arrow" })}> </div>
          <div className="my-1 text-sm font-semibold text-bsr-gray-700">
            {(() => {
              if (activeTimePeriod.cadence === "daily") {
                return dayjs(observation?.datetime_str).format("MMM DD, YYYY");
              } else if (activeTimePeriod.cadence === "monthly") {
                return dayjs(observation?.datetime_str).format("MMM YYYY");
              } else if (activeTimePeriod.cadence === "yearly") {
                return dayjs(observation?.datetime_str).format("YYYY");
              } else {
                return dayjs(observation?.datetime_str).format("MMM DD, YYYY");
              }
            })()}
          </div>
          <div style={{ color: stoneColor }} className="text-lg">
            {isNullish(formattedData.value) ? "n/a" : formattedData.value}
            <span className={`ml-1 text-bsr-gray-500`}>
              {dataset?.metric?.units}
            </span>
            <span className="text-sm text-bsr-gray-500">
              {formattedData.uncertainty_display
                ? " ±" + formattedData.uncertainty_display + "%"
                : ""}
            </span>
          </div>
          {!isNullish(observation.metadata?.emission_rate_outside) && (
            <div className="text-xs text-bsr-gray-500">
              Outside emission rate:{" "}
              {formatData(observation.metadata?.emission_rate_outside).value}{" "}
              kg/hr
            </div>
          )}
          {!isNullish(observation.metadata?.mean_background) && (
            <div className="text-xs text-bsr-gray-500">
              Mean background:{" "}
              {formatData(observation.metadata.mean_background).value} ppm
            </div>
          )}
          {observation?.metadata?.total_readings &&
            observation?.metadata?.total_readings > 0 && (
              <div className="text-xs text-bsr-gray-500">
                Num readings: {observation?.metadata?.total_readings}
              </div>
            )}
          {observation?.metadata?.total_sensor_readings &&
            observation?.metadata?.total_sensor_readings > 0 && (
              <div className="text-xs text-bsr-gray-500">
                Num readings: {observation?.metadata?.total_sensor_readings}
              </div>
            )}
          {/* 
          turn back on when once this issue is resolved: 
          https://github.com/orgs/blue-sky-resources/projects/16?pane=issue&itemId=43151548
          {!isNullish(observation?.metadata?.total_exceedances) && (
            <div className="text-xs text-bsr-gray-500">
              Num exceedances: {observation?.metadata?.total_exceedances}
            </div>
          )} */}
          {observation?.dataset?.details?.type === "semFluxHeatMapLayer" &&
            !isNullish(observation.exceedances) && (
              <div className="text-xs text-bsr-gray-500">
                {observation.dataset.id.includes("initial")
                  ? `Num exceedances: ${observation.exceedances}`
                  : observation.dataset.id.includes("remonitored")
                  ? `Num exceedances remonitored: ${observation.exceedances}`
                  : `Num exceedances: ${observation.exceedances}`}
              </div>
            )}
          {observation?.dataset?.details?.type === "optical" && (
            <div className="text-xs text-bsr-gray-500">
              Cloud Coverage:{" "}
              {Math.round(observation?.metadata?.cloud_coverage * 100)}%<br />
              Site Coverage: {observation?.metadata?.site_coverage}%<br />
              Time of Acq:{" "}
              {dayjs(observation?.metadata?.img_time).format("HH:mm")} UTC
            </div>
          )}
          {observation?.dataset?.details?.type === "optical-preview" && (
            <div className="text-xs text-bsr-gray-500">
              Cloud Coverage:{" "}
              {Math.round(observation?.metadata?.cloud_coverage * 100)}%<br />
              Site Coverage: {observation?.metadata?.site_coverage}%<br />
              Time of Acq:{" "}
              {dayjs(observation?.metadata?.img_time).format("HH:mm")} UTC
            </div>
          )}
          {observation?.dataset?.id === "bhp-drone2flux-daily" && (
            <div className="text-xs text-bsr-gray-500">
              <span>
                Total Area:{" "}
                {observation.metadata["coverage (m2)"].toFixed(2) / 1e6} km²
                <br />
              </span>
              <span>
                Avgerage emission rate:
                {
                  formatData(
                    observation?.metadata?.["flux measured (kg/hr per m2)"] *
                      1e6
                  ).value
                }{" "}
                * 1e6 (Avg. kg / hr / km²)
                <br />
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
