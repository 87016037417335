import { useEffect, useState } from "react";
import { db, auth } from "./shared/firebase";
import { Avatar } from "antd";
import dayjs from "dayjs";
import timestampConverter from "./converters/timestampConverter";
import { TagIcon } from "@heroicons/react/24/outline";

function uidToColor(uid) {
  // Create a hash from the UID using a simple hash function
  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32-bit integer
    }
    return hash;
  }

  // Convert the hash code to a color
  function intToColor(int) {
    const hex = (int & 0xffffff).toString(16).toUpperCase();
    return `#${"0".repeat(6 - hex.length) + hex}`;
  }

  // Generate a hash code from the UID and convert it to a color
  const hash = hashCode(uid);
  return intToColor(hash);
}

const NoteItem = ({ note, currentTime }) => {
  const { publisher, published_time, content } = note;
  const color = uidToColor(publisher.uid);

  return (
    <div className="flex items-start space-x-4 border-b border-gray-200 p-4">
      {note.type === "user" ? (
        <Avatar
          style={{
            backgroundColor: color,
            verticalAlign: "middle",
          }}
          size="large"
          gap={2}
        >
          {publisher.email[0]}
        </Avatar>
      ) : (
        <div
          style={{ color }}
          className="flex h-10 w-10 items-center justify-center rounded-full"
        >
          <TagIcon style={{ color }} className="h-10 w-10" />
        </div>
      )}
      <div className="flex-1">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">{publisher.email}</h3>
          <time className="text-sm text-gray-500" dateTime={published_time}>
            {published_time
              ? dayjs(published_time).from(currentTime)
              : dayjs().fromNow()}
          </time>
        </div>
        <p className="mt-2 whitespace-pre-wrap text-gray-800">{content}</p>
      </div>
    </div>
  );
};

export default function MeasurementHistory({ measurementId }) {
  const [notes, setNotes] = useState([]);
  const [currentTime, setCurrentTime] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs());
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const notesRef = db
      .collection("accounts")
      .doc(auth.currentUser.account)
      .collection("measurements")
      .doc(measurementId)
      .collection("notes");

    // Set up the real-time listener
    const unsubscribe = notesRef
      .orderBy("published_time", "desc")
      .withConverter(timestampConverter)
      .onSnapshot(
        (snapshot) => {
          const notesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setNotes(notesData);
        },
        (error) => {
          console.error("Error fetching notes:", error);
        }
      );

    // Cleanup function to unsubscribe from the listener
    return () => unsubscribe();
  }, [measurementId]);

  return (
    <div className="bg-white p-4 shadow-md">
      <h1 className="mb-4 text-xl font-bold">History</h1>
      <div className="overflow-hidden rounded-lg bg-white shadow-lg">
        {notes.map((note) => (
          <NoteItem key={note.id} note={note} currentTime={currentTime} />
        ))}
      </div>
    </div>
  );
}
