import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import MeasurementHeader from "./MeasurementHeader";
import { Select, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Button from "./shared/Button";
import MeasurementHistory from "./MeasurementHistory";
import { createNotification, selectSites } from "./store/applicationSlice";

import {
  fetchAccountTags,
  fetchAccountDatasets,
  fetchMeasurementById,
  selectDataLoaded,
  selectMeasurement,
  selectIsCreatingNote,
  addTag,
  removeTag,
  createNote,
  clearError,
} from "./store/responseSlice";

import Loading from "./shared/Loading";
import MeasurementMap from "./MeasurementMap";

export default function MeasurementDetail() {
  const dispatch = useDispatch();
  const { siteId, measurementId } = useParams();
  const sites = useSelector(selectSites);
  const datasets = useSelector((state) => state.response.accountDatasets);
  const measurement = useSelector(selectMeasurement);

  const site = sites.find((item) => item.id === siteId);

  const measurementTags = useSelector(
    (state) => state.response.measurementTags
  );
  const accountTags = useSelector((state) => state.response.accountTags);
  const error = useSelector((state) => state.response.error);
  const [note, setNote] = useState("");
  const creatingNote = useSelector(selectIsCreatingNote);

  const options = accountTags.map(({ tag_id, tag_name }) => ({
    label: tag_name,
    value: tag_id,
  }));

  const isDataLoaded = useSelector(selectDataLoaded);

  useEffect(() => {
    if (measurementId) {
      dispatch(fetchMeasurementById(measurementId));
    }
  }, [dispatch, measurementId]);

  // TODO probably move these into fetchAccountResources
  // because we only need to fetch them once per user session.
  useEffect(() => {
    dispatch(fetchAccountTags());
    dispatch(fetchAccountDatasets());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      dispatch(
        createNotification({
          title: "Warning",
          description: error,
          type: "warning",
        })
      );
      dispatch(clearError());
    }
  }, [dispatch, error]);

  if (!isDataLoaded) {
    return <Loading />;
  }

  return (
    <div className="grid grid-cols-1 gap-2 md:grid-cols-5">
      <main className="md:col-span-3">
        <header className="sticky top-0 z-10 h-[68px] bg-gray-800 px-4 py-2 text-white">
          <MeasurementHeader
            site={site}
            measurement={measurement}
            datasets={datasets}
          />
        </header>
        <div className=" mb-4 h-[50vh] bg-white shadow-md">
          <MeasurementMap measurement={measurement} />
        </div>
        <MeasurementHistory measurementId={measurementId} />
      </main>
      {/* Sidebar */}
      <aside className="sticky top-0 max-h-[70vh] bg-gray-100 p-4 shadow-md md:col-span-2">
        <h2 className="mb-4 text-xl font-semibold">Response</h2>
        <h4 className="mb-4 text-lg">Emission source</h4>
        <Select
          mode="multiple"
          allowClear
          style={{
            width: "100%",
          }}
          placeholder="Please select an emission source"
          value={measurementTags.map((t) => t.tag_id)}
          onSelect={(id) => dispatch(addTag({ id, measurementId }))}
          onDeselect={(id) => dispatch(removeTag({ id, measurementId }))}
          options={options}
        />
        <h4 className="my-4 text-lg">Add note</h4>
        <Input.TextArea
          rows={8}
          disabled={creatingNote}
          placeholder="Enter an optional note about the emission"
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
        <div className="my-2">
          <Button
            disabled={creatingNote}
            onClick={() => {
              if (note.length === 0) return;
              dispatch(createNote({ measurementId, content: note }));
              setNote("");
            }}
            fullWidth
            type="primary"
          >
            Submit
          </Button>
        </div>
      </aside>
    </div>
  );
}
