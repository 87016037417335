import firebase from "firebase/compat/app";

const timestampConverter = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return convertTimestampsToISO(data);
  },
};

/**
 * Recursively converts Firestore Timestamps to JavaScript ISO strings
 * @param {object} data - The Firestore document data
 * @returns {object} - The converted document data with ISO strings
 */
function convertTimestampsToISO(data) {
  if (data === null || data === undefined) {
    return data;
  }

  if (data instanceof firebase.firestore.Timestamp) {
    return data.toDate().toISOString();
  }

  if (Array.isArray(data)) {
    return data.map((item) => convertTimestampsToISO(item));
  }

  if (typeof data === "object") {
    const result = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        result[key] = convertTimestampsToISO(data[key]);
      }
    }
    return result;
  }

  return data;
}

export default timestampConverter;
