import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlineAreaChart } from "react-icons/ai";

import PortfolioMap from "./PortfolioMap";

import {
  selectActiveDatasets,
  selectSites,
  selectDatasets,
} from "./store/applicationSlice";

import {
  selectObservationsBySite,
  selectObservationsStatus,
} from "./store/portfolioSlice";
import Loading from "./shared/Loading";

export default function PortfolioMapWrapper() {
  const activeDatasets = useSelector(selectActiveDatasets);
  const observationsStatus = useSelector(selectObservationsStatus);
  const datasets = useSelector(selectDatasets);
  const sites = useSelector(selectSites);
  const observationsBySite = useSelector(selectObservationsBySite);
  const [searchParameters, setSearchParameters] = useSearchParams();
  const navigate = useNavigate();

  if (activeDatasets.length === 0) {
    return (
      <div className="m-3 flex h-full grow items-center justify-center border-2 border-dashed border-bsr-gray-200 bg-bsr-gray-050 p-12 text-center hover:border-bsr-gray-200">
        <div>
          <AiOutlineAreaChart className="mx-auto h-20 w-20 text-gray-400" />
          <span className="mt-2 block text-sm font-medium text-bsr-gray-700">
            Activate a dataset using the datasets dropdown above to get started
          </span>
        </div>
      </div>
    );
  }

  console.log(observationsStatus);

  if (observationsStatus === "idle" || observationsStatus === "loading") {
    return <Loading />;
  }

  return (
    <PortfolioMap
      x={parseFloat(searchParameters.get("x"))}
      y={parseFloat(searchParameters.get("y"))}
      z={parseFloat(searchParameters.get("z"))}
      sites={sites}
      datasets={datasets}
      setSearchParameters={setSearchParameters}
      searchParameters={searchParameters}
      activeDatasets={activeDatasets}
      observationsBySite={observationsBySite}
      navigate={navigate}
    />
  );
}
