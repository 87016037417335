import { classNames, formatData } from "../utilities";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedMetrics,
  replaceSelectedMetric,
  selectSelectedMetric,
  selectSelectedDatasets,
} from "../store/applicationSlice";

import { isNullish } from "../utilities";
import Tooltip from "./Tooltip";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { selectSitesCount } from "../store/applicationSlice";
import { useParams } from "react-router-dom";

export default function MetricCard({ metric, aggregation, multipleSelect }) {
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const selectedMetric = useSelector(selectSelectedMetric);
  const selectedDatasets = useSelector(selectSelectedDatasets);
  const sitesCount = useSelector(selectSitesCount);

  const d = formatData(aggregation?.total, aggregation?.total_uncertainty);
  const active = multipleSelect
    ? selectedDatasets.map((m) => m.id).includes(metric.id)
    : selectedMetric.id === metric.id;

  return (
    <div
      className={classNames(
        active ? `${metric.color.tailwind.text}` : "text-gray-500",
        "relative flex min-w-[150px] flex-col rounded-sm bg-white px-3 py-3 hover:cursor-pointer hover:bg-bsr-gray-050 focus:z-10"
      )}
      onClick={() =>
        multipleSelect
          ? dispatch(updateSelectedMetrics(metric))
          : dispatch(replaceSelectedMetric(metric))
      }
    >
      <div
        className={classNames(
          active ? `${metric.color.tailwind.text}` : "text-gray-900",
          "flex text-sm"
        )}
      >
        <div>
          <span className="capitalize">{metric.agg}: </span> {metric.name}
        </div>
        <Tooltip position="bottom" content={metric?.description}>
          <InformationCircleIcon className="mx-1 h-4 w-4 text-bsr-gray-500"></InformationCircleIcon>
        </Tooltip>
      </div>
      <div className="mb-2 flex">
        <div
          className={classNames(
            active ? `${metric.color.tailwind.text}` : "text-bsr-gray-900",
            "truncate text-2xl font-bold"
          )}
        >
          {isNullish(d?.value) ? (
            <div>--</div>
          ) : (
            <div>
              {d.value}
              <span className="text-sm font-normal"> {metric.units}</span>
              {/* {d.uncertainty_display && (
                <span
                  className={classNames(
                    active
                      ? `${metric.color.tailwind.text}`
                      : "text-bsr-gray-900",
                    "text-sm font-normal"
                  )}
                >
                  {" "}
                  ±{`${d.uncertainty_display}%`}
                </span>
              )} */}
            </div>
          )}
        </div>
      </div>
      {aggregation?.sites_reporting > 0 && sitesCount && !siteId && (
        <div className="text-xs text-bsr-gray-400">
          Sites reporting: {aggregation?.sites_reporting}/{sitesCount}
        </div>
      )}
      {aggregation?.total_readings > 0 && (
        <div className="text-xs text-bsr-gray-400">
          Num. measurements: {formatData(aggregation?.total_readings).value}
        </div>
      )}
      <span
        aria-hidden="true"
        className={classNames(
          active ? `${metric.color.tailwind.bg}` : "bg-transparent",
          "absolute inset-x-0 bottom-0 h-1.5"
        )}
      />
    </div>
  );
}
