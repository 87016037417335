import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getMeasurementById,
  getAccountTags,
  addTagToMeasurement,
  removeTagFromMeasurement,
  addNoteToMeasurement,
  getAccountDatasets,
} from "../services/Api";

const initialState = {
  measurement: null,
  measurementLoaded: false,
  creatingNote: false,
  accountTags: [],
  accountDatasets: [],
  accountTagsLoaded: false,
  accountDatasetsLoaded: false,
  measurementTags: [],
  measurementNotes: [],
  error: null,
};

export const createNote = createAsyncThunk(
  "response/createNote",
  ({ measurementId, content }) =>
    addNoteToMeasurement({ measurementId, content })
);

export const fetchMeasurementById = createAsyncThunk(
  "response/fetchMeasurementById",
  async (measurementId) => {
    try {
      return getMeasurementById(measurementId);
    } catch (err) {
      throw new Error("failed to fetch measurement");
    }
  }
);

export const fetchAccountTags = createAsyncThunk(
  "response/fetchAccountTags",
  async () => {
    const accountTags = await getAccountTags();
    return accountTags;
  }
);

export const fetchAccountDatasets = createAsyncThunk(
  "response/fetchAccountDatasets",
  async () => {
    const accountDatasets = await getAccountDatasets();
    return accountDatasets;
  }
);

export const addTag = createAsyncThunk(
  "response/addTagToMeasurement",
  async ({ id, measurementId }, { getState }) => {
    const accountTags = getState().response.accountTags;
    const tag = accountTags.find((t) => t.tag_id === id);
    await addTagToMeasurement(measurementId, tag);
    return tag;
  }
);

export const removeTag = createAsyncThunk(
  "response/removeTagFromMeasurement",
  async ({ id, measurementId }, { getState }) => {
    const accountTags = getState().response.accountTags;
    const tag = accountTags.find((t) => t.tag_id === id);
    await removeTagFromMeasurement(measurementId, tag);
    return tag;
  }
);

export const responseSlice = createSlice({
  name: "response",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: {
    [fetchMeasurementById.fulfilled]: (state, action) => {
      state.measurement = action.payload.measurement;
      state.measurementNotes = action.payload.notes;
      state.measurementTags = action.payload.measurement.tags;
      state.measurementLoaded = true;
    },
    [fetchAccountTags.fulfilled]: (state, action) => {
      state.accountTags = action.payload;
      state.accountTagsLoaded = true;
    },
    [fetchAccountDatasets.fulfilled]: (state, action) => {
      state.accountDatasets = action.payload;
      state.accountDatasetsLoaded = true;
    },
    [addTag.fulfilled]: (state, action) => {
      state.measurementTags = [...state.measurementTags, action.payload];
    },
    [removeTag.fulfilled]: (state, action) => {
      state.measurementTags = state.measurementTags.filter(
        (t) => t.tag_id !== action.payload.tag_id
      );
    },
    [createNote.pending]: (state) => {
      state.creatingNote = true;
    },
    [createNote.fulfilled]: (state) => {
      state.creatingNote = false;
    },
    [fetchMeasurementById.rejected]: (state) => {
      state.error = "Operation fetch measurement details failed";
      state.measurementLoaded = false;
    },
    [fetchAccountTags.rejected]: (state) => {
      state.error = "Operation fetch account tags failed";
      state.accountTagsLoaded = false;
    },
    [createNote.rejected]: (state) => {
      state.error = "Operation create note failed";
    },
    [addTag.rejected]: (state) => {
      state.error = "Operation add tag failed";
    },
    [removeTag.rejected]: (state) => {
      state.error = "Operation remove tag failed";
    },
  },
});

export const { clearError } = responseSlice.actions;

export const selectDataLoaded = (state) => {
  return (
    state.response.measurementLoaded &&
    state.response.accountTagsLoaded &&
    state.response.accountDatasetsLoaded
  );
};
export const selectIsCreatingNote = (state) => state.response.creatingNote;
export const selectMeasurement = (state) => state.response.measurement;

export default responseSlice.reducer;
