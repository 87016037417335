import React from "react";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps";

class MapCreate extends React.Component {
  constructor() {
    super();
    this.mapRef = React.createRef();
    this.overlay = new DeckOverlay({
      layers: [],
    });
    this.state = {
      search: "",
      loading: false,
    };
  }

  onPlaceChanged = () => {
    const place = this.places.getPlace();
    if (!place.geometry) {
      this.setState({ search: "" });
    } else {
      try {
        this.map.fitBounds(place.geometry.viewport);
        this.props.suggestName(place.name);
      } catch (error) {
        console.warn("failed to fit bounds on autocomplete search result:");
        console.log(error);
      }
    }
  };

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");
    const { DrawingManager } = await window.google.maps.importLibrary(
      "drawing"
    );
    const { Autocomplete } = await window.google.maps.importLibrary("places");

    let centerLatLng = { lat: 40.015, lng: -105.2705 };

    if (this.mapRef.current) {
      this.map = new Map(this.mapRef.current, {
        center: centerLatLng,
        zoom: 3,
        tilt: 0,
        mapTypeId: "satellite",
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
      });

      this.drawingManager = new DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ["rectangle", "polygon"],
        },
      });

      this.places = new Autocomplete(document.getElementById("autocomplete"), {
        fields: ["place_id", "geometry", "name"],
      });

      this.places.addListener("place_changed", this.onPlaceChanged);

      this.drawingManager.setMap(this.map);

      window.google.maps.event.addListener(
        this.drawingManager,
        "overlaycomplete",
        (event) => {
          switch (event.type) {
            case "marker":
              this.map.data.add(
                new window.google.maps.Data.Feature({
                  geometry: new window.google.maps.Data.Point(
                    event.overlay.getPosition()
                  ),
                })
              );
              break;
            case "rectangle":
              var b = event.overlay.getBounds(),
                p = [
                  b.getSouthWest(),
                  {
                    lat: b.getSouthWest().lat(),
                    lng: b.getNorthEast().lng(),
                  },
                  b.getNorthEast(),
                  {
                    lng: b.getSouthWest().lng(),
                    lat: b.getNorthEast().lat(),
                  },
                ];
              this.map.data.add(
                new window.google.maps.Data.Feature({
                  geometry: new window.google.maps.Data.Polygon([p]),
                })
              );
              break;
            case "polygon":
              this.map.data.add(
                new window.google.maps.Data.Feature({
                  geometry: new window.google.maps.Data.Polygon([
                    event.overlay.getPath().getArray(),
                  ]),
                })
              );
              break;
            default:
              break;
          }
          // we are storing these features in state in two places. we only need one to be visible.
          this.map.data.setStyle({ visible: false });
          this.props.addFeature(event);
          // reduce all features drawn on map => total sq. km total points and geojson feature collection
          this.props.calculateAoi();

          // turn drawing mode off after drawing a polygon.
          // remove drawing tools from map unless polygon is deleted.
          // this.drawingManager.setDrawingMode(null);
          // this.drawingManager.setOptions({
          //   drawingControl: false,
          // });
        }
      );
    }
  }

  componentDidMount() {
    this.initMap().then(() => {
      // ONE TIME SYNC GMAPS INSTANCE WITH DECK
      this.overlay.setMap(this.map);
      this.props.passMap(this.map);
    });
  }

  componentWillUnmount() {
    // hopefully JS garbage collection will free up memory once we set map to null.
    // TODO: keep an eye on memory usage and if we set up event listeners for this class component
    // be sure to un-register them here!
    window.google.maps.event.clearListeners(this.map, "overlaycomplete");
    window.google.maps.event.clearListeners(this.map, "place_changed");

    this.map = null;
    this.overlay.finalize();
  }

  render() {
    return (
      <>
        <div style={{ width: "100%", height: "100%" }} ref={this.mapRef}></div>
        {this.state.loading && (
          <div className="absolute inset-0 z-20 flex items-center justify-center bg-bsr-gray-500 bg-opacity-80">
            <div className="mb-20 text-center">
              <svg
                className="-ml-1 mr-3 inline h-6 w-6 animate-spin text-bsr-gray-800"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default MapCreate;
