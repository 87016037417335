import dayjs from "../dayjs";
import { formatData, isNullish } from "../utilities";
import { XMarkIcon } from "@heroicons/react/24/outline";

import WindRose from "./WindRose";

const HoveredMapInfo = ({ mapLayerType, layer, clear, properties }) => {
  if (!properties || !layer.id) return null;
  if (mapLayerType === "context" && properties?.[layer?.label]) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="whitespace-nowrap text-lg">
          {properties[layer.label]}
        </div>
      </div>
    );
  } else if (layer.id.includes("australia_mine_atlas")) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            Name:{" "}
            <span className="text-md font-bold">{properties["name"]}</span>
          </div>
          <div className="whitespace-nowrap">
            Operating status:{" "}
            <span className="text-md font-bold">
              {properties["operating_status"]}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Commodities:{" "}
            <span className="text-md font-bold">
              {properties["commodity_names"]}
            </span>
          </div>
        </div>
      </div>
    );
  } else if (
    layer.id.includes("ghgr-emissions") ||
    layer.id.includes("epa-ghgr")
  ) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            Direct emissions:{" "}
            <span className="text-md font-bold">
              {Math.round(properties["Total reported direct emissions"])} mtCO2e{" "}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Name:{" "}
            <span className="text-md font-bold">
              {properties["Facility Name"]}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Industry:{" "}
            <span className="text-md font-bold">
              {properties["Industry Type (sectors)"]}
            </span>
          </div>
        </div>
      </div>
    );
  } else if (
    layer.id.includes("bhp_fugitive_emissions") ||
    layer.id.includes("bhp_material_moved") ||
    layer.id.includes("bhp_operations")
  ) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            Operation:{" "}
            <span className="text-md font-bold">
              {formatData(Math.round(properties["value"])).value}{" "}
              {properties["units"]}{" "}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Pit: <span className="text-md font-bold">{properties["name"]}</span>
          </div>
        </div>
      </div>
    );
  } else if (layer.id.includes("bhp-operations")) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            Operation:{" "}
            <span className="text-md font-bold">
              {formatData(Math.round(properties["value"])).value}{" "}
              {properties["units"]}{" "}
            </span>
          </div>
          {properties?.["Timestamp"]?.seconds && (
            <div className="whitespace-nowrap">
              Timestamp:{" "}
              <span>
                {dayjs(properties?.["Timestamp"]?.seconds).format("h:mm:ss")}
              </span>
            </div>
          )}
          {properties["TruckID"] && (
            <div className="whitespace-nowrap">
              Truck ID: <span>{properties["TruckID"]}</span>
            </div>
          )}
        </div>
      </div>
    );
  } else if (layer.id.includes("bhp-weather")) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            Speed:{" "}
            <span className="text-md font-bold">
              {formatData(Math.round(properties["value"])).value}{" "}
              {properties["units"]}{" "}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Direction:{" "}
            <span className="text-md font-bold">
              {properties["direction_text"]}
            </span>
          </div>
        </div>
      </div>
    );
  } else if (properties?.layerType === "localWindLayer") {
    console.log(properties);
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="bg-white">
          <div className="ml-2 flex justify-between text-bsr-gray-900">
            <div>{properties.name}</div>
            <div className="cursor-pointer p-2">
              <XMarkIcon
                className="h-5 w-5 text-bsr-gray-700"
                onClick={clear}
              />
            </div>
          </div>
          {!isNullish(properties.avg) && (
            <div className="mx-2 text-bsr-gray-900">
              {formatData(properties.avg).value} m/s
            </div>
          )}
          <WindRose
            datasetName={properties.datasetName}
            cadence={properties.cadence}
            acquisitions={properties.acquisitions}
          />
        </div>
      </div>
    );
  } else if (layer.id.includes("bhp_weather")) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col text-lg">
          <div className="whitespace-nowrap">
            <span className="text-md font-bold">
              {formatData(Math.round(properties["value"])).value}{" "}
              {properties["units"]}{" "}
            </span>
          </div>
          <div className="whitespace-nowrap">
            Sensor:{" "}
            <span className="text-md font-bold">{properties["name"]}</span>
          </div>
        </div>
      </div>
    );
  } else if (
    layer.id.includes("cu-fixed") &&
    properties?.layerType === "SensorLayer"
  ) {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div>{properties?.name}</div>
        <div>
          {String(properties?.value)} {String(properties?.units)}{" "}
        </div>
        <div>in {properties?.total_readings} readings</div>
      </div>
    );
  } else if (properties?.layerType === "SensorLayer") {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <p>{properties?.name}</p>
        <p>
          {properties?.exceedance_percentage}% exceedances in{" "}
          {properties?.total_readings} readings
        </p>
      </div>
    );
  } else if (properties?.layerType === "flightPathLayer") {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <p>Tail #: {properties?.aircraft_id}</p>
        <p>Operator: {properties?.operator}</p>
      </div>
    );
  } else if (properties?.layerType === "SemLayer") {
    return (
      <div className="absolute left-2 top-2 z-80 bg-bsr-gray-700 bg-opacity-90 p-2 text-white">
        <div className="flex flex-col">
          <div className="text-lg">
            {dayjs(properties.timestamp).format("MMMM Do hh:mm A")}
          </div>
          <div>
            {formatData(properties?.value).value} {String(properties?.units)}{" "}
            {properties?.metric ? properties?.metric : ""}
          </div>
        </div>
      </div>
    );
  } else {
    console.log("returning null");
    return null;
  }
};

export default HoveredMapInfo;
