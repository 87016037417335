import { useState } from "react";
import {
  classNames,
  generateTimeSeries,
  safeToLowerCase,
  isNullish,
} from "./utilities";
import { isPlainObject } from "lodash";
import { useSelector } from "react-redux";
import {
  selectSensors,
  selectSites,
  selectActiveTimePeriod,
} from "./store/applicationSlice";
import {
  selectCoverage,
  selectCoverageBySite,
} from "./store/dataCoverageSlice";

import {
  BarsArrowUpIcon,
  BarsArrowDownIcon,
} from "@heroicons/react/24/outline";
import TooltipViaRenderProps from "./TooltipViaRenderProps";
import CoverageCellHover from "./CoverageCellHover";
import DataCoverageLegend from "./DataCoverageLegend";
import SensorHover from "./SensorHover";

export default function DataCoverageTable({ searchTerm }) {
  const sensors = useSelector(selectSensors);
  const sites = useSelector(selectSites);
  const activeTimeFrame = useSelector(selectActiveTimePeriod);
  const coverage = useSelector(selectCoverage);
  const coverageBySite = useSelector(selectCoverageBySite);
  const [sortBy, setSortBy] = useState(sensors[0]?.name);
  const [sortAscending, setSortAscending] = useState(false);

  function reSort(field) {
    if (field !== sortBy) setSortBy(field);

    // only flip the sort order if the user clicked on the active field
    if (field === sortBy) setSortAscending(!sortAscending);
  }

  const sortFunction = (site1, site2) => {
    let comparison = 0;
    const site1Value = isPlainObject(site1[sortBy])
      ? site1[sortBy]?.total_readings
      : site1[sortBy];

    const site2Value = isPlainObject(site2[sortBy])
      ? site2[sortBy]?.total_readings
      : site2[sortBy];

    if (sortAscending) {
      // sort nulls to bottom of list - remove this if block if you don't want this
      if (!isNullish(site1Value) && isNullish(site2Value)) {
        comparison = -1;
      } else if (!isNullish(site2Value) && isNullish(site1Value)) {
        comparison = 1; // sort nulls to bottom of list. change to -1 if you don't want this
      } else if (site1Value > site2Value) {
        comparison = 1;
      } else if (site2Value > site1Value) {
        comparison = -1;
      }
    } else {
      if (!isNullish(site1Value) && isNullish(site2Value)) {
        comparison = -1;
      } else if (site2Value > site1Value) {
        comparison = 1;
      } else if (site1Value > site2Value) {
        comparison = -1;
      }
    }
    return comparison;
  };

  if (!activeTimeFrame) return null;

  const timeSeries = generateTimeSeries(
    activeTimeFrame.startDate,
    activeTimeFrame.endDate,
    activeTimeFrame.cadence
  );

  const rows = sites.map((site) => {
    const row = {
      name: site.name,
      id: site.id,
    };
    sensors.forEach((sensor) => {
      row[sensor.name] = 0;
      Object.keys(timeSeries).forEach((datetimeStr) => {
        row[sensor.name] +=
          coverageBySite?.[row.id]?.[sensor.name]?.[datetimeStr]
            ?.total_readings ?? 0;
      });
    });
    return row;
  });

  const results = !searchTerm
    ? rows.slice().sort(sortFunction)
    : rows
        .slice()
        .filter(
          (site) =>
            safeToLowerCase(site.name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(site.parent_company_name).includes(
              searchTerm.toLocaleLowerCase()
            ) ||
            safeToLowerCase(site["function"]).includes(
              searchTerm.toLocaleLowerCase()
            )
        )
        .sort(sortFunction);

  return (
    <div className="mt-7">
      <div className="flex bg-bsr-gray-500 text-white">
        <div
          className="inline-flex w-[250px] cursor-pointer items-center px-4 py-5"
          onClick={(e) => {
            reSort("name");
          }}
        >
          Site name
          {sortBy === "name" &&
            (sortAscending ? (
              <BarsArrowUpIcon className="ml-1 h-4 w-4" />
            ) : (
              <BarsArrowDownIcon className="ml-1 h-4 w-4" />
            ))}
        </div>
        {sensors.map((sensor) => {
          return (
            <div key={`all-sites-${sensor.name}`}>
              <div className="grid h-0 grid-cols-6 gap-x-1 bg-transparent px-3">
                {Object.keys(timeSeries).map((datetimeStr) => {
                  return <div key={datetimeStr} className="h-5 w-5"></div>;
                })}
              </div>
              <TooltipViaRenderProps
                placement="top"
                trigger="hover"
                tooltip={(props) => <SensorHover {...props} sensor={sensor} />}
              >
                {({ getTriggerProps, triggerRef }) => {
                  return (
                    <div
                      {...getTriggerProps({
                        ref: triggerRef,
                        className:
                          "trigger inline-flex items-center justify-center px-2 py-5 cursor-pointer",
                        /* your props here */
                      })}
                      onClick={(e) => {
                        reSort(sensor.name);
                      }}
                    >
                      {sensor.name}{" "}
                      {sortBy === sensor.name &&
                        (sortAscending ? (
                          <BarsArrowUpIcon className="ml-1 h-4 w-4" />
                        ) : (
                          <BarsArrowDownIcon className="ml-1 h-4 w-4" />
                        ))}
                    </div>
                  );
                }}
              </TooltipViaRenderProps>
            </div>
          );
        })}
      </div>
      <div className="h-[60vh] divide-y divide-gray-300 overflow-scroll bg-white text-bsr-gray-700">
        <div className="flex ">
          <div className="w-[250px] border-r border-bsr-gray-200 p-5">
            All sites ({results.length})
          </div>
          {sensors.map((sensor) => {
            return (
              <div
                className="grid grid-cols-6 gap-1 border-r border-bsr-gray-200 px-3 py-5"
                key={`all-sites-${sensor.name}`}
              >
                {Object.keys(timeSeries).map((datetimeStr) => {
                  return (
                    <TooltipViaRenderProps
                      placement="bottom"
                      trigger="hover"
                      tooltip={(props) => (
                        <CoverageCellHover
                          {...props}
                          datetimeStr={datetimeStr}
                          numReadings={
                            coverage?.[sensor.name]?.[datetimeStr]
                              ?.total_readings
                          }
                        />
                      )}
                      key={`all-sites-${sensor.name}-${datetimeStr}`}
                    >
                      {({ getTriggerProps, triggerRef }) => {
                        return (
                          <div
                            {...getTriggerProps({
                              ref: triggerRef,
                              className: "trigger",
                            })}
                            key={datetimeStr}
                            title={datetimeStr}
                            className={classNames(
                              coverage?.[sensor.name]?.[datetimeStr]
                                ?.total_readings
                                ? "bg-bsr-blue-800"
                                : "bg-bsr-gray-050",
                              "h-5 w-5 cursor-pointer rounded-full border border-bsr-blue-800"
                            )}
                          ></div>
                        );
                      }}
                    </TooltipViaRenderProps>
                  );
                })}
              </div>
            );
          })}
        </div>
        {results.map((row, index) => {
          return (
            <div className="flex" key={`row-${index}`}>
              <div className="w-[250px] border-r border-bsr-gray-200 p-5">
                {row.name}
              </div>
              {sensors.map((sensor) => {
                return (
                  <div
                    className="grid grid-cols-6 gap-1 border-r border-bsr-gray-200 px-3 py-5"
                    key={`row-${index}-${sensor.name}`}
                  >
                    {Object.keys(timeSeries).map((datetimeStr) => {
                      return (
                        <TooltipViaRenderProps
                          placement="bottom"
                          trigger="hover"
                          key={`row-${index}-${sensor.name}-${datetimeStr}`}
                          tooltip={(props) => (
                            <CoverageCellHover
                              {...props}
                              datetimeStr={datetimeStr}
                              siteId={row.id}
                              numReadings={
                                coverageBySite?.[row.id]?.[sensor.name]?.[
                                  datetimeStr
                                ]?.total_readings
                              }
                            />
                          )}
                        >
                          {({ getTriggerProps, triggerRef }) => {
                            return (
                              <div
                                {...getTriggerProps({
                                  ref: triggerRef,
                                  className: "trigger",
                                })}
                                key={datetimeStr}
                                title={datetimeStr}
                                className={classNames(
                                  coverageBySite?.[row.id]?.[sensor.name]?.[
                                    datetimeStr
                                  ]?.total_readings
                                    ? "bg-bsr-blue-800"
                                    : "bg-bsr-gray-050",
                                  "h-5 w-5 cursor-pointer rounded-full border border-bsr-blue-800"
                                )}
                              ></div>
                            );
                          }}
                        </TooltipViaRenderProps>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="flex items-center space-x-10 bg-bsr-gray-100 px-5 text-xs text-gray-800">
        <DataCoverageLegend
          cadence={activeTimeFrame.cadence}
          timeSeries={timeSeries}
        />
      </div>
    </div>
  );
}
