import { useEffect } from "react";
import { getD3ScaleForVis } from "../utilities";
import Tooltip from "./Tooltip";
import { uniqBy } from "lodash";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { getTimeFormat, isNullish } from "../utilities";
import { useDispatch, useSelector } from "react-redux";
import { updateMapOpacity } from "../store/applicationSlice";
import dayjs from "../dayjs";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import { scaleLinear } from "d3-scale";
import { range } from "d3-array";
import PropTypes from "prop-types";

import WindRose from "./WindRose";

import { ALMOST_ZERO } from "../constants";

function Legend({ activeObservation, id }) {
  const dispatch = useDispatch();
  const opacity = useSelector((state) => {
    // default integrated wind layers to zero for now until we figure out
    // better way to prevent them from obsfuscating other map visualizations
    if (id.includes("era5-wind-daily") || id.includes("gfs-wind-daily")) {
      return !isNullish(state.app.mapLayerOpacity?.[id])
        ? state.app.mapLayerOpacity?.[id]
        : 0;
    } else {
      return !isNullish(state.app.mapLayerOpacity?.[id])
        ? state.app.mapLayerOpacity?.[id]
        : 1;
    }
  });
  const dataset = activeObservation?.dataset;
  const activeDate = activeObservation?.datetime_start;
  const vis = dataset?.details?.vis;

  // generate legend with d3
  useEffect(() => {
    if (vis && vis.type !== "ordinal") {
      const width = 250;
      const height = 25;

      const svg = select("#legend-" + id)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      // Create scale
      const scale = getD3ScaleForVis(vis);

      const length = scale.range().length;

      // X Pixel Scale
      const xScale = scaleLinear()
        .domain([1, length - 1])
        .rangeRound([width / length, (width * (length - 1)) / length]);

      // Add legend colors
      svg
        .selectAll("rect")
        .data(scale.range())
        .join("rect")
        .attr("height", 8)
        .attr("x", (d, i) => xScale(i))
        .attr("width", (d, i) => xScale(i + 1) - xScale(i))
        .attr("fill", (d) => d);

      // Add scales to axis
      const x_axis = axisBottom(xScale)
        .tickSize(13)
        .tickFormat((i) => {
          const tickvalue = scale.domain()[i - 1];
          if (tickvalue === ALMOST_ZERO) {
            return 0;
          } else {
            return tickvalue;
          }
        })
        .tickValues(range(1, length));

      //Append group and insert axis
      svg.append("g").call(x_axis).select(".domain").remove();

      // prevent svg.append from adding duplicate legends when this component is re-rendered
      //  when unmounted, clean out the legend so the next append just creates one legend
      return function cleanup() {
        const legendDiv = document.getElementById("legend-" + id);
        if (legendDiv) {
          console.log("clearing legend");
          legendDiv.innerHTML = "";
        }
      };
    }
  }, [vis, id]);

  if (vis) {
    return (
      <div
        className="p-2 pb-1"
        style={{ backgroundColor: "rgba(232, 236, 241, .95)" }}
      >
        <div>
          <div className="flex flex-row items-center text-sm">
            {dataset.details.name}{" "}
            {dataset.details.units ? `(${dataset.details.units})` : ""}
            <Tooltip position="top" content={dataset.details.description}>
              <InformationCircleIcon className="mx-1 h-4 w-4 text-bsr-gray-400 hover:text-bsr-gray-500" />
            </Tooltip>
          </div>
          <div className="flex text-xs font-light">
            for {dayjs(activeDate).format(getTimeFormat(dataset?.frequency))}
          </div>
        </div>
        <div id={`legend-${id}`}>
          {(() => {
            if (
              activeObservation.dataset.details.type ===
                "integratedWindLayer" ||
              activeObservation.dataset.details.type === "WindroseLayer"
            ) {
              // fetch png and render it here in div.
              return (
                <WindRose
                  datasetName={activeObservation.dataset.name}
                  cadence={activeObservation.dataset.frequency}
                  acquisitions={activeObservation.metadata.acquisitions}
                />
              );
            }
            if (
              activeObservation.dataset.details.type === "LgmsImpairedLayer"
            ) {
              const scale = getD3ScaleForVis(
                activeObservation.dataset.details.vis
              );
              return (
                <div>
                  <div className="mt-2 flex">
                    <div
                      style={{ backgroundColor: scale("Impaired") }}
                      className="mr-1 h-8 w-8"
                    ></div>
                    <div className="flex flex-col justify-center text-xs">
                      <div>Impaired</div>
                    </div>
                  </div>
                  <div className="mt-2 flex">
                    <div
                      style={{ backgroundColor: scale("Non-impaired") }}
                      className="mr-1 h-8 w-8"
                    ></div>
                    <div className="flex flex-col justify-center text-xs">
                      <div>Non-impaired</div>
                    </div>
                  </div>
                </div>
              );
            }
            if (activeObservation.dataset_id === "flightaware-tracking-daily") {
              const uniqueFlights = uniqBy(
                activeObservation.metadata.readings,
                "aircraft_id"
              );
              const scale = getD3ScaleForVis(
                activeObservation.dataset.details.vis
              );
              return (
                <div>
                  {uniqueFlights.map((flight) => {
                    return (
                      <div className="mt-2 flex">
                        <div
                          style={{ backgroundColor: scale(flight.aircraft_id) }}
                          className="mr-1 h-8 w-8"
                        ></div>
                        <div className="flex flex-col justify-center text-xs">
                          <div>{flight.aircraft_id}</div>
                          <div>{flight.operator}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            } else {
              return null;
            }
          })()}
        </div>
        {activeObservation.dataset.details.type !== "windLayer" &&
          activeObservation.dataset.details.type !== "integratedWindLayer" && (
            <div className="mt-1 flex items-center justify-between">
              <div className="slider flex items-center">
                <span className="mr-1 text-xs font-medium text-bsr-gray-500">
                  opacity:
                </span>
                <input
                  type="range"
                  min={0}
                  max={100}
                  value={opacity * 100}
                  onChange={(event) => {
                    const value = Number(event.target.value);
                    dispatch(
                      updateMapOpacity({
                        id,
                        value: value / 100.0,
                      })
                    );
                  }}
                  className="range"
                />
              </div>
            </div>
          )}
      </div>
    );
  }
  return (
    <div
      className="rounded-b-md p-2 pb-1 text-bsr-gray-700"
      style={{ backgroundColor: "rgba(232, 236, 241, .95)" }}
    >
      No legend has been configured for this dataset.
    </div>
  );
}

Legend.propTypes = {
  activeObservation: PropTypes.any,
  id: PropTypes.string,
};

export default Legend;
