import { useEffect, useState } from "react";
import ModalWithHeader from "./ModalWithHeader";

export default function WindRose({ cadence, acquisitions, datasetName }) {
  const [chart, setChart] = useState(null);
  const [rose, setRose] = useState(null);
  const [expanded, setExpanded] = useState(false);

  console.log({ acquisitions });

  useEffect(() => {
    async function fetchChart() {
      return fetch(`${process.env.REACT_APP_AIRLOGIC_API_URL}/windchart`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(acquisitions),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    async function fetchRose() {
      return fetch(`${process.env.REACT_APP_AIRLOGIC_API_URL}/windrose`, {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(acquisitions),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }
    if (cadence === "daily") {
      fetchChart()
        .then((res) => res.text())
        .then((b64) => setChart(b64));
    }
    fetchRose()
      .then((res) => res.text())
      .then((b64) => setRose(b64));
  }, [cadence, acquisitions]);

  return (
    <div className="flex justify-center">
      {rose ? (
        <div
          className="cursor-pointer text-center"
          onClick={() => setExpanded(true)}
        >
          <p className="mt-2 text-xs text-bsr-gray-500">
            ( click image for additional details )
          </p>
          <img
            className=" max-w-[200px] tall:max-w-[300px]"
            alt="wind rose plot"
            src={`data:image/png;base64, ${rose}`}
          />
        </div>
      ) : (
        <div className="p-3 text-bsr-gray-700">generating windrose...</div>
      )}
      {expanded && chart ? (
        <ModalWithHeader
          open
          width="w-2/3"
          overlayZIndex="z-80"
          headerTitle={`Wind Model: ${datasetName}`}
          onClose={() => setExpanded(false)}
        >
          <div className="flex justify-center">
            <img
              alt="wind chart plot"
              src={`data:image/png;base64, ${chart}`}
            />
          </div>
        </ModalWithHeader>
      ) : expanded && rose ? (
        <ModalWithHeader
          open
          width="w-2/3"
          overlayZIndex="z-80"
          headerTitle={`Wind Model: ${datasetName}`}
          onClose={() => setExpanded(false)}
        >
          <div className="flex justify-center">
            <img alt="wind chart plot" src={`data:image/png;base64, ${rose}`} />
          </div>
        </ModalWithHeader>
      ) : null}
    </div>
  );
}
