import React from "react";
import { GoogleMapsOverlay as DeckOverlay } from "@deck.gl/google-maps";
import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "deck.gl";
import { parseWktPoint } from "./utilities";
import api from "./services/Api";
import constructBaseGeojsonLayer from "./layers/geojson";
import { getHtml } from "./utilities";
import { feature } from "@turf/helpers";

class MeasurementMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.overlay = new DeckOverlay({
      layers: [],
      getTooltip: ({ object }) => {
        if (object && object.properties) {
          return {
            html: `<div>${getHtml({
              ...object.properties,
              geometry: object.geometry,
            })}</div>`,
            style: {
              backgroundColor: "#1F2933",
              whiteSpace: "nowrap",
              fontSize: "1.5em",
              padding: "5px",
              color: "white",
            },
          };
        }
      },
    });
  }

  async initMap() {
    const { Map } = await window.google.maps.importLibrary("maps");

    const { measurement } = this.props;
    const { lat, lng } = parseWktPoint(measurement.location_wkt) || {
      lat: 40.015,
      lng: -105.2705,
    };

    if (this.mapRef.current) {
      this.map = new Map(this.mapRef.current, {
        center: { lat, lng },
        zoom: 15,
        tilt: 0,
        mapTypeId: "satellite",
        mapTypeControlOptions: {
          mapTypeIds: ["roadmap", "terrain", "satellite", "hybrid"],
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: true,
        fullscreenControl: false,
        streetViewControl: false,
        rotateControl: false,
        mapTypeControl: false,
      });
    }
  }

  async loadMeasurement(measurement) {
    try {
      // Retrieve the URL for the measurement from the API
      const { url } = await api.getMeasurementById({
        measurementId: measurement.measurement_id,
        datasetId: measurement.dataset_id,
      });

      const tileLayer = new TileLayer({
        id: "plume-layer",
        data: url,
        tileSize: 512, // Adjust based on your tile size
        minZoom: 0,
        maxZoom: 18,
        renderSubLayers: (props) => {
          const {
            bbox: { west, south, east, north },
          } = props.tile;
          return new BitmapLayer(props, {
            data: null,
            image: props.data,
            bounds: [west, south, east, north],
          });
        },
      });

      const { geojson } = parseWktPoint(measurement.location_wkt);

      const percentError = (
        (measurement?.uncertainty_value / measurement?.value) *
        100
      ).toFixed(1);

      const properties = {
        value: measurement.value,
        units: measurement.units,
      };

      if (measurement?.uncertainty_value) {
        properties["uncertainty"] = `±${percentError}%`;
      }

      const data = feature(geojson, properties);

      const plumeOriginLayer = constructBaseGeojsonLayer(data, {
        id: `geojson-plume-origin`,
        getFillColor: [218, 18, 125, 250],
        plumeOriginLayer: true,
      });

      this.overlay.setProps({
        layers: [tileLayer, plumeOriginLayer],
      });
    } catch (error) {
      // Handle any errors that occurred during the API call or adding the TileLayer
      console.error("Failed to load plume layer:", error);
    }
  }

  async componentDidMount() {
    await this.initMap();
    // ONE TIME SYNC GMAPS INSTANCE WITH DECK
    this.overlay.setMap(this.map);
    this.loadMeasurement(this.props.measurement);
  }

  render() {
    return <div className="h-full w-full" ref={this.mapRef}></div>;
  }
}

export default MeasurementMap;
