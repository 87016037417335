import dayjs from "../dayjs";

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import {
  getObservations,
  aggregateObservationsByMetric,
} from "../services/Api";
import { findDatasetForAggregation } from "../utilities";
import { updateActiveTimeframe } from "./applicationSlice";

const initialState = {
  data: null,
  status: "idle",
  error: null,
};

export const fetchObservations = createAsyncThunk(
  "dataCoverage/fetchObservations",
  async ({ sites, activeTimeFrame, datasetIds }) => {
    const observations = await getObservations({
      siteIds: sites,
      datasetIds: datasetIds,
      cadence: activeTimeFrame.cadence,
      startDate: dayjs(activeTimeFrame.startDate).toDate(),
      endDate: dayjs(activeTimeFrame.endDate).toDate(),
    });
    return observations;
  }
);

export const dataCoverageSlice = createSlice({
  name: "dataCoverage",
  initialState,
  reducers: {
    setActiveTimeFrame: (state, action) => {
      state.activeTimeFrame = action.payload;
      state.status = "idle";
    },
  },
  extraReducers: {
    [fetchObservations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchObservations.pending]: (state) => {
      state.status = "loading";
      state.data = null;
    },
    [fetchObservations.failed]: (state, action) => {
      state.error = action.payload;
      state.status = "failed";
    },
    // when timeframe is updated invalidate the cached portfolio observations
    [updateActiveTimeframe]: (state, action) => {
      state.status = initialState.status;
    },
  },
});

export const selectCoverage = createSelector(
  (state) => state.app.sensors,
  (state) => state.app.datasets,
  (state) => state.app.activeTimePeriod,
  (state) => state.dataCoverage.data,
  (sensors, datasets, activeTimePeriod, data) => {
    console.log("running expensive ag selector");

    if (!data || !sensors || !activeTimePeriod) {
      return null;
    }

    const coverage = {};

    const uniqueDatetimeStrings = new Set();
    data.forEach((observation) => {
      uniqueDatetimeStrings.add(observation.datetime_str);
    });

    sensors.forEach((sensor) => {
      coverage[sensor.name] = {};
      const dataset = findDatasetForAggregation(
        { dataset_matcher: sensor.dataset_matcher },
        activeTimePeriod.cadence,
        datasets
      );

      if (!dataset) {
        return;
      }

      // some datasets store coverage information in separate datasets.
      //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
      const dataCoverageDataset = dataset?.data_coverage
        ? datasets.find((d) => d.id === dataset?.data_coverage?.dataset_id)
        : null;
      const coverageObservations = dataCoverageDataset
        ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
        : null;

      uniqueDatetimeStrings.forEach((datetime_str) => {
        const a = aggregateObservationsByMetric({
          observations: data.filter(
            (d) =>
              d.dataset_id === dataset?.id && d.datetime_str === datetime_str
          ),
          coverageObservations: coverageObservations?.filter(
            (o) => o.datetime_str === datetime_str
          ),
          metric: "value",
          aggregation: "min",
        });
        coverage[sensor.name][datetime_str] = a;
      });
    });
    console.log("coverage", coverage);
    return coverage;
  }
);

export const selectCoverageBySite = createSelector(
  (state) => state.app.sites,
  (state) => state.app.sensors,
  (state) => state.app.datasets,
  (state) => state.app.activeTimePeriod,
  (state) => state.dataCoverage.data,
  (sites, sensors, datasets, activeTimePeriod, data) => {
    console.log("running expensive ag selector");
    if (!data || !sensors || !activeTimePeriod) {
      return null;
    }

    const coverage = {};

    const uniqueDatetimeStrings = new Set();
    data.forEach((observation) => {
      uniqueDatetimeStrings.add(observation.datetime_str);
    });

    sites.forEach((site) => {
      coverage[site.id] = {};

      sensors.forEach((sensor) => {
        coverage[site.id][sensor.name] = {};
        const dataset = findDatasetForAggregation(
          { dataset_matcher: sensor.dataset_matcher },
          activeTimePeriod.cadence,
          datasets
        );

        if (!dataset) {
          return;
        }

        // some datasets store coverage information in separate datasets.
        //   the corresponding dataset and calculate total_readings for each of the aggregations and datetime_str groups
        const dataCoverageDataset = dataset?.data_coverage
          ? datasets.find((d) => d.id === dataset?.data_coverage?.dataset_id)
          : null;
        const coverageObservations = dataCoverageDataset
          ? data.filter((d) => d.dataset_id === dataCoverageDataset.id)
          : null;

        uniqueDatetimeStrings.forEach((datetime_str) => {
          const a = aggregateObservationsByMetric({
            observations: data.filter(
              (d) =>
                d.dataset_id === dataset?.id &&
                d.site_id === site.id &&
                d.datetime_str === datetime_str
            ),
            coverageObservations: coverageObservations?.filter(
              (o) => o.site_id === site.id && o.datetime_str === datetime_str
            ),
            metric: "value",
            aggregation: "min",
          });
          coverage[site.id][sensor.name][datetime_str] = a;
        });
      });
    });

    return coverage;
  }
);

export const selectObservationsStatus = (state) => state.dataCoverage.status;
export const selectObservations = (state) => state.dataCoverage.data;

export default dataCoverageSlice.reducer;
